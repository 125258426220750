import { SettingsManager } from '@viamap/viamap2-common';
import { LayerInfoStyling } from 'src/common/managers/Types';

export class MaplibreUtils {

   static makeSourceSpec(featuresArray:any[], useClustering:boolean):any {
      // tslint:disable

      let clusterParams = useClustering ?         
      {
        cluster: true,
        clusterMaxZoom: SettingsManager.getSystemSetting("clustering.clusterMaxZoom"), // Max zoom to cluster points on
        clusterRadius: SettingsManager.getSystemSetting("clustering.clusterRadius") // Radius of each cluster when clustering points (defaults to 50)
      } 
      : {};
      return {
        generateId: false,
        type: "geojson",
        data: {
            "type": "FeatureCollection",
            // "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::4326" } },
            "features": featuresArray,
        },
        ...clusterParams
      };
      // tslint:enable

   }

   static makeSourceSpecPieChart(featuresArray:any[], piePropertyPrefix:string, pieColorPrefix:string, pieLabelPrefix:string, piePropertySuffixList:string[]):any {
      // tslint:disable

      let clusterParams = 
      {
        cluster: true,
        clusterMaxZoom: SettingsManager.getSystemSetting("clustering.clusterMaxZoom"), // Max zoom to cluster points on
        clusterRadius: SettingsManager.getSystemSetting("clustering.clusterRadius"), // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: piePropertySuffixList.reduce((result, val, idx) => {
          return {...result, 
            [piePropertyPrefix+val]: ["+", ["coalesce", ['get', piePropertyPrefix+val], 0]],
            // Arrangement to make colours ripple up. The color of the same divisionId will be the same.
            [pieColorPrefix+val]: [
               ["case", [ ">", [ "length", ["accumulated"] ], 0 ], ["accumulated"], ["coalesce", ['get', pieColorPrefix+val], "" ]
            ], ["coalesce", ['get', pieColorPrefix+val], ""]
            ],
            // Arrangement to make labels ripple up. The label of the same divisionId will be the same.
            [pieLabelPrefix+val]: [
               ["case", [ ">", [ "length", ["accumulated"] ], 0 ], ["accumulated"], ["coalesce", ['get', pieLabelPrefix+val], "" ]
            ], ["coalesce", ['get', pieLabelPrefix+val], ""]
            ]
         }
        }, {})
      };
      return {
        type: "geojson",
        generateId: false,
        data: {
            "type": "FeatureCollection",
            // "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::4326" } },
            "features": featuresArray,
        },
        ...clusterParams
      };
      // tslint:enable

   }

   static makeSourceRaster():any {
      // tslint:disable
      return {
        type: "raster",
        tiles: [
         "https://services.kortforsyningen.dk/service?servicename=mat&token=80dae7e359473909ed9de92a88707d8f&bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Jordstykke&Styles=Roede_Jordstykker"
        ],
        tileSize: 256
      };
      // tslint:enable
   }

   // --------------------------------------------------------------------------------------------------------

   static makeLayerSpecSimple(id:string, source:string, styling:LayerInfoStyling, color:string, useCustomIcon?:boolean, iconSize?:number, showText?:boolean, textColor?:string, textFont?:string):any {
      // tslint:disable
      let iconImage= useCustomIcon ?
         ['coalesce',
            ['image', ['get', 'iconUrl']],
            ['image', 'mit-pin-orange']
         ] 
      : SettingsManager.getSystemSetting("pinPrefix","mit-pin")+"-"+color;
      let textLayoutProperties = showText ? {
         "text-field": showText ?  ["get","textValue"] : "",
         "text-allow-overlap": false,
         "text-ignore-placement": false,
         "text-font": [
            textFont  || "Noto Sans Regular"
        ]
      } : {};
      let textPaintProperties = showText ? {
         "text-halo-color": "#fff",
         "text-halo-width": 1.5,
         "text-color": textColor ||"blue"
      } : {};
      return {
         id: id,
         type: "symbol",
         source: source ,
         filter: ["!", ["has", "point_count"]],
         paint: {
            "icon-opacity": ['number', ['get', 'opacity'], (styling.opacity ?? 1)],
            ...textPaintProperties
         },
         layout: {
             "icon-image": iconImage,
             "icon-size": (useCustomIcon && iconSize) || 1,
             "icon-allow-overlap": true,
             "icon-anchor": "bottom",
             "text-ignore-placement": true,
             "icon-ignore-placement": true,
             ...textLayoutProperties
         }
     };
      // tslint:enable
   }

   static makeLayerPoi(id:string, source:string):any {
      // tslint:disable
      return {
         id: id,
         type: "symbol",
         source: source ,
         filter: ["!", ["has", "point_count"]],
         paint: {
            "text-opacity": ['case',['boolean', ['feature-state', 'hover'], false],1, 0],
            "text-halo-color":"#fff",
            "text-halo-width": 3,
         },
         layout: {
             "icon-image": ['get','icon'],
             "icon-size": 1,
            //  "icon-allow-overlap": true,
             "icon-anchor": "bottom",
             "text-field": ['concat', ['get','label'], ' ', ["get", "poiName"]],
             "text-font": ["Noto Sans Regular"],
             "text-size": 10,
             "text-anchor": 'top',
             "icon-ignore-placement": true,
             "text-allow-overlap": true
         }
      };
      // tslint:enable
   }
   static makeLayerSpecStyled(id:string, sourceId:string, heightByValue:boolean):any {
      // tslint:disable
      if (heightByValue) {
         return {
            id: id,
            type: 'fill-extrusion',
            source: sourceId ,
            filter: ["!", ["has", "point_count"]],
            layout: {
            },
            paint: {
               "fill-extrusion-opacity": 0.7,
               "fill-extrusion-height": [ 'get', 'height'],
               "fill-extrusion-color": [ 'get', "itemcolor" ]
            }
          };
      } else {

      return {
         id: id,
         type: 'circle',
         source: sourceId ,
         filter: ["!", ["has", "point_count"]],
         paint: {
            'circle-color': [ 'get', "itemcolor" ],
            'circle-radius': ['get', "radius"],
            'circle-opacity': 0.8
         }
      };
   }
      // tslint:enable
   }

   static makeLayerSpecText(id:string, sourceId:string, styling):any {
      return {
         id: id,
         type: "symbol",
         source: sourceId,
         "paint": {
            "text-color": styling.textColor || "#454659",
            "text-halo-color": "#fff",
            "text-halo-width": 1.5
          },
          "layout": {
            "text-field": ["get","textToDisplay"],
            "text-allow-overlap": false,
            "text-ignore-placement": false,
            "text-font": [
              styling.textFont ||
              "Noto Sans Regular"
            ]
          },
          "filter": ["all",["==", "$type", "Point"],["has","textToDisplay"],["!has", "point_count"]]
      };
   }

   static makeLayerCells(id:string, sourceId:string):any {
      // tslint:disable
      return {
         id: id,
         type: 'fill',
         source: sourceId ,
         paint: {
            'fill-color': [ 'get', "itemcolor" ],
            'fill-opacity': 0.8
         }
      };
      // tslint:enable
   }

   static makeLayerClusterMarkers(id:string, sourceId:string) {
      // todo: Style to match leaflet-markercluster
      // tslint:disable
      return {
         id: id,
         type: "circle",
         source: sourceId,
         filter: ["has", "point_count"],
         paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
            "step",
            ["get", "point_count"],
            ["rgba",110, 204, 57, 0.8],
            50,
            ["rgba",240, 194, 12, 0.8],
            100,
            ["rgba",241, 128, 23, 0.8]
            ],
            "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40
            ]
         }
      };
      // tslint:enable
   }

   static makeLayerClusterCount(id:string, sourceId:string) {
      // todo: Style to match leaflet-markercluster
      // tslint:disable
      return {
         id: id,
         type: "symbol",
         source: sourceId,
         filter: ["has", "point_count"],
         layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Noto Sans Regular"],
            "text-size": 14
         },
         "paint": {
            "text-color": [
               "step",
               ["get", "point_count"],
               "black",
               50,
               "black",
               100,
               "black"
               ]
         }
      };
      // tslint:enable       
   }

   static makeLayerRaster(id:string, sourceId:string):any {
      // tslint:disable
      return {
         'id': id,
         'type': 'raster',
         'source': sourceId,
         'paint': {}
      };
      // tslint:enable
   }

}