import { useContext, useRef } from "react";
import { GlassButton } from "../components/GlassButtons";
import { Paragraph, patchDocument, PatchType, Table, TableCell, TableRow, TextRun, WidthType } from "docx";
import { actionSetInfoMessage, AppMessagesContext, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { BNBOBeregninger } from "./BNBOBeregninger";
import { BNBOContext, BNBOOmrådeType, BNBOState, getLodsejerData } from "./BNBOState";
import { formatMatrikelNumre } from "./BNBOFunc";
import area from "@turf/area";
import { feature, featureCollection } from "@turf/helpers";
import { intersect, union } from "@turf/turf";
import { bnboActiveFilter, getAllDeleForLodsejer } from "./BNBOAreaEditorExtra";


export function BNBOGenerateAfgørelse(props: {
   lodsEjer: string
}) {
   const {state:bnboState} = useContext(BNBOContext)
   const {dispatch} = useContext(AppMessagesContext)
   const linkRef = useRef<HTMLAnchorElement>(null)

   


   return (
      <>
      <a ref={linkRef} style={{opacity:0, position:"absolute", zIndex:-2}}></a>
      <GlassButton 
         // disabled={!bnboState.markDele[props.lodsEjer]}
         onClick={(e) => {
            if (!bnboState.markDele[props.lodsEjer]) {
               dispatch(actionSetInfoMessage("Ingen marker på Ejendom"))
            } else {
               // handleGenerateDokument(e)
            }
         }} >
         Afgørelse
      </GlassButton>
      </>
   )
}

export async function handleGenerateDokument(linkRef, bnboState, lodsEjer, filename: string | Blob) {
   let afgørelseBlob = await danBNBODokument(bnboState, lodsEjer, filename)
   let docxUrl = URL.createObjectURL(afgørelseBlob)
   
   if (linkRef.current) {
      linkRef.current.href = docxUrl;
      if (filename instanceof Blob) {
         linkRef.current.download = (filename as File)?.name || "droppedFile.docx"
      } else {
         linkRef.current.download = filename;
      }
      linkRef.current.click()
   }   
}

function removeEndingSymbols(str:string):string {
   return str.trim().replace(/[\.\,]*$/,"")
}

function extractBynavnFromAdresse(adresse:string):string {
   let parts = adresse.split(", ")
   let post = parts.find(part => /^\d{4}/.test(part)) || "";
   return removeEndingSymbols(post.replace(/^\d{4}/, "").trim())
}

function readyForMatrikelMatt(list:any[]) {
   return list.filter((a) => a).map((a) => ({
      "m":a.properties.Matrikelnummer,
      "e":a.properties.Ejerlavsnavn,
      "a": area(a as any)
   })).sort((a,b) => a.e.localeCompare(b.e) || a.m.localeCompare(b.m)) || []
}

async function danBNBODokument(bnboState:BNBOState, lodsejerId: string, filename: string | Blob) {
   // TODO: Følgende skal på et tidspunkt komme fra staten.
   const Dato = (new Date()).toLocaleDateString("da-dk", {day:"2-digit", month:"2-digit", year:"numeric"});

   // Lodsejer
   let lodsejer = getLodsejerData(bnboState, lodsejerId)
   const Lodsejer_Navn = lodsejer.EjerNavn || "[[Lodsejer_navn]]";
   const Lodsejer_Adresse = lodsejer.Adresse || "[[Lodsejer_adresse]]";
   const LodsEjer_ByNavn = (lodsejer.EjerNavn && extractBynavnFromAdresse(lodsejer.EjerNavn)) || "[[Bynavn]]";
   const Lodsejer_PostNr = "[[PostNr]]";
   const Lodsejer_Cvr = (lodsejer as any).Cvr || "";
   
   // Kommune
   const Kommune_Navn = SettingsManager.getSystemSetting("bnbo.kommuneNavn") || "";
   const Kommune_Journalnummer = lodsejer.JournalNr || "[[Kommune_Journalnummer]]";
   const Kommune_Reference = lodsejer.sagsbehandler || "[[Sagsbehandler]]";
   const Sagsbehandler = lodsejer.sagsbehandler || "[[Sagsbehandler]]";
   
   // Vandværk og Boringer
   const Borings_Numre = Object.values(bnboState.bnboer[lodsejerId]).map((a) => a.dguNr).join(", ") || "[[bnbo.Dgunr]]";
   
   const Vandværk_Navn = (lodsejer.forsyningNavn || "[[Vandværk_Navn]]")
   const Vandværk_Adresse = (lodsejer.forsyningAdresse || "[[Vandværk_Adresse]]")
   const Vandværk_PostNr = (lodsejer.forsyningPostnummer || "[[Vandværk_CVR]]")
   const Vandværk_CVR = (lodsejer.forsyningCVR || "[[Vandværk_PostNr]]")
   // const Vandværk_CVR_Navn = [...new Set(Object.values(bnboState.bnboer[lodsejerId]).map((a) => a.cvr_navn))].join(", ") || "[[bnbo.VandværkCVRNavn]]";
   
   // Ejendom
   const Matrikel_Liste = (lodsejer.geometry.features && formatMatrikelNumre(lodsejer.geometry.features, " og ")) || "[[matr_nr]]";
   const Ejendom_Beliggenhed = (lodsejer.Adresse && removeEndingSymbols(lodsejer.Adresse)) || "[[ejendom_aresse]]";

   const bnbo = Object.values(bnboState.bnboer[lodsejerId]).filter(bnboActiveFilter).map((a) => {
      return feature(a.geometry, {
        id: a.id,
        dguNr: a.dguNr,
        statusCode: a.statusCode,
        statusTekst: a.statusTekst,
        anlaegsNavn: a.anlaegsNavn,
        kommuneNavn: a.kommuneNavn,
      })
    }) || []
   const bnboUnion = (() => {
      if (bnbo.length > 1)
         return [union(featureCollection(bnbo))]
      return bnbo
   })();
   const matrikel_bnbo = lodsejer.geometry.features.filter((a) => intersect(featureCollection([a, ...bnboUnion])))
   const Matrikel_Liste_BNBO = matrikel_bnbo && formatMatrikelNumre(matrikel_bnbo, " og ") || "[[Matrikel_Liste_BNBO]]"
   


   // Erstatning
   let erst = BNBOBeregninger.udregnErstatningEjendom(bnboState, lodsejerId)
   let totalErst = BNBOBeregninger.sumErstatninger(erst)
   const Erstatning_Total = BNBOBeregninger.formatTotalErstatning(totalErst)

   const dele = getAllDeleForLodsejer(bnboState, lodsejerId)
   const deleUnion = (() => {
      if (dele.length > 1)
         return [union(featureCollection(dele))]
         return dele
   })()
   const deleUnion_UU = (() => {
      const deleUU = dele.filter((a) => a.properties.type !== BNBOOmrådeType.UlempeAreal)
      if (deleUU.length > 1)
         return [union(featureCollection(deleUU))]
         return deleUU
   })()

   let matt_MU = readyForMatrikelMatt(lodsejer.geometry.features.map((a) => {
      return intersect(featureCollection([deleUnion, a].flat()), {properties: a.properties})
   }))
      
   let matt_UU = readyForMatrikelMatt(lodsejer.geometry.features.map((a) => {
      return intersect(featureCollection([deleUnion_UU, a].flat()), {properties: a.properties})
   }))

   let matt = readyForMatrikelMatt(lodsejer.geometry.features)

   // All Matrikler Area
   const Jordstykke_Matrikel_Nr = matt.map((a) => a.m)
   const Jordstykke_Ejerlav = matt.map((a) => a.e)
   const Jordstykke_Arealer = matt.map((a) => ((a.a || 0)/10000).toLocaleString("da-dk", {maximumFractionDigits:2, minimumFractionDigits:2})).map((a) => a + " ha")
   const Jordstykke_Arealer_Sum = (matt.map((a) => ((a.a || 0)/10000)).reduce((a,b) => a+b, 0).toLocaleString("da-dk", {maximumFractionDigits:2, minimumFractionDigits:2}))

   // MU Matrikler med Ulempe
   const Jordstykke_Matrikel_Nr_MU = matt_MU.map((a) => a.m)
   const Jordstykke_Ejerlav_MU = matt_MU.map((a) => a.e)
   const Jordstykke_Arealer_MU = matt_MU.map((a) => ((a.a || 0)/10000).toLocaleString("da-dk", {maximumFractionDigits:2, minimumFractionDigits:2})).map((a) => a + " ha")
   const Jordstykke_Arealer_Sum_MU = (matt_MU.map((a) => ((a.a || 0)/10000)).reduce((a,b) => a+b, 0).toLocaleString("da-dk", {maximumFractionDigits:2, minimumFractionDigits:2}))

   // MM Matrikler uden Ulempe
   const Jordstykke_Matrikel_Nr_UU = matt_UU.map((a) => a.m)
   const Jordstykke_Ejerlav_UU = matt_UU.map((a) => a.e)
   const Jordstykke_Arealer_UU = matt_UU.map((a) => ((a.a || 0)/10000).toLocaleString("da-dk", {maximumFractionDigits:2, minimumFractionDigits:2})).map((a) => a + " ha")
   const Jordstykke_Arealer_Sum_UU = (matt_UU.map((a) => ((a.a || 0)/10000)).reduce((a,b) => a+b, 0).toLocaleString("da-dk", {maximumFractionDigits:2, minimumFractionDigits:2}))

   // BNBOBeregninger.udregnErstatningEjendom
   const file = async () => {
      if (filename instanceof Blob) {
         return {Body: new Promise<Blob>((res) => {res(filename)})}
      }
      let persistence = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"));
      let file = persistence.getObjectS3(`BNBOModuleFiles/${bnboState.selectedProjectId}/Templates/${filename}`)
      return file as any
   }

   return new Promise<Blob>(async (res) => {
      let Ud = await file() as any
      // let Da = await new Blob([Ud.Body], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
      patchDocument({outputType:"blob", data: Ud.Body,keepOriginalStyles:true,
         patches: {
            "Dato"                  :{type: PatchType.PARAGRAPH,children: [new TextRun(Dato                  )]},
            "Lodsejer_Navn"         :{type: PatchType.PARAGRAPH,children: [new TextRun(Lodsejer_Navn         )]},
            "Lodsejer_ByNavn"       :{type: PatchType.PARAGRAPH,children: [new TextRun(LodsEjer_ByNavn       )]},
            // "Lodsejer_Adresse"      :{type: PatchType.PARAGRAPH,children: [new TextRun(Lodsejer_Adresse      )]},
            // "Lodsejer_PostNr"       :{type: PatchType.PARAGRAPH,children: [new TextRun(Lodsejer_PostNr       )]},
            // "Lodsejer_Cvr"          :{type: PatchType.PARAGRAPH,children: [new TextRun(Lodsejer_Cvr          )]},
            "Kommune_Navn"          :{type: PatchType.PARAGRAPH,children: [new TextRun(Kommune_Navn          )]},
            "Kommune_Journalnummer" :{type: PatchType.PARAGRAPH,children: [new TextRun(Kommune_Journalnummer )]},
            "Kommune_Reference"     :{type: PatchType.PARAGRAPH,children: [new TextRun(Kommune_Reference     )]},
            "Sagsbehandler"      :{type: PatchType.PARAGRAPH,children: [new TextRun(Sagsbehandler         )]},
            "Borings_Numre"         :{type: PatchType.PARAGRAPH,children: [new TextRun(Borings_Numre         )]},
            "Vandværk_Navn"         :{type: PatchType.PARAGRAPH,children: [new TextRun(Vandværk_Navn         )]},
            "Vandværk_CVR"          :{type: PatchType.PARAGRAPH,children: [new TextRun(Vandværk_CVR          )]},
            "Vandværk_Adresse"      :{type: PatchType.PARAGRAPH,children: [new TextRun(Vandværk_Adresse      )]},
            "Vandværk_PostNr"       :{type: PatchType.PARAGRAPH,children: [new TextRun(Vandværk_PostNr       )]},
            "Matrikel_Liste"        :{type: PatchType.PARAGRAPH,children: [new TextRun(Matrikel_Liste        )]},
            "Matrikel_Liste_BNBO"   :{type: PatchType.PARAGRAPH,children: [new TextRun(Matrikel_Liste_BNBO   )]},
            "Ejendom_Beliggenhed"   :{type: PatchType.PARAGRAPH,children: [new TextRun(Ejendom_Beliggenhed   )]},
            "Erstatning_Total"    :{type: PatchType.PARAGRAPH,children: [new TextRun(Erstatning_Total    )]},
            "Jordstykke_Matrikel_Nr":{type: PatchType.PARAGRAPH,children: Jordstykke_Matrikel_Nr.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Ejerlav"    :{type: PatchType.PARAGRAPH,children: Jordstykke_Ejerlav.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Arealer"    :{type: PatchType.PARAGRAPH,children: Jordstykke_Arealer.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Arealer_Sum":{type: PatchType.PARAGRAPH,children: [new TextRun(Jordstykke_Arealer_Sum)]},
            "Jordstykke_Matrikel_Nr_MU":{type: PatchType.PARAGRAPH,children: Jordstykke_Matrikel_Nr_MU.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Ejerlav_MU":{type: PatchType.PARAGRAPH,children: Jordstykke_Ejerlav_MU.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Arealer_MU":{type: PatchType.PARAGRAPH,children: Jordstykke_Arealer_MU.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Arealer_Sum_MU":{type: PatchType.PARAGRAPH,children: [new TextRun(Jordstykke_Arealer_Sum_MU)]},
            "Jordstykke_Matrikel_Nr_UU":{type: PatchType.PARAGRAPH,children: Jordstykke_Matrikel_Nr_UU.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Ejerlav_UU":{type: PatchType.PARAGRAPH,children: Jordstykke_Ejerlav_UU.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Arealer_UU":{type: PatchType.PARAGRAPH,children: Jordstykke_Arealer_UU.map((a, idx) => new TextRun({text:a, break: idx ? 1 : 0})) },
            "Jordstykke_Arealer_Sum_UU":{type: PatchType.PARAGRAPH,children: [new TextRun(Jordstykke_Arealer_Sum_UU)]},
            "Erstatningsopgørelse"  :{type: PatchType.DOCUMENT,
               children: [
                  new Paragraph({
                     children: [new TextRun({
                        text: "Erstatningsopgørelse",
                        style: "Subtitle",
                     })]
                  }),
                  Erstatnignsopgørelse(BNBOBeregninger.formatErstatningsBeregningEjendom(erst), Erstatning_Total),
                  new Paragraph({
                     children: [new TextRun({
                        text: "Total Erstatningsbeløb " + BNBOBeregninger.formatTotalErstatning(totalErst),
                        style: "Subtitle",
                        break: 1,
                        bold:true
                     })],
                  })
               ]
            }
         }
      }).then((a) => {
         res(a)
      })
   })
}

// const removed = {
//    "tabel": {type: PatchType.DOCUMENT,
//       children: [...marker.flatMap((a, idx) => [
//             new Paragraph({
//                children: [new TextRun({
//                   text: "\nMark " + a.markNr,
//                   style: "Subtitle",
//                   break:1,
//                })]
//             }),
//          ]),
//          markTable(bnboState, lodsejer, marker[idx].id),
//          new Paragraph({
//             children: [new TextRun({
//                text: "Total Erstatningsbeløb " + BNBOBeregninger.formatTotalErstatning(totalErst),
//                style: "Subtitle",
//                break: 1,
//                bold:true
//             })],
//          })
//       ]
//    }
// }

function Erstatnignsopgørelse(erst, total) {
   return(
      new Table({
         width: {
            size: 100,
            type: WidthType.PERCENTAGE,
         },
         rows: [
            headerRow(),
            ...erst.map((a) => {
               return row(a.type_formatted, a.antal_formatted, a.faktor_formatted, a.takst_formatted, a.procent_formatted, a.erstatning_formatted)
            }),
            totalRow(total),
         ]
         
      })
      
   )
}

function headerRow() {
   return (
      new TableRow({
         children: [
            new TableCell({
               children: [new Paragraph({children: [new TextRun({text:"Type", color: "FFFFFF"})]})],
               shading: {fill: "000000"}
            }),
            new TableCell({
               children: [new Paragraph({children: [new TextRun({text:"Antal", color: "FFFFFF"})]})],
               shading: {fill: "000000"}
            }),
            new TableCell({
               children: [new Paragraph({children: [new TextRun({text:"Faktor", color: "FFFFFF"})]})],
               shading: {fill: "000000"}
            }),
            new TableCell({
               children: [new Paragraph({children: [new TextRun({text:"Takst", color: "FFFFFF"})]})],
               shading: {fill: "000000"}
            }),
            new TableCell({
               children: [new Paragraph({children: [new TextRun({text:"Procent", color: "FFFFFF"})]})],
               shading: {fill: "000000"}
            }),
            new TableCell({
               children: [new Paragraph({children: [new TextRun({text:"Total", color: "FFFFFF"})]})],
               shading: {fill: "000000"}
            })
         ]
      })
   )
}

function row(_a,a,b,c,d,e) {
   return (
      new TableRow({
         children: [
            new TableCell({
               children: [new Paragraph({alignment: "left",children: [new TextRun({text: _a})]})],
               
            }),
            new TableCell({
               children: [new Paragraph({alignment: "right",children: [new TextRun({text: a})]})],
               
            }),
            new TableCell({
               children: [new Paragraph({alignment: "right",children: [new TextRun({text: b})]})],
               
            }),
            new TableCell({
               children: [new Paragraph({alignment: "right",children: [new TextRun({text: c})]})],
               
            }),
            new TableCell({
               children: [new Paragraph({alignment: "right",children: [new TextRun({text: d})]})],
               
            }),
            new TableCell({
               children: [new Paragraph({alignment: "right",children: [new TextRun({text: e})]})],
               
            })
         ]
      })
   )
}

function totalRow(a:string) {
   return (
      new TableRow({
         children: [
            new TableCell({
               children: [new Paragraph({children:[new TextRun({text: "Total erstatning ", bold: true})]})],
               columnSpan:5,
            }),
            new TableCell({
               children: [new Paragraph({alignment:"right", children:[new TextRun({text: a, bold: true})]})]
            })
         ]
      })
   )
}