import { GlassFoldUdBox, GroupSelector, KeyValueLine, KeyValuesHorizontal, RenderLinkToCompanyGraph, RenderLinkToPropertyInfo, ShowSelectedGroup } from "src/propertyInfoTemplates/PropertyComponents"
import "../css/propertyInfoStyle.css";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { Cookies, SettingsManager } from "@viamap/viamap2-common";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { PropertyInformation } from "src/managers/PropertyInformation";
import { useState, useContext, useEffect } from "react";


type PersonData = any

type props = {
  personInfo:any,
  foldetUd: boolean,
  cols: number
  callBackOnLoaded?: (a: string) => any
}
export function PersonInfoView(props: props) {
  // const [collector] = useState(  );

  function setSelectedGroup(string) {
    Cookies.setCookie("PersonInfoSelectedTab",string)
    _setSelectedGroup(string)
  }

  const [selectedGroup, _setSelectedGroup] = useState(
    Cookies.getCookie("PersonInfoSelectedTab") || "Alle"
  );
  const [availableGroup, setAvailableGroup] = useState(["Alle"]);
  const [data, setData] = useState<Partial<PersonData>>({});
  const [loading, setLoading] = useState(true)
  const [ejendomsListe, setEjendomsListe] = useState<any[]>([]); // Ejendomme ejet (eller delvist ejet) af Personen.
  const [rolleListe, setRolleListe] = useState<any[]>([]); // Ejendomme ejet (eller delvist ejet) af Personen.

  const { hasAccessToFeature } = useContext(ApplicationStateContext);

  useEffect(() => {
    // getDataCache()
    getDataAsync();
  }, [hasAccessToFeature, props.personInfo]);
  
  async function getDataAsync() {
    if (props.personInfo.cvrPersonId || (props.personInfo.navn && props.personInfo.postnr && props.personInfo.adresse)) {
      function getActiveRecord(recs: { periode: { gyldigFra: string, gyldigTil: string } }[]): any {
        return recs.find((r) => r.periode.gyldigTil === null);
      }  
      function aktiveVirksomheder(virksomhedSummariskRelation:any):boolean {
        return virksomhedSummariskRelation?.virksomhed?.livsforloeb && getActiveRecord(virksomhedSummariskRelation.virksomhed.livsforloeb);
      }
      function aktiveFunktioner(organisationer:any):boolean {
        let funktionAttr = organisationer.attributter?.find((org) => org.type === "FUNKTION");
        return funktionAttr?.vaerdier && getActiveRecord(funktionAttr.vaerdier);
      }
      let cvrInfo;
      if (props.personInfo.cvrPersonId) {
        cvrInfo = await PropertyInfoInterface.CVRPersonDeltagerByEnhedsNummer(props.personInfo.cvrPersonId);
      } else {
        if (!(props.personInfo.vejnavn || props.personInfo.husnr)) {
          // split adress into streetname and houseno
          let re = new RegExp(/([^0-9]*)([0-9]+[a-zA-Z]*)/);
          let res = re.exec(props.personInfo.adresse);
          props.personInfo.vejnavn = res && res[1];
          props.personInfo.husnr = res && res[2];
        }
        cvrInfo = await PropertyInfoInterface.CVRPersonDeltagerByNameAndAddress(props.personInfo.navn, props.personInfo.vejnavn, props.personInfo.husnr, props.personInfo.postnr, "DK");
      }
      const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");

      if ((props.personInfo.navn && props.personInfo.postnr && props.personInfo.adresse) || cvrInfo) {
        let postnr = props.personInfo.postnr || cvrInfo.beliggenhedsadresse[0].postnummer
        let adresse = props.personInfo.adresse || cvrInfo.beliggenhedsadresse[0].vejnavn + " " + cvrInfo.beliggenhedsadresse[0].husnummerFra
        let liste = await PropertyInfoInterface.getPropertiesOwnedByPerson(props.personInfo.navn, postnr, adresse);
        setEjendomsListe(liste?.hits?.hits.map((ht) => ht._source) || []);
      } else {
        setEjendomsListe([]);
      }

      let res:any[] = cvrInfo?.virksomhedSummariskRelation?.filter(aktiveVirksomheder).map((vr) => {
          let virkData = {
            cvrNummer: vr.virksomhed.cvrNummer,
            navn : getActiveRecord(vr.virksomhed.navne)?.navn || "ukendt",
          };
          let orgsData = vr.organisationer.filter(aktiveFunktioner).map((org) => {
            return {
              hovedType : org.hovedtype,
              navn: getActiveRecord(org.organisationsNavn)?.navn || "ukendt",
              periode: org.medlemsData[0]?.attributter?.find((a) => a.type == "FUNKTION")?.vaerdier?.[0].periode
            }
          });
          return {
            virkData, orgsData
          }
      }).sort((a,b) => a.virkData.navn > b.virkData.navn ? 1 : -1)
      setRolleListe(res || []);
    } else {
      setRolleListe([]);
    }
    


    setLoading(false);
    props.callBackOnLoaded?.("Done");

  }
  
  function PrintHeader() {
    let printDate = new Date().toLocaleDateString();

    return (
      <div className="only-print print-header" style={{}}>
        <div style={{ display: "inline-block" }}>
          <h4>Personinformation</h4>
          <h5>Printed: {printDate}</h5>
        </div>
      </div>
    );
  }

  let x = ejendomsListe[0]?.ejf_ejere_liste && PropertyInformation.propertyOwnerMatchingName(ejendomsListe[0]?.ejf_ejere_liste, props.personInfo.navn) || undefined
  
  let fødselsdato = props.personInfo.foedselsdato || x?.foedselsdato
  let dateString = fødselsdato ? (new Date(fødselsdato)).toISOString() || x.foedselsdato : "no date";
  let age =  fødselsdato ? calculateAge(new Date(fødselsdato)) : undefined;
  function calculateAge(dob:Date) { 
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }


  return (
    <>
    <PrintHeader />
    <KeyValuesHorizontal>
      <KeyValueLine valKey={"Navn"} value={props.personInfo.navn} />
      <KeyValueLine valKey={"Fødselsdato"} value={fødselsdato && dateString} />
    </KeyValuesHorizontal>

    <GroupSelector
        selected={selectedGroup}
        group={availableGroup}
        onSelect={setSelectedGroup}
      />
      <div className="Scroll-content">
        <ShowSelectedGroup
          keepSelected={true}
          loading={loading}
          columns={props.cols!}
          group={selectedGroup}
          groupCallBack={(groups) =>
            setAvailableGroup([...new Set([...groups, "Alle"])])
          }
          >
            <GlassFoldUdBox group="Person" foldetUd={props.foldetUd} title={"Person Oplysninger"} >
              <KeyValueLine valKey={"Navn"} value={props.personInfo.navn} />
              <KeyValueLine valKey={"Fødselsdato"} value={dateString} />
              <KeyValueLine valKey={"Alder"} value={age} />
            </GlassFoldUdBox>
            <GlassFoldUdBox group="Ejendomme" foldetUd={props.foldetUd} title={`Ejendomme ${ejendomsListe && ejendomsListe.length > 0 ? `(${ejendomsListe?.length})`:""}`} >
              {
                ejendomsListe?.map((ejd) => {
                  let ejerData = PropertyInformation.propertyOwnerMatchingName(ejd.ejf_ejere_liste, props.personInfo.navn);
                  let text = ejd.bfe_adresse; 
                  if (ejerData && ejerData.andelProcent /* && ejerData.andelProcent < 100 */) {
                    text += " ("+ejerData.andelProcent+"%)"
                  }
                  return (
                    <>
                  {RenderLinkToPropertyInfo(text,{lat:ejd.koord_lat, lng:ejd.koord_lng})}<br/>
                    </>
                  );
                })
              }

            </GlassFoldUdBox>
            <GlassFoldUdBox group="Roller" foldetUd={props.foldetUd} title={"Roller"} >
              {
                rolleListe?.map((rel) => {

                  let text = (<>{rel.virkData.navn} (<RenderLinkToCompanyGraph cvrNr={rel.virkData.cvrNummer} />)</>); 
                  let rolleListe = rel.orgsData.map((org, index) =>{
                    if (org.periode?.gyldigTil || !(org.periode?.gyldigFra)) {
                      return ""
                    }
                    return org.navn + " (" + org.periode?.gyldigFra.split("-").reverse().join(".") + "- ... )"; //  + "("+org.hovedType+")";
                  },"").filter((a) => a).join("\n")
                  return (         
                    <KeyValueLine valKey={text} value={rolleListe} />
                  );
                })
              }
            </GlassFoldUdBox>
            <GlassFoldUdBox group="Roller" foldetUd={props.foldetUd} title={"Ophørte Roller"} >
              {
                rolleListe?.map((rel) => {

                  let text = (<>{rel.virkData.navn} (<RenderLinkToCompanyGraph cvrNr={rel.virkData.cvrNummer} />)</>); 
                  let rolleListe = rel.orgsData.map((org, index) =>{
                    if (!(org.periode?.gyldigTil)) {
                      return ""
                    }
                    return org.navn + " (" + org.periode?.gyldigFra.split("-").reverse().join(".") + "-" + org.periode?.gyldigTil.split("-").reverse().join(".") + ")"; //  + "("+org.hovedType+")";
                  },"").filter((a) => a).join("\n")
                  return (         
                    <KeyValueLine valKey={text} value={rolleListe} />
                  );
                })
              }
            </GlassFoldUdBox>

          </ShowSelectedGroup>
    </div>
    
    </>
  ) 
}