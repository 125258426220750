import { destination, lineString, length } from "@turf/turf"
import { Localization, SettingsManager } from "@viamap/viamap2-common"
import jsPDF from "jspdf"
import { message } from "src/components/Message"

export function drawFoldMarkers(
   ctx: CanvasRenderingContext2D,
   canvas: HTMLCanvasElement,
   drawingOptions: {fold ,format, foldFormat, margin}
 ) {
   if (drawingOptions.fold !== true) {
     return
   }
   
   const {height:fHeight, width:fWidth} = paperToSize(drawingOptions.format)
   const fSize = Number(drawingOptions.format.split("A")[1][0])
   const fSizeA0 = Number(drawingOptions.format.split("A")[0] || 1)
   
   const ffSize = Number(drawingOptions.foldFormat.split("A")[1][0])
   const ffSizeA0 = Number(drawingOptions.foldFormat.split("A")[0] || 1)
   const distance = (ffSize - Math.log2(ffSizeA0)) - (fSize - Math.log2(fSizeA0))
   if (distance < 1) {
     return null
   }
   
   const direction = canvas.height < canvas.width   
   
   const lxfolds = Math.floor(distance / 2) + (distance % 2)
   const sxfolds = Math.floor(distance / 2)
   
   const lxTfolds = 2**lxfolds; 
   const sxTfolds = 2**sxfolds; 
   
   
   // ctx.save()
   // ctx.translate(canvas.width / 2, canvas.height / 2)
   ctx.fillStyle = "#000"
   
   let xSize = canvas.width / ((direction && lxTfolds) || sxTfolds);
   let ySize = canvas.height / ((direction && sxTfolds) || lxTfolds);
   
   ctx.save()
   
   for(let i = 0; i < 2; i++) {
     for (let x = 0; x <= lxTfolds; x++) {
       const xcpos = xSize * x;
       ctx.fillRect(xcpos-1, drawingOptions.margin*4, 2, drawingOptions.margin*4 + 25)
     }
     
     for (let y = 0; y <= lxTfolds; y++) {
       const ycpos = ySize * y;
       ctx.fillRect(drawingOptions.margin*4, ycpos-1, drawingOptions.margin*4 + 25, 2)
     }
     
     ctx.translate(canvas.width, canvas.height);
     ctx.rotate(Math.PI);
   }
   ctx.restore()
 }

export function canvasToPDF(canvas: HTMLCanvasElement | string, paperFormat:string) {
      let canvasElem = typeof canvas == "string" ? document.getElementById(canvas) : canvas

      let paper = (""+paperFormat).toLowerCase()
      
      const doc = new jsPDF({
        format: paper.slice(0, - 1),
        orientation: paper.at(-1) as any
      });
      
      let paperS = paperToSize(paperFormat)
      if (canvasElem instanceof HTMLCanvasElement) {
         doc.addImage(canvasElem, 0 ,0, paperS.width, paperS.height)
         doc.save(saveWithTimeStampt("mapit-Plot")+".pdf");
      } else {
         message.error("Coundn't save to PDF")
      }
    }

export function saveWithTimeStampt(name: string) {
   let now = new Date();
   
   let yyyy = numberFixedWidth(now.getFullYear(),4);
   let MM = numberFixedWidth(now.getMonth()+1);
   let dd = numberFixedWidth(now.getDate());
   let hh = numberFixedWidth(now.getHours());
   let mm = numberFixedWidth(now.getMinutes());
   let ss = numberFixedWidth(now.getSeconds());
   
   return yyyy+MM+dd+"_"+name+"_"+hh+mm+ss;
}

function numberFixedWidth(number:number, width:number = 2) {
   let padding = ("0".repeat(width))
   return (padding+number).slice(-width)
 }

export function paperToSize(paper:string): {height:number, width:number} {
   const PaperSizes = {
     "4A0L":{height:1682, width:2378},
     "4A0P":{width: 1682, height:2378},
     "2A0L":{height:1189, width:1682},
     "2A0P":{width: 1189, height:1682},
     "A0L":{height:841, width:1189},
     "A0P":{width: 841, height:1189},
     "A1L":{height:594, width:841},
     "A1P":{width: 594, height:841},
     "A2L":{height:420, width:594},
     "A2P":{width: 420, height:594},
     "A3L":{height:297, width:420},
     "A3P":{width: 297, height:420},
     "A4L":{height:210, width:297},
     "A4P":{width: 210, height:297},
     "A5L":{height:148, width:210},
     "A5P":{width: 148, height:210},
     "A6L":{height:105, width:148},
     "A6P":{width: 105, height:148},
     "A7L":{height:74, width:105},
     "A7P":{width: 74, height:105},
     "A8L":{height:52, width:74},
     "A8P":{width: 52, height:74},
     "A9L":{height:37, width:52},
     "A9P":{width: 37, height:52},
     "A10L":{height:26, width:37},
     "A10P":{width: 26, height:37},
   }
   return PaperSizes[paper] || PaperSizes["A4P"]
 }
type PaperFormats = "4A0L"|"4A0P"|"2A0L"|"2A0P"|"A0L"|"A0P"|"A1L"|"A1P"|"A2L"|"A2P"|"A3L"|"A3P"|"A4L"|"A4P"|"A5L"|"A5P"|"A6L"|"A6P"|"A7L"|"A7P"|"A8L"|"A8P"|"A9L"|"A9P"|"A10L"|"A10P";


// MARK: Paper Projections
export function PlotLayerCornerArr(center, rot, scale, paperFormat) {
   const RadToDeg = 57.2957795
   const sideLength = paperToSize(paperFormat)
   
   let angle = RadToDeg * Math.atan(sideLength.width / sideLength.height)
   let hypo = Math.sqrt(sideLength.width**2 + sideLength.height**2)
   
   let rotation = rot;
   function calcProjectedSideLength(c, scale, rot) {
     return [
       destination(c, (hypo/2)*scale/1_000_000 , rot - angle).geometry.coordinates[0] - c[0],
       destination(c, (hypo/2)*scale/1_000_000 , rot - angle).geometry.coordinates[1] - c[1],
     ]
   }
   
   function transpose(c, sl) {
     return [
       c[0] + sl[0], c[1] + sl[1]
     ]
   }
   
   return [
     transpose(center,calcProjectedSideLength(center, scale, rotation) as [number,number]),
     transpose(center,calcProjectedSideLength(center, scale, rotation + (2 * angle)) as [number,number]),
     transpose(center,calcProjectedSideLength(center, scale, rotation + 180) as [number,number]),
     transpose(center,calcProjectedSideLength(center, scale, rotation + 180 + (2 * angle)) as [number,number]),
     transpose(center,calcProjectedSideLength(center, scale, rotation) as [number,number]),
   ]
 }
 
 export function maxWebGLSize() {
   let OffScreenCanvas = new OffscreenCanvas(1,1)
   let gl = OffScreenCanvas.getContext("webgl")
   let max = gl!.getParameter(gl!.MAX_TEXTURE_SIZE) as number
   return max
 }

 
// MARK: Compose drawing
export async function drawDrawing(
  ctx:CanvasRenderingContext2D, 
  canvas: HTMLCanvasElement, 
  legendCanvas: HTMLCanvasElement,
  map: HTMLCanvasElement | HTMLImageElement,
  drawingOptions: {center, bearing, scale, format, margin, arrowImg, title, description, imgCache, legendIgnoreBlocks, showLegend, attrib, logoImg, includeDate}
) {
  const Corners = PlotLayerCornerArr(drawingOptions.center,drawingOptions.bearing, drawingOptions.scale, drawingOptions.format)
  const CW = canvas.width;
  const CH = canvas.height;
  ctx.clearRect(0, 0, CW, CH);
  ctx.drawImage(map,0,0);
  ctx.fillStyle = "white";
  
  const mm = 8 // ?
  
  const Margin = drawingOptions.margin * mm
  ctx.fillRect(0,0, CW, Margin);
  ctx.fillRect(0,0, Margin, CH);
  ctx.fillRect(0,CH-Margin, CW, Margin);
  ctx.fillRect(CW-Margin,0, Margin, CH);
  
  let Bottom = Corners.map((a) => a).splice(2, 2)
  const BottomLengthKM = length(lineString(Bottom));
  const estW = BottomLengthKM*1000*0.3;
  let restW = estW % 100;
  if (estW < 100) {
    restW = estW % 20;
  }
  if (estW < 20) {
    restW = estW % 5;
  }
  
  const optimalW = estW - restW;
  const ScalaToOptimal = optimalW/estW;
  
  
  ctx.strokeStyle = "#000"
  ctx.lineWidth = 1;
  ctx.beginPath()
  ctx.moveTo(Margin,Margin)
  ctx.lineTo(CW-Margin,Margin)
  ctx.lineTo(CW-Margin,CH-Margin)
  ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin);
  ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin-60);
  ctx.lineTo(Margin, CH-Margin-60);
  ctx.lineTo(Margin,CH-Margin)
  ctx.lineTo(Margin,Margin)
  ctx.lineTo(Margin+10,Margin)
  ctx.stroke()
  
  
  
  const ImgNArrow = await drawingOptions.arrowImg
  if (ImgNArrow) {
    const ImgNArrowScale = 1/3
    const IW = ImgNArrow.width * ImgNArrowScale;
    const IH = ImgNArrow.height * ImgNArrowScale;
    const IBig = IW > IH ? IW : IH;
    const IOFF = Margin + 20 + (IBig/2)
    ctx.save()
    ctx.translate(IOFF,IOFF)
    ctx.rotate(((-(drawingOptions.bearing + 360) % 360) * Math.PI/180))
    ctx.drawImage(ImgNArrow, -IW/2, -IH/2, IW, IH )
    ctx.restore()
  }
  
  
  let totalImgHeight = 0;
  let sigWidth = (210*mm)*3/10;
  ctx.font = "45px Arial";
  let titleWrap = wrapText(ctx, drawingOptions.title.trim() , sigWidth)
  ctx.font = "30px Arial";
  let date = drawingOptions.includeDate ?  " \n " + new Date().toLocaleDateString(Localization.getLocale(), {year:"numeric", month:"2-digit", day:"2-digit"}) : "";
  let lines = wrapText(ctx, (ParseNewlinesAndSpaces(drawingOptions.description).trim() + date).trim(), sigWidth)

  titleWrap.forEach(async (a:string) => {
    let x = a.match(/(^https:\/.+\.(?:svg|png|jpeg))/gm);
    let img = drawingOptions.imgCache?.[a || "a"] || null;
    if (img) {
      let awaited = (await img)
      if (awaited) {
        totalImgHeight += (awaited.height * sigWidth/awaited.width) - 45;
      }
    }
  })
  lines.forEach(async (a:string) => {
    let x = a.match(/(^https:\/.+\.(?:svg|png|jpeg))/gm);
    let img = drawingOptions.imgCache?.[a || "a"] || null;
    if (img) {
      let awaited = (await img)
      if (awaited) {
        totalImgHeight += (awaited.height * sigWidth/awaited.width) - 30;
      }
    }
  })
  
  
  
  let legendScaling = 1.5
  const IgnBlock = drawingOptions.legendIgnoreBlocks
  let dIgnBlock = [...IgnBlock, {start: legendCanvas.height, end: legendCanvas.height}]
  let legendHeight = (drawingOptions.showLegend && dIgnBlock.reduce((a,b) => a - (b.end - b.start), legendCanvas.height)) || 0;
  let box = titleWrap.length * 45 + lines.length * 30 + 15  + totalImgHeight;
  
  // Attrib
  ctx.strokeStyle = "#fff";
  ctx.fillStyle = "#000";
  ctx.font = "18px Arial";
  let attrib = drawingOptions.attrib.filter((a) => a).map((a) => "© " + a);
  attrib.sort((a,b) => {return ctx.measureText(b).width - ctx.measureText(a).width});
  attrib.forEach((str,idx) => {
    let strW = ctx.measureText(str).width
    ctx.strokeText(str, (CW - Margin - 7) - strW, CH-(Margin+legendHeight + 22*8 + idx*19));
    ctx.fillText(str, (CW - Margin - 7) - strW, CH-(Margin+legendHeight + 22*8 + idx*19));
  })
  
  ctx.strokeStyle = "#000";
  ctx.fillStyle = "#fff"
  ctx.fillRect(CW - ((sigWidth)+Margin+30), CH-(Margin+legendHeight + 30 + box+ 15) , sigWidth + 10+20 + 15, legendHeight + 20 + box + 15 + 15)
  ctx.beginPath()
  ctx.moveTo(CW - Margin, CH-(Margin+legendHeight + 30 + box+ 20))
  ctx.lineTo(CW - Margin, CH-(Margin+legendHeight + 30 + box+ 15))
  ctx.lineTo(CW - ((sigWidth)+Margin+30), CH-(Margin+legendHeight + 30 + box+ 15))
  ctx.lineTo(CW - ((sigWidth)+Margin+30) , CH-Margin)
  ctx.stroke()
  
  let currTTop = CH-(2*Margin+ legendHeight + 20 + box + 15);
  ctx.fillStyle = "#000"
  ctx.font = "45px Arial";
  for (let i = 0; i < titleWrap.length; i++) {
    let text = titleWrap[i];
    let img = drawingOptions.imgCache?.[text || "a"] || null;
    if (img) { 
      let awaited = (await img)
      if (awaited) {
        ctx.drawImage(awaited, CW - ((sigWidth)+Margin+10), Margin + currTTop, sigWidth, awaited.height * sigWidth/awaited.width)
        currTTop += (awaited.height * sigWidth/awaited.width);
      }
    } else {
      ctx.fillText(text, CW - ((sigWidth)+Margin+10), 35 + Margin + currTTop)
      currTTop += 45
    }
  }
  ctx.font = "24px Arial";
  for (let i = 0; i < lines.length; i++) {
    let text = lines[i];
    let img = drawingOptions.imgCache?.[text || "a"] || null;
    if (img) { 
      let awaited = (await img)
      if (awaited) {
        ctx.drawImage(awaited, CW - ((sigWidth)+Margin+10), Margin + currTTop, sigWidth, awaited.height * sigWidth/awaited.width)
        currTTop += (awaited.height * sigWidth/awaited.width);
      }
    } else {
      ctx.fillText(text, CW - ((sigWidth)+Margin+10), 35 + Margin + currTTop)
      currTTop += 30
    }
  }
  
  let legendBlockPadding = 20
  let legendInlinePadding = 20
  
  let currTop = 0 + legendBlockPadding + currTTop + 20;
  let lIP = legendInlinePadding
  let legendWidth = sigWidth // awaitedImages.reduce((a,b) => b !== "" ? (b.width > a ? b.width : a) : a , 0) * legnedScaling
  if (legendHeight) {
    ctx.fillStyle = "#fff";
    ctx.fillRect(CW-Margin - legendWidth - lIP, Margin+currTop-legendBlockPadding, legendWidth + lIP, legendHeight+legendBlockPadding*2)
    
    let lOff = 0;
    let inOff = 0;
    const IgnBlock = drawingOptions.legendIgnoreBlocks
    let dIgnBlock = [...IgnBlock, {start: legendCanvas.height, end: legendCanvas.height}]
    
    dIgnBlock.forEach((a, idx) => {
      ctx.drawImage(legendCanvas, 
        0, inOff, legendCanvas.width , a.start - inOff, 
        CW-Margin - legendWidth - lIP/2, Margin+currTop+lOff, legendCanvas.width , a.start - inOff ) 
        lOff += a.start - inOff;
        inOff = a.end;
      });
      
    }
    
    
    const ImgLogo = await drawingOptions.logoImg;
    if (ImgLogo) {
      const desireHeight = SettingsManager.getSystemSetting("plot.logoDesiredHeight",150);
      const IScale = desireHeight/ImgLogo.height;
      const IW = ImgLogo.width * IScale;
      const IH = ImgLogo.height * IScale;
      const IBig = IW > IH ? IW : IH;
      const IOFF = Margin + 30
      const IOFFW = Margin + 30 + sigWidth + SettingsManager.getSystemSetting("plot.logoOffsetX", 0);
      const IOFFH = Margin + SettingsManager.getSystemSetting("plot.logoOffsetY", 0);
      
      ctx.save()
      ctx.translate(CW-(IOFFW),CH-IOFFH)
      // ctx.beginPath();
      // ctx.arc(0, 0, 10, 0, 2 * Math.PI);
      // ctx.stroke();

      ctx.drawImage(ImgLogo, 0, 0, -IW, -IH )
      ctx.restore()
    }
    
    
    
    
    // Ruler
    // let Bottom = Corners.map((a) => a).splice(2, 2)
    // const BottomLengthKM = Turf.length(Turf.lineString(Bottom));
    
    ctx.font = "30px Arial";
    
    // const estW = BottomLengthKM*1000*0.3;
    // let restW = estW % 100;
    // if (estW < 100) {
    //   restW = estW % 20;
    // }
    // if (estW < 20) {
    //   restW = estW % 5;
    // }
    
    // const optimalW = estW - restW;
    // const ScalaToOptimal = optimalW/estW;
    
    
    
    
    const visualW = Math.round(optimalW);
    ctx.beginPath();
    // ctx.moveTo(Margin-5, CH-Margin-100);
    // ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal / 2 + 5, CH-Margin-25 - 10);
    // ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 5, CH-Margin-10 - 10);
    ctx.lineTo(Margin-5, CH-Margin-60);
    ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin-60);
    ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin+5);
    ctx.lineTo(Margin-5, CH-Margin+5);
    ctx.fillStyle = "#fff";
    ctx.fill();
    
    ctx.fillStyle = "#ff5722";
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 5;
    // ctx.fillRect(0, CH-10, CW / 2, 10);
    ctx.fillRect(Margin, CH-Margin-10, CW * 0.3 * ScalaToOptimal, 10);
    ctx.fillRect(Margin + CW * 0.3 * ScalaToOptimal, CH-Margin-10, -5, -5)
    ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal, CH-Margin-10)
    ctx.fillRect(Margin, CH-Margin-15, 5, 12);
    ctx.fillStyle = "#000";
    ctx.strokeText(visualW+ " m", Margin + CW * 0.3 * ScalaToOptimal + 7, CH-Margin-5);
    ctx.fillText(visualW+ " m", Margin + CW * 0.3 * ScalaToOptimal + 7, CH-Margin-5);
    ctx.fillRect(Margin, CH-Margin-25, CW * 0.3 * ScalaToOptimal / 2, 10);
    ctx.fillRect(Margin + CW * 0.3 * ScalaToOptimal / 2, CH-Margin-25, -5, -5);
    ctx.fillRect(Margin, CH-Margin-30, 5, 15);
    ctx.strokeText(visualW/2+ " m", Margin + (CW * 0.3 * ScalaToOptimal) /2 + 7, CH-Margin-17);
    ctx.fillText(visualW/2+ " m", Margin + (CW * 0.3 * ScalaToOptimal) /2 + 7, CH-Margin-17);
    
    let paper = (drawingOptions.format as any)
    paper = paper.slice(0, -1);
    
    
    ctx.fillStyle = "#000"
    ctx.strokeText("1:"+drawingOptions.scale+" ("+(paper)+")", Margin + 7, CH-Margin-34);
    ctx.fillText("1:"+drawingOptions.scale+" ("+(paper)+")", Margin + 7, CH-Margin-34);
    ctx.fillStyle = "#ff5722";
    ctx.fillText("1:", Margin + 7, CH-Margin-34);
    ctx.fillStyle = "#000";
    ctx.fillText("1", Margin + 7, CH-Margin-34);
    
    ctx.fillStyle = "#ff5722";
    
    ctx.fillStyle = "#000";
  }
  
  export function PromiseImage(url:URL|string) {
    return new Promise<HTMLImageElement | "">(async (resolve) => {
      const img = new Image();
      if (url === "") {
        resolve("")
        return
      }
      img.src = url as unknown as string //Cause Url is a string
      img.crossOrigin = "anonymous"
      img.onload = function () {
        resolve(img)
      }
      img.onerror = async function () {
        const corsProxy = SettingsManager.getSystemSetting("ImageCorsProxyUrl");
        const imgProx = new Image();
        let x = await fetch(corsProxy + `https://${(url as string).split("://")[1]}` as unknown as string, {
        headers: {
          accept:"image/png"
        }
      })
      let blobUrl = URL.createObjectURL(await x.blob())
      imgProx.src = blobUrl //Cause Url is a string
      imgProx.crossOrigin = "anonymous";
      imgProx.onload = function () {
        resolve(imgProx)
      }
      imgProx.onerror = function () {
        resolve("")
      }
      
    }
  })
}

function wrapText(ctx:CanvasRenderingContext2D, text:string, maxWidth: number) {
  const spaceWidth = ctx.measureText(" ").width
  const words = text.split(" ").map((a) => {
    const newLine = (a !== "\n")
    return newLine ? {
      word: a,
      width: ctx.measureText(a).width,
      newLine: false 
    } : {word:"",width:0,newLine:true}
  })
  
  let lines:string[] = [];
  let currentLine:string = "";
  let currentLineWidth = 0
  words.forEach((a, idx) => {
    if (a.newLine || currentLineWidth + spaceWidth + a.width > maxWidth) {
      lines.push(currentLine)
      currentLine = a.word
      currentLineWidth = a.width
    } else {
      currentLine += currentLine !== "" ? " " + a.word : a.word;
      currentLineWidth += a.width + spaceWidth
    }
  })
  currentLine && lines.push(currentLine)
  return lines
}


function ParseNewlinesAndSpaces(text: string) {
  return text.replaceAll(/[ ]*[\n]/g," \n ")
}