import { Localization } from "@viamap/viamap2-common";
import { useState, useEffect } from "react";
import { Col, Form, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { ImportTransformation, ImportTransformationAction, ImportTransformationType, SheetAnalysisResult, ValidationType } from "src/common/managers/Types";


type SelectionList =
  {
    value: string;
    label: string;
    comment?: string;
    disabled?: boolean;
  }[];

export function LayerImportDuplicatesForm(props: { analysisResult: SheetAnalysisResult, onChange: (newTransformation: ImportTransformation) => void }) {
  // const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
  // const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
  // const { hasAccessToFeature } = useContext(ApplicationStateContext);

  const [removeDuplicates, setRemoveDuplicates] = useState<boolean>(true);
  const [transformationAction, setTransformationAction] = useState<ImportTransformationAction>(ImportTransformationAction.None);
  const [transformationType, setTransformationType] = useState<ImportTransformationType>(ImportTransformationType.None);
  const [concatenationSeparator, setConcatenationSeparator] = useState<string>(", ");
  const [selectedLayerId, setSelectedLayerId] = useState<any>("1");

  useEffect(() => {
    let result: ImportTransformation = {
      action: ImportTransformationAction.None
    };
    if (!removeDuplicates) {
      let field;
      if (selectedLayerId) {
        let idx = Number.parseInt(selectedLayerId);
        field = props.analysisResult?.columnNames[idx - 1];
      }
      result = {
        action: ImportTransformationAction.DuplicatesTransform,
        transformation: transformationType,
        propertyToTransform: field,
        concatenationSeparator: concatenationSeparator
      };
    }
    props.onChange && props.onChange(result);
  },
    [removeDuplicates, selectedLayerId, transformationType, transformationAction, concatenationSeparator])

  let column1 = 4, column2 = 12 - column1;

  function getSelectWithOptions(defaultValue: any, optionsArray: { value: any, label: any, disabled?: boolean }[], name: string, onChange: any, caption: string, emptyValue: string, validationFunc: () => ValidationType) {
    return (
      <FormGroup
        controlId="formControlsSelect"
        style={{ paddingLeft: "10%", paddingRight: "10%" }}
      >
        <FormLabel>{Localization.getText(caption)}</FormLabel>
        <FormControl
          as="select"
          placeholder={Localization.getText("please select") + " " + caption}
          onChange={(e) => onChange(e)}
          value={defaultValue}
        >
          <option className="small" value=''>{emptyValue.split("|").map((a) => Localization.getText(a)).join(" ")}</option>
          {optionsArray && optionsArray.filter((option) => {
            return (option.disabled === undefined)
          }).map((option, i) => (
            <option key={option.label} className="small" value={option.value}>{option.label}</option>
          ))}
        </FormControl>
      </FormGroup>
    );
  }

  function generateColumList(analysisResult: SheetAnalysisResult): SelectionList {
    // Determine the applicability of each sheet column to the needed information element
    let result: SelectionList = [];
    analysisResult && analysisResult.columns.map((item, idx) => {
      result.push({
        value: (idx + 1).toString(), // one based column index
        label: item.columnLetter+" "+item.name,
        // display value
        comment: ""
      });
    });
    return (result);
  }

  const FormControlElement = (props: { labelElement, controlElement }) => {
    return (
      <FormGroup
        as={Row}
        style={{ marginLeft: "10%", marginRight: "10%", marginBottom: "20px" }}
      // validationState="success"
      >
        <Form.Label column sm={column1} lg={column1}>
          {props.labelElement}
        </Form.Label>
        <Col sm={column2} lg={column2}>
          {props.controlElement}
        </Col>
      </FormGroup>
    )
  }
  /*
      layerInfo.importTransformation = {
        action: ImportTransformationAction.DuplicatesTransform,
        transformation: ImportTransformationType.Concatenate,
        propertyToTransform: "Region",
        concatenationSeparator: ", "
      }
  */
  const SelectTransformation = (props: { title: string, layerId: any, sKey: string, onSelect: (value: string) => void }) => {
    let transformChoices = ["Sum", "Min", "Max", "Avg", "Concatenate","Count"];
    return (
      <>
        <select onChange={e => {
          props.onSelect(e.target.value);
        }}
          className="form-control input-sm"
          style={{ width: "auto" }}
          value={props.sKey}>
          <option key={"default"} value={""} >{Localization.getText("Duplicates:SelectTransformation")}</option>
          {transformChoices.map((property) => {
            return (
              <option disabled={false} key={"" + property} value={property} >{Localization.getText("Duplicates:Transformation:" + property)}</option>
            )
          })}
        </select>
      </>
    )
  }

  // remove or consolidate
  // field to consolidate
  // operations
  let optionsArray = generateColumList(props.analysisResult);
  return (
    <>
      <FormControlElement
        labelElement={(
          <>{Localization.getText("Duplicates Handling")}</>
        )}
        controlElement={(
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}>
            <Form.Check
              label={Localization.getText("Duplicates:Remove")}
              type={"radio"}
              checked={removeDuplicates}
              onChange={(e) => { setRemoveDuplicates(e.target.checked) }}
            />
            <Form.Check
              style={{ marginTop: "0px" }}
              label={Localization.getText("Duplicates:Consolidate")}
              type={"radio"}
              checked={!removeDuplicates}
              onChange={(e) => { setRemoveDuplicates(!e.target.checked) }}
            />
          </div>
        )}
      />
      {!removeDuplicates ? (
        <>
          <FormControlElement
            labelElement={(
              <>{Localization.getText("Transformation")}</>
            )}
            controlElement={(
              <SelectTransformation
                title={Localization.getText("Transformation")}
                layerId={selectedLayerId}
                sKey={transformationType}
                onSelect={(tfm: any) => {
                  setTransformationType(tfm);
                }}
              />
            )}
          />
          {transformationType ? (
            <>
              {getSelectWithOptions(
                selectedLayerId,
                optionsArray,
                selectedLayerId,
                (e) => {
                  let sel = e.target.value;
                  setSelectedLayerId(sel);
                },
                "Transformation:Field",
                "Transformation:EnterField",
                () => { return "success" })
              }
              {transformationType === ImportTransformationType.Concatenate ? (
                <FormControlElement
                  labelElement={(
                    <>{Localization.getText("Transformation:Separator")}</>
                  )}
                  controlElement={(
                    <FormControl
                      size="sm"
                      type="text"
                      value={concatenationSeparator}
                      onChange={(e: any) =>
                        setConcatenationSeparator(e.target.value)}
                    />
                  )}
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
