import './ElasticCvrTypes';

export class PropertyInfoInterface {

   static API_KEY = "QnSGmQ8Icf4aMf7SA41485ZbbfuMCuOgF23GjaYd";

   static async sendRequest(options: any, body?: any) {
      return new Promise((resolve, reject) => {

         let params=body ? Object.keys(body).reduce((prev, curr, idx) => {
            return (idx>0 ? "&":"")+curr+"="+body[curr];
         }, "") : "";
         let url = `${options.protocol}//${options.hostname}${options.path}?${params}`;
         var request = new Request(url, {
            method: `${options.method}`,
            headers: new Headers({
                "Content-Type": "application/json",
                "x-api-key": this.API_KEY
            }),
            body: JSON.stringify(body)
        });
        fetch(request)
        .then(async (res)=> {
         let result;
         if (res.status === 200) {
            // tslint: disable-next-line
            // if (res.headers['content-type'] && (res.headers['content-type'] as string).toLowerCase().startsWith("application/json")) {
               result = await res.json();
            // }
            resolve(result);
         } else {
            console.error("Got error code: " + res.statusText);
            result = await res.text();
            resolve(result);
         }
        })
        .catch((err) => {
            reject("Got error"+err);
        })
      });
   }

   static async getCVRData(cvrNr: number): Promise<any> {

         // var myHeaders = new Headers();
         // myHeaders.append("Authorization", "Basic bW9sYmFrLmRrX0NWUl9JX1NLWUVOOjE1NGVkNzMyLTMxNWEtNDk0NS05Mjc4LTY4ZWJhMTM2YzkwNw==");
         // myHeaders.append("Content-Type", "application/json");

         // var raw = JSON.stringify({
         //    "query": {
         //       "term": {
         //          "Vrvirksomhed.cvrNummer": cvrNr
         //       }
         //    }
         // });

         // var requestOptions = {
         //    method: 'POST',
         //    headers: myHeaders,
         //    body: raw,
         //    // redirect: 'follow'
         // };

         // fetch("http://distribution.virk.dk/cvr-permanent/virksomhed/_search", requestOptions)
         // .then(response => response.text())
         // .then(result => {
         //    resolve(result);
         //    }
         //    )
         // .catch(error => {
         //    reject(error);
         // });

         
      let serviceHost = "eymh7y652k.execute-api.eu-north-1.amazonaws.com/production"; // Utils.getSystemSettingByEnvironment("obliqueServiceHost");
      let servicePath = "/getcvrdata";
      
      let parameters = {
         funktion:"hentElasticCVRNummer",
         id:cvrNr
      };

      let path = servicePath;
      let options = {
         protocol: "https:",
         hostname: serviceHost,
         // port: 443,
         path: path,
         method: 'POST',
         headers: {
            "Content-Type": "application/json",
            "X-API-KEY": this.API_KEY
         }
      };
      
      let res = await this.sendRequest(options, parameters);
      
      return res;

   }
   
   static async getCVRSearch(query:any, index:string="virksomhed"): Promise<any> {

   let serviceHost = "eymh7y652k.execute-api.eu-north-1.amazonaws.com/production"; // Utils.getSystemSettingByEnvironment("obliqueServiceHost");
   let servicePath = "/getcvrdata";
   
   let parameters = {
      funktion:"hentQuery",
      "query": {...query, size:100},
      "index": index
   };

   let path = servicePath;
   let options = {
      protocol: "https:",
      hostname: serviceHost,
      path: path,
      method: 'POST',
      headers: {
         "Content-Type": "application/json",
         "X-API-KEY": this.API_KEY
      }
   };
   
   let res = await this.sendRequest(options, parameters);
   
   return res;

}

   public static extractRelevantData(rawCVRREturn:any) : any {
      function getActiveRecord(recs: {periode:{gyldigFra:string, gyldigTil:string},navn:string}[]):any {
         let active = recs.find((r) => r.periode.gyldigTil === null)
         if (active) {
            return active
         }
         let lastActive = recs.toSorted((a,b) => a.periode.gyldigTil > b.periode.gyldigTil ? -1 : 1)[0]
         if (lastActive.navn) {
            lastActive.navn = "❗"+lastActive.navn
            return lastActive
         }
         return null
      }

      if (rawCVRREturn.hits) {
         if (rawCVRREturn.hits.total !== 1) {
            return {message:"Unexpected hit count: "+rawCVRREturn.hits}
         } else {
            let virk = rawCVRREturn.hits.hits[0]._source.Vrvirksomhed;

            let deltagereEjereOrg = (virk.deltagerRelation as any[]).reduce<any[]>((prev, delt) => {
               let ejerOrganisationer = (delt.organisationer as any[]).filter((deltorg) => {
                  if (deltorg.hovedtype === "FULDT_ANSVARLIG_DELTAGERE") {
                     let navn = getActiveRecord(deltorg.medlemsData?.[0]?.attributter?.[0]?.vaerdier)?.vaerdi 
                     let res = navn === "INTERESSENTER";
                     res ??= getActiveRecord(deltorg.organisationsNavn)?.navn;
                     return res;
                  }
                  if (deltorg.hovedtype === "REGISTER") {
                     let navn = getActiveRecord(deltorg.organisationsNavn).navn;
                     let res = navn === "EJERREGISTER";
                     return res;
                  } else  {
                     return false;
                  }
               });
               if (ejerOrganisationer.length === 1) {
                  prev.push({deltager: delt.deltager, ejerOrg: ejerOrganisationer[0]});
               } else {
                  if (ejerOrganisationer.length > 1) {
                     throw new Error("Unexpected orgs count: "+ejerOrganisationer.length);
                  }
               }
               return prev; 
            }, []);
            let attributter = (virk.attributter as any[]).map((att) => {
               let vrec=getActiveRecord(att.vaerdier);
               return {
                  "type":att.type,
                  "vaerditype":att.vaerditype,
                  "vaerdi": vrec && vrec.vaerdi ||"NOV"
               }
            })
            return {
               "cvrNummer": virk.cvrNummer,
               "navn" : getActiveRecord(virk.navne).navn,
               "branchekode": getActiveRecord(virk.hovedbranche)?.branchekode,
               "branchetekst": getActiveRecord(virk.hovedbranche)?.branchetekst,
               "attributter" : attributter,
               "ejere" : deltagereEjereOrg.reduce((prev, {deltager, ejerOrg}) => {
                  let ejer = deltager;
                  // find ejeranddel
                  let andelAtt = ejerOrg.medlemsData && ejerOrg.medlemsData[0].attributter.filter((att:any) => att.type === "EJERANDEL_PROCENT");
                  let andelV = andelAtt?.length && getActiveRecord(andelAtt[0].vaerdier);
                  const fuldAnsvar = ejerOrg.hovedtype === "FULDT_ANSVARLIG_DELTAGERE";
                  let andelVaerdi = (andelV && andelV.vaerdi) || (fuldAnsvar && 1) || "NOTF";

                  if (andelVaerdi !== "NOTF") {
                     // konverter til andel til procent.
                     andelVaerdi *= 100;
                     return [...prev, {
                        "enhedstype": ejer.enhedstype,
                        "forretningsnoegle": ejer.forretningsnoegle,
                        "cvrPersonId": ejer.enhedsNummer,
                        "navn": getActiveRecord(ejer.navne).navn,
                        "andel": andelVaerdi
                     }]
                  }  else {
                     return prev;
                  }
               },[])
            }
         }
      } else {
         return {message:"Unexpected record format"}
      }
   }

   static formatAddress(beliggenhedsAdresse:any):string {
      function nvl(value:any):string {
         return value ? value : "";
      }
      let ba = beliggenhedsAdresse;
      let result="";

      if (ba.conavn) {
         result += "c/o "+ba.conavn+"; ";
      }
      if (ba.vejnavn) {
         result += ba.vejnavn+" ";
      }
      let nummer = nvl(ba.husnummerFra)+nvl(ba.bogstavFra)+ (ba.husnummerTil ? "-"+nvl(ba.husnummerTil)+nvl(ba.bogstavTil) : "");
      let etage = ba.etage ? " "+ba.etage+"." : "";
      let side = ba.sidedoer ? " "+ba.sidedoer : "";
      if (nummer && nummer.length > 0) {
         result += nummer+etage+side;
      }
      let postboks = ba.postboks ?  " "+ba.postboks+", " : "";
      let landekode = ba.landekode ? " "+ba.landekode+(ba.postnummer?"-":"") : "";
      result += `${postboks}${landekode}${nvl(ba.postnummer)} ${nvl(ba.postdistrikt)}`;

      return result;
   }

}