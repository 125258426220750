import { Button, Alert, Table } from 'react-bootstrap';
import {Localization} from "@viamap/viamap2-common";
import {MitDataType, LayerInfo, RowDataQuality} from '../common/managers/Types';
import { SheetFunc } from '../managers/SheetFunc';
import { MitCheckBox } from './ComponentUtils';
import DataGrid from 'react-data-grid';
import { createPortal } from 'react-dom';
import { PosPicker } from './PosPicker';
import { useState, useEffect, useRef } from 'react';
import { AiFillCheckCircle, AiFillEdit, AiFillExclamationCircle, AiFillEye, AiFillFlag } from 'react-icons/ai';
import { ExportData } from 'src/managers/ExportData';
import { ADModalBody, ADModalFooter, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton } from './GlassButtons';
import { GlassCheckbox } from './MitGlassComponents';
// IMPORT: VIEW IMPORT
type State = {
    showWindow: boolean;
    onFormClose: ()=>void;
    layerInfo: LayerInfo;
    rows: any;
    cols: any;
    originalRows: any;
    selectedIndexes: number[];
    groupBy: any[];
    expandedRows: {};
    showAll:boolean;
    isGeoCoding:boolean;
    sortColumn:string;
    sortDirection:"ASC" | "DESC" | "NONE" | undefined;
    showAlert:boolean;
};
type Props = {
    showWindow: boolean;
    onFormClose: ()=>void;
    layerInfo: LayerInfo;
    dataUpdate: (a:any)=>void
};

// Custom Formatter components

export function ImportQualityFormatter(props:{row:any}, value?:any) {
    switch (props.row.quality) {
        case RowDataQuality.Good:
        return <AiFillCheckCircle style={{color:"#5cb85c"}} />
        case RowDataQuality.Bad:
        return <AiFillExclamationCircle style={{color:"#d9534f"}} />
        case RowDataQuality.Error:
        return <AiFillExclamationCircle style={{color:"red"}} />
        case RowDataQuality.Warning:
        return <AiFillFlag style={{color: "#f0ad4e"}} />
        case RowDataQuality.UserEdit:
        return <AiFillEdit style={{color:"#5cb85c"}} />
        default:
        return <AiFillEye style={{color:'grey'}} />
    }
  }


function EmptyRowsView() {
  return (<Alert id="mit-import-details-nothing-alert">{Localization.getText("Nothing to show")}</Alert>);
}

export function ImportResultDetails (props: Props) {
    let [cols,setCols] = useState<any>([])
    let [rows,setRows] = useState<any>([])
    let [selectedIndexes,setSelectedIndexes] = useState<number[]>()
    let [showAll,setShowAll] = useState<boolean>(true)
    let [originalRows,setOriginalRows] = useState<any>(createRows(props.layerInfo, showAll))
    let [isGeoCoding,setIsGeoCoding] = useState<boolean>(Boolean(props.layerInfo && props.layerInfo.data![MitDataType.GeoCode_Result_Quality]))
    let [sortColumn,setSortColumn] = useState<string>("quality")
    let [sortDirection,setSortDirection] = useState<"ASC" | "DESC" | "NONE" | undefined>("ASC")
    let [showAlert,setShowAlert] = useState<boolean>()

    let grid = useRef()

    useEffect(() => {
        if (props.showWindow) {
            let originalRows = createRows(props.layerInfo, showAll)
            let hasUTM32:boolean = Boolean(props.layerInfo && props.layerInfo.data![MitDataType.GeoCode_Result_Quality]);
            setCols((hasUTM32 ? _columnsGeocodeWUTM : _columnsGeocode).map(a => {return {...a, name:Localization.getText(a.name)}}));
            let newRows = _handleGridSort (originalRows, originalRows, sortColumn, sortDirection)
            setOriginalRows(originalRows)
            setRows(newRows);
        }
    },[props])

    function onClickClose() {
        props.onFormClose();
    }

    function lookupTitleByKey(key:string) {
        let result:string=key;
        cols.forEach((element) => {
            if (element.key === key) {
                result = element.name;
            }
        });
        return result;
    }

    // excludeColumns(rows:any[], keyList:string[]):any[] {
    //     let result:any[]=[];
    //     rows.forEach(element => {
    //         let newElm = element;
    //         keyList.forEach((key) => {
    //             delete newElm[key];
    //         });    
    //         result.push(newElm);
    //     });
    //     return result;
    // }

    function onClickDownload() {
        let rows = createRowsForExport(props.layerInfo, showAll, true);
        let wb = SheetFunc.createImportResultWorkBook(rows);
        let blob = SheetFunc.createBlob(wb);
        let fileName = Localization.getText("FileName:ImportResult");
        fileName += ".xlsx";
        ExportData.downloadBlob(fileName, blob);
    }
  
    function onRowsSelected(rows) {
        if (selectedIndexes)
        setSelectedIndexes(selectedIndexes.concat(rows.map(r => r.rowIdx)));
    }
    
    function onRowsDeselected(rows) {
        let rowIndexes = rows.map(r => r.rowIdx);
        if (selectedIndexes)
        setSelectedIndexes(selectedIndexes.filter(i => rowIndexes.indexOf(i) === -1 ));
      }

    function updateLayerInfo(layerInfo:LayerInfo, rows: {[key:string]:any}[]) {
        let sortedRows = rows.sort((a,b) => a.layIdIndex - b.layIdIndex);
        if (layerInfo.data === undefined) {
            return;
        }
        let data = layerInfo.data
        sortedRows.forEach(row => {
            data[MitDataType.GeoCode_Result_Address][row.layIdIndex] = row.resultAdr;
            data[MitDataType.Coord_WGS84_Lat][row.layIdIndex] = row.lat;
            data[MitDataType.Coord_WGS84_Lon][row.layIdIndex] = row.lng;
        });
        props.dataUpdate({...data})
    }

    function createRows(layerInfo:LayerInfo, showAll:boolean):any[] {
        let rows:any[] = [];

        if(layerInfo && layerInfo.data) {
            for (let i = 0; i < layerInfo.data![MitDataType.Row_Data_Quality].length; i++) {
                let quality = layerInfo.data![MitDataType.Row_Data_Quality][i];
                if (quality !== RowDataQuality.Good || showAll) {
                    let qualityText = quality &&  Localization.getText(quality);
                    const note = layerInfo.data![MitDataType.Row_Data_Quality_Note][i];
                    const adr=layerInfo.data![MitDataType.AddressesConcatenated] && layerInfo.data![MitDataType.AddressesConcatenated][i];
                    const resultAdr=layerInfo.data![MitDataType.GeoCode_Result_Address] && layerInfo.data![MitDataType.GeoCode_Result_Address][i];
                    let dawaquality=layerInfo.data![MitDataType.GeoCode_Result_Quality] && layerInfo.data![MitDataType.GeoCode_Result_Quality][i];
                    dawaquality = dawaquality && Localization.getText(dawaquality);
                    const lat=layerInfo.data![MitDataType.Coord_WGS84_Lat] && layerInfo.data![MitDataType.Coord_WGS84_Lat][i];
                    const lng=layerInfo.data![MitDataType.Coord_WGS84_Lon] && layerInfo.data![MitDataType.Coord_WGS84_Lon][i];
                    const easting=layerInfo.data![MitDataType.Coord_UTM32_X] && layerInfo.data![MitDataType.Coord_UTM32_X][i];
                    const northing=layerInfo.data![MitDataType.Coord_UTM32_Y] && layerInfo.data![MitDataType.Coord_UTM32_Y][i];
                    rows.push({
                        rowno:i+2, // Add 1 for zerobased index and 1 for the header row
                        quality:quality,
//                        all: {adr, resultAdr, note},
                        note: note,
                        dawaquality: dawaquality,
                        adr: adr,
                        resultAdr: resultAdr,
                        lat:lat,
                        lng:lng,
                        easting:easting,
                        northing:northing,
                        layIdIndex: i,
                    });
                }
            }
        }

        return rows;
    }    

    function IncludesUTM(easting, northing) {
        if (easting && northing) {
            return {Eastring: easting, Northing: northing}
        }
        return {}
    }

    function createRowsForExport(layerInfo:LayerInfo, showAll:boolean, isGeoCoding:boolean):any[] {
        let exportData = originalRows.toSorted((a,b) => a.rowno-b.rowno)
        let rows = exportData.map((a, idx) => {
            let quality = a.quality;
            if (quality == RowDataQuality.Good && !showAll) {
                return undefined
            }

            return {
                Rowno : idx+2, // Add 1 for zerobased index and 1 for the header row
                Quality : quality && Localization.getText(quality),
                Dawaquality : a.dawaquality,
                InputAddress : a.adr,
                Result : a.resultAdr,
                Lat : a.lat,
                Lng : a.lng,
                ...IncludesUTM(a.easting, a.northing),
                Note : a.note,
            }
        }).filter((a) => a)

        return rows;
    }

    function _handleGridSort (originalRows:any[], rows:any[], sortColumn:any, sortDirection:any) {
        // Note: secondary sort field is always "rowno".
        const comparer = (a, b) => {
        if (sortDirection === 'ASC') {
            return (a[sortColumn] > b[sortColumn]) ? 1 : (a[sortColumn] < b[sortColumn]) ? -1 : (a.rowno > b.rowno) ? 1: -1;
        } else if (sortDirection === 'DESC') {
            return (a[sortColumn] < b[sortColumn]) ? 1 : (a[sortColumn] > b[sortColumn]) ? -1 : (a.rowno < b.rowno) ? 1: -1;
        } else {
            return 0;
        }
        };

        const result = sortDirection === 'NONE' ? originalRows.slice(0) : rows.sort(comparer);
        return result;
    }

    function handleGridSort (sortColumn:any, sortDirection:any) {
        let newRows = _handleGridSort(originalRows, rows, sortColumn, sortDirection);
        setSortColumn(sortColumn)
        setSortDirection(sortDirection)
        setRows(newRows)
    
        
    }

    function rowGetter(i:any) {
        return rows[i];
    }

    function onChangeShowAll(e:any) {
        let showAll=e.target.checked;
        let originalRows = createRows(props.layerInfo, showAll);
        let newRows = originalRows.slice(0);
        newRows = _handleGridSort(originalRows, newRows, sortColumn,sortDirection);

        setShowAll(showAll)
        setOriginalRows(originalRows)
        setRows(newRows)
    }

    function handleAlertDismiss() {
        setShowAlert(false);
    }

        if (props.showWindow) {

        const nrRows=props.layerInfo.analysisResult!.noOfRows-1;
        const rowsGood=props.layerInfo.data![MitDataType.Row_Data_Quality].filter(
            (obj:RowDataQuality) => {
                return obj === RowDataQuality.Good;
            }).length;
        const rowsBad=props.layerInfo.data![MitDataType.Row_Data_Quality].filter(
            (obj:RowDataQuality) => {
                return obj === RowDataQuality.Bad;
            }).length;
        const rowsWarning=props.layerInfo.data![MitDataType.Row_Data_Quality].filter(
            (obj:RowDataQuality) => {
                return obj === RowDataQuality.Warning;
            }).length;
        const rowsError=props.layerInfo.data![MitDataType.Row_Data_Quality].filter(
            (obj:RowDataQuality) => {
                return obj === RowDataQuality.Error;
            }).length;

        const rowsErrorOrBad = rowsError + rowsBad;
        let warningType;
        let warningText;
        if (rowsErrorOrBad > 0 && rowsWarning > 0) {
            // Both errors and warnings
            warningType="danger";
            let dp = Localization.getText(rowsErrorOrBad > 1? "data points": "data point");
            let dp2 = Localization.getText(rowsWarning > 1? "data points": "data point");
            warningText = Localization.getFormattedText(
                "Oops. Import was not a complete success.  {noOf} {dp} could not be displayed and {noOf2} {dp2} needs checking",
                {noOf:rowsErrorOrBad,dp:dp, noOf2:rowsWarning, dp2:dp2});
        } else {
            if (rowsErrorOrBad > 0) {
                // Only errors. No warnings
                warningType="danger";
                let dp = Localization.getText(rowsErrorOrBad > 1? "data points": "data point");
                warningText = Localization.getFormattedText(
                    "Oops. Import was not a complete success.  {noOf} {dp} could not be displayed.",
                    {noOf:rowsErrorOrBad, dp:dp});
            } else {
                // Only warnings. No errors
                warningType="warning";
                let dp = Localization.getText(rowsWarning > 1? "data points": "data point");
                warningText = Localization.getFormattedText(
                    "Oops. Import was not a complete success. {noOf} {dp} needs checking",
                    {noOf:rowsWarning, dp:dp});
            }
        }

        let warningSection= rowsGood===nrRows || !showAlert ? null : (
            <Alert variant={warningType} onClose={()=>handleAlertDismiss()}>
            <h4>
            {warningText}
            </h4>      
            </Alert>
        );
            
        let closeButton = (
            <div>x</div>
          );

        let downloadButton = (
            <Button 
                size="sm"
                type="button"
                // bsStyle="primary"
                id="mit-demography-report-download-button"
                onClick={()=> onClickDownload()}
            >
            {Localization.getText("Download")}
            </Button>
          );

        if (!props.showWindow) {
            return <></>
        }

          // TODO: Download button and closeButton
        return createPortal(
            <AdvancedDragModal
                title={Localization.getText("Import Details")}
                PosDefault={{left: "50%", transform: "translate(-50%, 0%)", top: "10%"}}
            >
                <ADModalBody>                
                        {warningSection}
                        <Table striped={true} bordered={true} cellPadding="2" cellSpacing="4">
                        <tbody>
                            <tr>
                            <td colSpan={3}><strong>{props.layerInfo.datasetname}</strong></td>
                            </tr>
                            <tr>
                            <td>{Localization.getText(RowDataQuality.Good)}: {rowsGood}</td>
                            <td>{Localization.getText(RowDataQuality.Warning)}: {rowsWarning}</td>
                            <td>{Localization.getText(RowDataQuality.Bad)}: {rowsErrorOrBad}</td>
                            </tr>
                        </tbody>
                        </Table>
                        <DataGrid
                                //onGridSort={(a,b) => this.handleGridSort(a,b)}
                            columns={cols}
                            className='rdg-light'
                            rows={rows}
                            onRowsChange={(a,b) => updateLayerInfo(props.layerInfo, a as any)}
                            //rowsCount={this.state.rows.length}
                            //minHeight={500}
                            //minWidth={700}
                            //sortColumn={this.state.sortColumn}
                            //sortDirection={this.state.sortDirection}
                            //emptyRowsView={EmptyRowsView}

                            // // rowKey="id"
                            // onRowExpandToggle={this.onRowExpandToggle}
                            // rowSelection={{
                            //     showCheckbox: true,
                            //     enableShiftSelect: true,
                            //     onRowsSelected: this.onRowsSelected,
                            //     onRowsDeselected: this.onRowsDeselected,
                            //     selectBy: {
                            //       indexes: this.state.selectedIndexes
                            //     }
                            //   }}
                        />

                        <div className="row">
                        <div className="col-sm-3" style={{marginTop:"4pt"}} id="mit-import-details-checkbox-container">
                        
                        </div>
                        <div className="col-sm-3" >
                        
                        </div>
                        <div className="col-sm-3" >{' '}</div>
                    </div>
                    </ADModalBody>
                    <ADModalFooter>
                    <GlassCheckbox 
                        style={{marginRight:"auto", marginLeft:"6px"}}
                        checked={showAll}
                        onClick={(e) => {onChangeShowAll(e);}}
                        >{Localization.getFormattedText("Show All ({count})",{count:props.layerInfo.analysisResult!.noOfRows-1})}
                        </GlassCheckbox>
                    <GlassButton 
                            onClick={()=> onClickDownload()}
                        >
                        {Localization.getText("Download")}
                        </GlassButton>
                        
                        <GlassButton 
                            onClick={()=> onClickClose()}
                        >
                        {Localization.getText("Close")}
                        </GlassButton>
                    </ADModalFooter>
            </AdvancedDragModal>
        , document.getElementById("Mit-MapOverlay") || document.body); 
        } else {
            return(null);
        }
}

const _columnsGeocodeWUTM = [
    {
        key: 'rowno',
        name: 'Row No',
//                width: 80,
        sortable: true,
        resizable: true,
    },
    {
        key: 'quality',
        name: 'Quality',
//                width: 60,
        sortable: true,
        resizable: true,
        formatter: ImportQualityFormatter,
    },
    {
        key: 'adr',
        name: 'Input Address',
        width: 300,
        sortable: true,
        resizable: true,
    },
    {
        key: 'resultAdr',
        name: 'Result Address',
        width: 300,
        sortable: true,
        resizable: true,
        editor: ({ row, onRowChange, onClose}:any) => {
            return createPortal(<PosPicker row={row} onRowChange={onRowChange} onClose={onClose} />, document.body);
        },
        editorOptions: {
            renderFormatter: true,
            editOnClick: true,
        }
    },
    {
        key: 'lat',    
        name: 'Latitude',
        width: 100,
        sortable: true,
        resizable: true,
    },
    {
        key: 'lng',
        name: 'Longitude',
        width: 100,
        sortable: true,
        resizable: true,
    },
    {
        key: 'easting',    
        name: 'Easting',
        width: 100,
        sortable: true,
        resizable: true,
    },
    {
        key: 'northing',
        name: 'Northing',
        width: 100,
        sortable: true,
        resizable: true,
    },
    ];

    
const _columnsGeocode = [
        {
            key: 'rowno',
            name: 'Row No',
//                width: 80,
            sortable: true,
            resizable: true,
        },
        {
            key: 'quality',
            name: 'Quality',
//                width: 60,
            sortable: true,
            resizable: true,
            formatter: ImportQualityFormatter,
        },
//             {
//                 key: 'all',
//                 name: 'Quality',
// //                width: 60,
//                 sortable: true,
//                 resizable: true,
//                 formatter: ImportResultFormatter,
//             },
//             {
//                 key: 'note',
//                 name: 'Note',
// //                width: 250,
//                 sortable: true,
//                 resizable: true,
//             },
//             {
//                 key: 'dawaquality',
//                 name: 'DawaQuality',
// //                width: 80,
//                 sortable: true,
//                 resizable: true,
//
//             },
        {
            key: 'adr',
            name: 'Input Address',
            width: 300,
            sortable: true,
            resizable: true,
        },
        {
            key: 'resultAdr',
            name: 'Result Address',
            width: 300,
            sortable: true,
            resizable: true,
            editor: ({ row, onRowChange, onClose}:any) => {
                return createPortal(<PosPicker row={row} onRowChange={onRowChange} onClose={onClose} />, document.body);
            },
            editorOptions: {
                renderFormatter: true,
                editOnClick: true,
            }
        },
        {
            key: 'lat',    
            name: 'Latitude',
            width: 100,
            sortable: true,
            resizable: true,
        },
        {
            key: 'lng',
            name: 'Longitude',
            width: 100,
            sortable: true,
            resizable: true,
        },
        ];

const _columnsOther = [
        {
            key: 'rowno',
            name: 'Row No',
            width: 80,
            sortable: true,
            resizable: true,
        },
        {
            key: 'quality',
            name: 'Quality',
            width: 60,
            sortable: true,
            resizable: true,
            formatter: ImportQualityFormatter,
        },
        {
            key: 'note',
            name: 'Note',
            width: 450,
            sortable: true,
            resizable: true,
        },
        ];
