import { Localization, Utils } from "@viamap/viamap2-common";
import { SimpleTranslationTable } from "@viamap/viamap2-common";
import { BBOXTURF } from "../compentsModus/EmbeddedVectorMap";
import { ReferenceGeomDenmark } from "../managers/ReferenceGeomDenmark";
import { MitStorage } from "src/managers/MitStorage";
import { Feature } from "./ApplicationState";
import { createContext } from "react";

export enum MessageType { 
   Error="Error",
   Success="Success"
}

// ---------------------------------------------------- STATE --------------------------------------------------------------
type Filter = {
   item:string,
   value:string | string[]
}


export enum FilterType {
   // Must be strings to allow setting value from a config file (string)
   StaticValue = "StaticValue", 
   String = "String", 
   Integer = "Integer", 
   IntegerRange = "IntegerRange", 
   DateRange = "DateRange", 
   Select = "Select", 
   SelectText = "SelectText", 
   SelectTextSlider = "SelectTextSlider",
   BoundingBox = "BoundingBox", 
   Polygon = "Polygon",
   FilterType = "FilterType", 
   SingleSelect = "SingleSelect",
   Number = "Number",
   Color = "Color",
   IntegerSet = "IntegerSet", /* a list of integers which at least one should match  (An OR-search) */
   PolygonFromLayer = "PolygonFromLayer" /* Create polygon filter from a Map Area Layer */
};

export type FilterSpec = {
   filterType:FilterType,
   title: string,
   default: any[],
   options?: {[item:string]:string}
   hideOnFilterList?:boolean,
   translationTable: SimpleTranslationTable,
   translateOptions?:boolean // ToDo: long term all options should be translated
   index_field_override?:string // Option to search a different field than the filter KEY value (when having two different keys searching the same field, in different ways)
   licenseFeature?:string // Option to specify a Feature that must be available for this filter to be shown
}

export type FilterValue = undefined | string[] | any[] | BBOXTURF;

export interface ExploreSearchState {
   // product:string,
   // userGroup:string,
   // trialDays:number,
   activeTransactions:{[guid:string]:any},
   message:string,
   messageType:MessageType,
   selectableFilterList: {[id:string]:FilterSpec},
   favoritFilters: string[]
   activeFilters: {[id:string]: FilterValue},
}
// ToDo: Rename to SearchState and move any global settings to ApplicationState
//
// ---------------------------------------------------- INITIAL STATE --------------------------------------------------------------

export function initialExploreSearchState(): ExploreSearchState {

   return {
      // product:"es demo",
      // userGroup:"any",
      // trialDays:30,
      activeTransactions:{},
      message:"",
      messageType:MessageType.Success,
      favoritFilters: (MitStorage.getValue("FilterFavoritSearch") || []),
      activeFilters: (MitStorage.getValue("FilterSearch") || {}),
      selectableFilterList : {
         "bbr_anvendelse": { title:"Building Usage", filterType:FilterType.SelectText, default: [],
         translationTable: {"en":{"Building Usage":"Building Usage"},"da":{"Building Usage":"Bygningens Anvendelse"}},
            options: {
               110: "Stuehus til landbrugsejendom",
               120: "Fritliggende enfamilieshus (parcelhus)",
               121: "Sammenbygget enfamiliehus",
               130: "(UDFASES) Række-, kæde-, eller dobbelthus (lodret adskillelse mellem enhederne).",
               131: "Række- og kædehus",
               132: "Dobbelthus",
               140: "Etagebolig-bygning, flerfamiliehus eller to-familiehus",
               150: "Kollegium",
               160: "Boligbygning til døgninstitution",
               185: "Anneks i tilknytning til helårsbolig.",
               190: "Anden bygning til helårsbeboelse",
               210: "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o. lign",
               211: "Stald til svin",
               212: "Stald til kvæg, får mv.",
               213: "Stald til fjerkræ",
               214: "Minkhal",
               215: "Væksthus",
               216: "Lade til foder, afgrøder mv.",
               217: "Maskinhus, garage mv.",
               218: "Lade til halm, hø mv.",
               219: "Anden bygning til landbrug mv.",
               220: "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o.lign.)",
               221: "Bygning til industri med integreret produktionsapparat",
               222: "Bygning til industri uden integreret produktionsapparat",
               223: "Værksted",
               229: "Anden bygning til produktion",
               230: "(UDFASES) El-, gas-, vand- eller varmeværk, forbrændingsanstalt m.v.",
               231: "Bygning til energiproduktion",
               232: "Bygning til forsyning- og energidistribution",
               233: "Bygning til vandforsyning",
               234: "Bygning til håndtering af affald og spildevand",
               239: "Anden bygning til energiproduktion og -distribution",
               290: "(UDFASES) Anden bygning til landbrug, industri etc.",
               310: "(UDFASES) Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910",
               311: "Bygning til jernbane- og busdrift",
               312: "Bygning til luftfart",
               313: "Bygning til parkering- og transportanlæg",
               314: "Bygning til parkering af flere end to køretøjer i tilknytning til boliger",
               315: "Havneanlæg",
               319: "Andet transportanlæg",
               320: "(UDFASES) Bygning til kontor, handel, lager, herunder offentlig administration",
               321: "Bygning til kontor",
               322: "Bygning til detailhandel",
               323: "Bygning til lager",
               324: "Butikscenter",
               325: "Tankstation",
               329: "Anden bygning til kontor, handel og lager",
               330: "(UDFASES) Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
               331: "Hotel, kro eller konferencecenter med overnatning",
               332: "Bed & breakfast mv.",
               333: "Restaurant, café og konferencecenter uden overnatning",
               334: "Privat servicevirksomhed som frisør, vaskeri, netcafé mv.",
               339: "Anden bygning til serviceerhverv",
               390: "(UDFASES) Anden bygning til transport, handel etc",
               410: "(UDFASES) Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o. lign.",
               411: "Biograf, teater, koncertsted mv.",
               412: "Museum",
               413: "Bibliotek",
               414: "Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund",
               415: "Forsamlingshus",
               416: "Forlystelsespark",
               419: "Anden bygning til kulturelle formål",
               420: "(UDFASES) Bygning til undervisning og forskning (skole, gymnasium, forskningslabratorium o.lign.).",
               421: "Grundskole",
               422: "Universitet",
               429: "Anden bygning til undervisning og forskning",
               430: "(UDFASES) Bygning til hospital, sygehjem, fødeklinik o. lign.",
               431: "Hospital og sygehus",
               432: "Hospice, behandlingshjem mv.",
               433: "Sundhedscenter, lægehus, fødeklinik mv.",
               439: "Anden bygning til sundhedsformål",
               440: "(UDFASES) Bygning til daginstitution",
               441: "Daginstitution",
               442: "Servicefunktion på døgninstitution",
               443: "Kaserne",
               444: "Fængsel, arresthus mv.",
               449: "Anden bygning til institutionsformål",
               490: "(UDFASES) Bygning til anden institution, herunder kaserne, fængsel o. lign.",
               510: "Sommerhus",
               520: "(UDFASES) Bygning til feriekoloni, vandrehjem o.lign. bortset fra sommerhus",
               521: "Feriecenter, center til campingplads mv.",
               522: "Bygning med ferielejligheder til erhvervsmæssig udlejning",
               523: "Bygning med ferielejligheder til eget brug",
               529: "Anden bygning til ferieformål",
               530: "(UDFASES) Bygning i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.)",
               531: "Klubhus i forbindelse med fritid og idræt",
               532: "Svømmehal",
               533: "Idrætshal",
               534: "Tribune i forbindelse med stadion",
               535: "Rideskole",
               539: "Anden bygning til idrætformål",
               540: "Kolonihavehus",
               585: "Anneks i tilknytning til fritids- og sommerhus",
               590: "Anden bygning til fritidsformål",
               910: "Garage (med plads til et eller to køretøjer)",
               920: "Carport",
               930: "Udhus",
               940: "Drivhus",
               950: "Fritliggende overdækning",
               960: "Fritliggende udestue",
               970: "Tiloversbleven landbrugsbygning",
               990: "Faldefærdig bygning",
               999: "Ukendt bygning"
            }},
         "bbr_opfoerelsesAAr": { title:"Construction year", filterType:FilterType.IntegerRange, default: ["1900", "2100"] ,
         translationTable: {"en":{"Construction year":"Construction year"},"da":{"Construction year":"Opførelsesår"}} },
         "bbr_samletBygningsAreal": { title: "Building area", filterType:FilterType.IntegerRange, default: ["0", "10000"],
         translationTable: {"en":{"Building area":"Building area"},"da":{"Building area":"Samlet bygningsareal"}}},
         "bbr_samletErhvervsAreal": { title: "Commercial area", filterType:FilterType.IntegerRange, default: ["0", "10000"],
         translationTable: {"en":{"Commercial area":"Commercial area"},"da":{"Commercial area":"Bygningens erhvervsareal"}}},
         "bbr_bygningensSamledeBoligAreal": { title: "Building living Area", filterType:FilterType.IntegerRange, default: ["0", "10000"],
         translationTable: {"en":{"Building living Area":"Building living Area"},"da":{"Building living Area":"Bygningens beboelsesareal"}}},
         // Disabled for now, as BBR is not consistent in the data, and this leads to confusion
         // "bbr_ejd_samletBygningsAreal": { title: "Building area", filterType:FilterType.IntegerRange, default: ["0", "10000"],
         // translationTable: {"en":{"Building area":"Property Building area"},"da":{"Building area":"Ejendommens bygningsareal"}}},
         // "bbr_ejd_samletErhvervsAreal": { title: "Commercial area", filterType:FilterType.IntegerRange, default: ["0", "10000"],
         //    translationTable: {"en":{"Commercial area":"Property Commercial area"},"da":{"Commercial area":"Ejendommens erhvervsareal"}}},
         "bbr_ejd_bygningensSamledeBoligAreal": { title: "Building living Area", filterType:FilterType.IntegerRange, default: ["0", "10000"],    
         translationTable: {"en":{"Building living Area":"Property living Area"},"da":{"Building living Area":"Ejendommens beboelsesareal"}}},
         "ejf_afdoed": { title: "Deceased", filterType:FilterType.SingleSelect, default:[""], 
            options: { "true":"yes", "false":"no"},
            translateOptions: true,
            translationTable: {"en":{"Deceased":"Deceased", "yes":"yes", "no":"no", false:'no', true:'yes'},"da":{"Deceased":"Afdød","yes":"ja","no":"nej", false:'nej', true:'ja'}}
         },
         "ejf_kommunenr": { title: "Municipality", filterType:FilterType.Select, default:[], 
            options: ReferenceGeomDenmark.municipalityLookupList.sort((a,b) => a.KOMNAVN.localeCompare(b.KOMNAVN)).reduce<any>((prev, val, idx) => {
               prev[val.Komnr.toFixed(0).toString().padStart(4,"0")]=val.KOMNAVN;
               return prev;
            }, {}),
            translationTable: {"en":{"Municipality":"Municipality"},"da":{"Municipality":"Kommune"}}},
         "ejf_postnr": { title: "Zip Code", filterType:FilterType.IntegerRange, default:["",""],
         translationTable: {"en":{"Zip Code":"Zip Code"},"da":{"Zip Code":"Postnummer"}}},
         "ejf_cvrnr": { title: "CVRnr", filterType:FilterType.Integer, default:[""],
         translationTable: {"en":{"CVRnr":"CVRnr"},"da":{"CVRnr":"CVRnr"}}},
         "ejf_ejere": { title: "Owner", filterType:FilterType.String, default:[""],
         translationTable: {"en":{"Owner":"Owner"},"da":{"Owner":"Ejernavn"}}},
         "bbr_antalEtager": { title: "Number of floors", filterType:FilterType.IntegerRange, default: ["1", "0"],
         translationTable: {"en":{"Number of floors":"Number of floors"},"da":{"Number of floors":"Antal etager"}}},
         "jords_ejerlavskode": { title: "Ejerlavskode", filterType:FilterType.Integer, default:[""],
         translationTable: {"en":{"Ejerlavskode":"Cadastral Region Code"},"da":{"Ejerlavskode":"Ejerlavskode"}}},
         "jords_matrikeln": { title: "MatrikelNr", filterType:FilterType.String, default:["97dp"],
         translationTable: {"en":{"MatrikelNr":"MatrikelNr"},"da":{"MatrikelNr":"MatrikelNr"}}},
         "handel_samletKoebesum": { title: "Last Transaction Amt", filterType:FilterType.IntegerRange, default: ["0", ""],
         translationTable: {"en":{"Last Transaction Amt":"Last Transaction Amt"},"da":{"Last Transaction Amt":"Seneste handelspris"}}},
         "handel_tidspunkt": { title:"Last Transaction Date", filterType:FilterType.DateRange, default:["2000-01-01",(new Date).toISOString().split("T")[0]],
         translationTable: {"en":{"Last Transaction Date":"Last Transaction Date"},"da":{"Last Transaction Date":"Seneste handelsdato"}}},
         // "bygningspunkt_DDKNcelle100m": { filterType:FilterType.String, default:["100m_63128_5149"]},
         // "bygningspunkt_DDKNcelle1km": { filterType:FilterType.String, default:["1km_6189_536"]},
         // "bygningspunkt_DDKNcelle10km": { filterType:FilterType.String, default:["10km_618_53"]},
         "sfe_registreretArealSamlet": { title: "Total property area", filterType:FilterType.IntegerRange, default: ["0", ""],
         translationTable: {"en":{"Total property area":"Total property area"},"da":{"Total property area":"Samlet grundareal"}}},
         "sfe_vejarealSamlet": { title: "Total road area", filterType:FilterType.IntegerRange, default: ["", "0"],
         translationTable: {"en":{"Total road area":"Total road area"},"da":{"Total road area":"Samlet vejareal"}}},
         "poi_dist_stop": { title: "Distance to Bus stop", filterType:FilterType.IntegerRange, default: ["", "200"],
         translationTable: {"en":{"Distance to Bus stop":"Distance to Bus stop"},"da":{"Distance to Bus stop":"Afstand til bus"}}},
         "poi_dist_junction": { title:"Distance to Motorway", filterType:FilterType.IntegerRange, default: ["", "5000"],
         translationTable: {"en":{"Distance to Motorway":"Distance to Motorway"},"da":{"Distance to Motorway":"Afstand til motorvej"}}},
         "poi_dist_chargingstation": { title: "Distance to Charging Station", filterType:FilterType.IntegerRange, default: ["", "1000"],
         translationTable: {"en":{"Distance to Charging Station":"Distance to Charging Station"},"da":{"Distance to Charging Station":"Afstand til ladestation"}}},
         "poi_dist_supercharger": { title: "Distance to Super Charging Station", filterType:FilterType.IntegerRange, default: ["", "1000"],
         translationTable: {"en":{"Distance to Super Charging Station":"Distance to Super Charging Station"},"da":{"Distance to Super Charging Station":"Afstand til lynladestation"}}},
         "trafik_highest_hdt": { title: "Traffic, Total", filterType:FilterType.IntegerRange, default: ["1000", ""],
         translationTable: {"en":{"Traffic, Total":"Traffic, Total"},"da":{"Traffic, Total":"Trafiktal, totalt"}}},
         "trafik_highest_trucks": { title: "Traffic, Trucks", filterType:FilterType.IntegerRange, default: ["1000", ""],
         translationTable: {"en":{"Traffic, Trucks":"Traffic, Trucks"},"da":{"Traffic, Trucks":"Trafiktal, lastbiler"}}},
         "trafik_highest_truckRatio": { title: "Traffic, Truck ratio", filterType:FilterType.IntegerRange, default: ["", "10"],
         translationTable: {"en":{"Traffic, Truck ratio":"Traffic, Truck ratio pct"},"da":{"Traffic, Truck ratio":"Trafiktal, lastbiler andel pct"}}},
         "trafik_highest_dist" : { title: "Traffic, Distance", filterType:FilterType.IntegerRange, default: [, "5000"],
         translationTable: {"en":{"Traffic, Distance":"Traffic, Distance"},"da":{"Traffic, Distance":"Trafiktal, afstand"}}},       
         "bbr_status": { title: "Building Status", filterType:FilterType.Select, default: ["6"],
         options: {
            // 1: "Start",
            2: "Projekteret",
            3: "Under opførsel",
            // 4: "Sagsgrund",
            // 5: "Oprettet",
            6: "Opført",
            // 7: "Gældende",
            // 8: "Godkendt",
            // 9: "Afsluttet",
            // 10: "Historisk",
            // 11: "Fejlregistreret",
            12: "Midlertidig Afsluttet",
            // 13: "Delvis Afsluttet",
            // 14: "Henlagt",
            // 15: "Modtaget",
            // 16: "UnderBehandling",
            // 17: "Fejl",
            // 18: "Udført",
            // 19: "Foreløbig",
            // 20: "BomSagModtaget",
            // 21: "BomSagUnderBehandling",
            // 22: "BomSagFejl",
            // 23: "BomSagUdført"
         },
         translationTable: {"en":{"Building Status":"Building Status"},"da":{"Building Status":"BBR Status"}}
         },
         "bbr_opvarmningsMiddel": { title: "Heating source", filterType:FilterType.Select, default: ["7"],
         options: {
            1 : "Elektricitet",
            2 : "Gasværksgas",
            3 : "Flydende brændsel",
            4 : "Fast brændsel",
            6 : "Halm",
            7 : "Naturgas",
            9 : "Andet"
         },
         translationTable: {"en":{"Heating source":"Heating source"},"da":{"Heating source":"Opvarmningsmiddel"}}
         },
         "bbr_varmeInstallation": { title: "Heating installation", filterType:FilterType.Select, default: ["1"],
         options: {
            1: "Fjernvarme/blokvarme",
            2: "Centralvarme med én fyringsenhed",
            3: "Ovn til fast og flydende brændsel",
            5: "Varmepumpe",
            6: "Centralvarme med to fyringsenheder",
            7: "Elvarme",
            8: "Gasradiator",
            9: "Ingen varmeinstallation",
            99: "Blandet"
         },
         translationTable: {"en":{"Heating installation":"Heating installation"},"da":{"Heating installation":"Varmeinstallation"}}
         },
         "bbr_tagMateriale": { title: "Roof material", filterType:FilterType.Select, default: ["7"],
         options: {
            1: "Tagpap med lille hældning",
            2: "Tagpap med stor hældning",
            3: "Fibercement herunder asbest",
            4: "Betontagsten",
            5: "Tegl",
            6: "Metal",
            7: "Stråtag",
            10: "Fibercement uden asbest",
            11: "Plastmaterialer",
            12: "Glas",
            20: "Levende tage",
            80: "(UDFASES) Ingen",
            90: "Andet materiale"
         },
         translationTable: {"en":{"Roof material":"Roof material"},"da":{"Roof material":"Tagdækningsmateriale"}}
         },
         "bbr_yderVaegMateriale": { title: "Siding Materials", filterType:FilterType.Select, default: ["7"],
         options: {
            1: "Mursten",
            2: "Letbetonsten",
            3: "Fibercement herunder asbest",
            4: "Bindingsværk",
            5: "Træ",
            6: "Betonelementer",
            8: "Metal",
            10: "Fibercement uden asbest",
            11: "Plastmaterialer",
            12: "Glas",
            80: "Ingen",
            90: "Andet materiale"
         },
         translationTable: {"en":{"Siding Materials":"Siding Materials"},"da":{"Siding Materials":"Ydervægsmateriale"}}
         },
         "bfe_ejendomsType": { title:"Property Type", filterType:FilterType.SelectText, default:["SamletFastEjendom"],
            options: {
               "SamletFastEjendom": "SamletFastEjendom",
               "Ejerlejlighed": "Ejerlejlighed",
               "BygningPaaFremmedGrund":"BygningPaaFremmedGrund"
            },
            translateOptions: true,
            translationTable: {
               "en":{"Property Type":"Property Type",
                  "SamletFastEjendom": "Samlet Fast Ejendom",
                  "Ejerlejlighed": "Ejerlejlighed",
                  "BygningPaaFremmedGrund":"Bygning På Fremmed Grund"},
               "da":{"Property Type":"Boligtype",
                  "SamletFastEjendom": "Samlet Fast Ejendom",
                  "Ejerlejlighed": "Ejerlejlighed",
                  "BygningPaaFremmedGrund":"Bygning På Fremmed Grund"
               }
            }
         },
         "bfe_nr": { title:"BFEnr", filterType:FilterType.String, default:["6"],
            translationTable: {"en":{"BFEnr":"BFEnr"},"da":{"BFEnr":"BFEnr"}}},
         "zone_zone": { title:"Zone", filterType:FilterType.Select, default:[],
            options: {
               1: "Byzone",
               0: "Landzone",
               3: "Sommerhusområde"
            },
            translationTable: {"en":{"Zone":"Zone"},"da":{"Zone":"Zone"}}
         },
         "ejf_reklameBeskyttelse": { title: "Ad-protected", filterType:FilterType.SingleSelect, default:[""], 
            options: { "true":"yes", "false":"no"},
            translateOptions: true,
            translationTable: {"en":{"Ad-protected":"Ad-protected", "yes":"yes", "no":"no", false:'no', true:'yes'},"da":{"Ad-protected":"Reklamebeskyttet","yes":"ja","no":"nej", false:'nej', true:'ja'}}
         },
         "map_layer": { title:"Map Layer", filterType:FilterType.PolygonFromLayer, default:[],
            options: {
            },
            translationTable: {"en":{"Map Layer":"Map Layer"},"da":{"Map Layer":"Kortlag"}},
            licenseFeature: Feature.ExplorerSearchByMapLayer
         },
         "bbr_landbrugsnotering.keyword": { title: "Landbrugsnotering", filterType:FilterType.Select, default: [""],
            options: {
               "Landbrug": "Landbrug",
               "Landbrug uden bygning": "Landbrug uden bygning"
            },
            translationTable: {"en":{"Landbrugsnotering":"Farming Area"},"da":{"Landbrugsnotering":"Landbrugsnotering"}}
         },
         "bbr_udskiltVej": { title: "Udskilt vej", filterType:FilterType.SingleSelect, default:[""], 
            options: { "true":"yes", "false":"no"},
            translateOptions: true,
            translationTable: {"en":{"Udskilt vej":"Extracted Road", "yes":"yes", "no":"no", false:'no', true:'yes'},"da":{"Udskilt vej":"Udskilt vej","yes":"ja","no":"nej", false:'nej', true:'ja'}}
         },
         "bbr_hovedejendomOpdeltIEjerlejligheder" : { title: "Opdelt i ejerlejligheder", filterType:FilterType.SingleSelect, default:[""], 
            options: { "true":"yes", "false":"no"},
            translateOptions: true,
            translationTable: {"en":{"Opdelt i ejerlejligheder":"Condominium Property", "yes":"yes", "no":"no", false:'no', true:'yes'},"da":{"Opdelt i ejerlejligheder":"Opdelt i ejerlejligheder","yes":"ja","no":"nej", false:'nej', true:'ja'}}
         },
         "drawn_area": { hideOnFilterList:true, title: "Bounding box", filterType: FilterType.BoundingBox, default:[11.635048941530158, 55.074257075021336, 12.362662349049884, 55.425561915849045],
         translationTable: {"en":{"Bounding box":"Bounding box"},"da":{"Bounding box":"Tegnet boks"}}},
         "polygon": { hideOnFilterList: true, title: "Drawn Area", filterType: FilterType.Polygon, default:[
            [
              [
                55,
                10
              ],
              [
                10,
                10
              ],
              [
                10,
                55
              ],
              [
                55,
                55
              ],
              [
                55,
                10
              ]
            ]
          ],
          translationTable: {"en":{"Drawn Area":"Drawn Area"},"da":{"Drawn Area":"Tegnet område"}}},
          "bfe_nr_set": { hideOnFilterList:true,  index_field_override: "bfe_nr", title: "bfe set", filterType: FilterType.IntegerSet, default: [], translationTable: {} }
       }
   };
}

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------
export enum ExploreSearchActionType {
   TxStarted,
   TxCompleted,
   TxFailed,
   AddFilter,
   RemoveFilter,
   HeartFilter,
   RemoveAllFilters,
   DummyTx,
}

export interface TxStarted {
   type: ExploreSearchActionType.TxStarted;
   payload: { guid: string, action: ExploreSearchActions };
}

export interface TxCompleted {
   type: ExploreSearchActionType.TxCompleted;
   payload: { guid: string, action: ExploreSearchActions };
}

export interface TxFailed {
   type: ExploreSearchActionType.TxFailed;
   payload: { guid: string, action: ExploreSearchActions, error: any };
}

export interface AddFilter {
   type: ExploreSearchActionType.AddFilter;
   payload: { item: string, value:FilterValue };
}

export interface RemoveFilter {
   type: ExploreSearchActionType.RemoveFilter;
   payload: { item: string };
}

export interface HeartFilter {
   type: ExploreSearchActionType.HeartFilter,
   payload: { item:string, heart:boolean }
}

export interface RemoveAllFilters {
   type: ExploreSearchActionType.RemoveAllFilters;
}

export interface DummyTx {
   type: ExploreSearchActionType.DummyTx;
}

// -------------------- utility functions to create an Action object ---------------------------------

export const actionTxStarted = (guid: string, action: ExploreSearchActions): TxStarted => ({
   type: ExploreSearchActionType.TxStarted,
   payload: { guid, action }
});

export const actionTxCompleted = (guid: string, action: ExploreSearchActions): TxCompleted => ({
   type: ExploreSearchActionType.TxCompleted,
   payload: { guid, action }
});

export const actionTxFailed = (guid: string, action: ExploreSearchActions, error: any): TxFailed => ({
   type: ExploreSearchActionType.TxFailed,
   payload: { guid, action, error }
});

export const addFilter = (item: string, value: FilterValue): AddFilter => ({
   type: ExploreSearchActionType.AddFilter,
   payload: { item, value }
});

export const removeFilter = (item: string): RemoveFilter => ({
   type: ExploreSearchActionType.RemoveFilter,
   payload: { item }
});

export const heartFilter = (item:string, heart:boolean): HeartFilter => ({
   type: ExploreSearchActionType.HeartFilter,
   payload: { item, heart }
})

export const removeAllFilters = (): RemoveAllFilters => ({
   type: ExploreSearchActionType.RemoveAllFilters
});

export type ExploreSearchActionsTransActional = DummyTx;
export type ExploreSearchActionsNonTransActional = TxStarted | TxCompleted | TxFailed
   | AddFilter | RemoveFilter | RemoveAllFilters | HeartFilter;
export type ExploreSearchActions = ExploreSearchActionsTransActional | ExploreSearchActionsNonTransActional;

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------


// ---------------------------------------------------- TRANSACTIONAL REDUCER --------------------------------------------------------------

export function transactionalExploreSearchReducer(action: ExploreSearchActionsTransActional, dispatch: any) {

   switch (action.type) {
      case ExploreSearchActionType.DummyTx: {
         }
         break;
      


      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
      }
}

// ---------------------------------------------------- REDUCER --------------------------------------------------------------

export function ExploreSearchReducer(state: ExploreSearchState, action: ExploreSearchActions): ExploreSearchState {
   function enum2String(type:any, value:number):string {
      return Object.values<string>(type)[value];
   }

   switch (action.type) {

      case ExploreSearchActionType.TxStarted:
         return {
             ...state,
             activeTransactions: { ...state.activeTransactions, [action.payload.guid]: action }
         };

     case ExploreSearchActionType.TxFailed: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         console.error(action.payload.error);
         let msg = Utils.formatString(Localization.getTextSafeMode("Error. Transaction {txname} returned with {error}"), { 
            txname: Localization.getTextSafeMode(ExploreSearchActionType[action.payload.action.type]), 
            error: Localization.getTextSafeMode(action.payload.error) });
         // Logger.logError("viamapLiceningState", ExploreSearchActionType[action.payload.action.type], msg);
         return {
             ...state,
             message: msg,
             messageType: MessageType.Error
         };
     }

     case ExploreSearchActionType.TxCompleted: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         return {
             ...state,
             activeTransactions: tmp,
             message: ""
         };
     }

      case ExploreSearchActionType.DummyTx: {
            return { ...state,
             };
         break;
      }
      
      case ExploreSearchActionType.AddFilter: {
         let value = action.payload.value || state.selectableFilterList[action.payload.item].default;
         let a2 = {...state.activeFilters, [action.payload.item]:value};
         return { ...state,
            activeFilters:a2
          };
         break;
      }

      case ExploreSearchActionType.RemoveFilter: {
         let a2 = {...state.activeFilters};
         delete a2[action.payload.item];
         return { ...state,
            activeFilters:a2
         };
         break;
      }

      case ExploreSearchActionType.HeartFilter: {
         let oldFavFilter = state.favoritFilters;
         let newFavFilter:string[] = [];
         if (action.payload.heart) {
            newFavFilter = [...oldFavFilter, action.payload.item]
         } else {
            newFavFilter = oldFavFilter.filter((a) => a !== action.payload.item)
         }
         return {...state, favoritFilters: newFavFilter}
         break;
      }

      case ExploreSearchActionType.RemoveAllFilters: {
         let a2 = {};
         return { ...state,
            activeFilters:a2
         };
         break;
      }

      default:
           throw new Error("Unknown action " + (action as ExploreSearchActions).type);
   }
}


// ---------------------------------------------------- generic stuff --------------------------------------------------------------

export const ExploreSearchContext = createContext<{
   state: ExploreSearchState;
   dispatch: React.Dispatch<ExploreSearchActions>;
}>({
   state: initialExploreSearchState(),
   dispatch: () => undefined,
});

export class GenericTransactionManager {

   static dispatchMiddleware<ActionTypes>(dispatch: any, transactionalReducer:(action:any,dispatch:any)=> any) {

       return (action: ActionTypes) => {
           transactionalReducer(action, dispatch);
       };
       
   }
}