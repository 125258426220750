import { Button, FormControl } from 'react-bootstrap';
import chroma from 'chroma-js';
import {AppMessagesContext, Localization, SettingsManager} from "@viamap/viamap2-common";
import {ColorRange, SizeRangeList, ColorRangeList, LayerInfo, 
    LayerType, MarkerType, MitDataType, ColumnMapping, 
    SizeRange, ColorByValueStyling, DivisionStylingType, 
    ClusteringType, LayerInfoStyling, HeightByValueStyling, TextByValueStyling} from '../common/managers/Types';
import {ColorPicker} from './ComponentUtils';
import {BsFillCloudArrowUpFill} from 'react-icons/bs'

import { DivisionCalculator } from 'src/managers/DivisionCalculator';
import ReactDOM from 'react-dom';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FeatureType } from 'src/states/ApplicationStateFeatures';
import { Slider } from './MitInputComponents';
import { ADModalBody, ADModalFooter, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassColorBox, GlassColorPalette, GlassInputGroup, GlassTextInputTransformer } from './GlassInput';
import { GlassButton } from './GlassButtons';
import { GlassCheckbox } from './MitGlassComponents';
import { useContext, useState, useEffect, Fragment } from 'react';


type Props = {
    layerInfo: LayerInfo;
    sizeRanges: SizeRangeList;
    colorRanges: ColorRangeList;
    colors: string[];
    callbackOnLayerStyling: (action:"styling"|"cancel", layerInfo?:LayerInfo)=>void;
    callbackToSaveLayerAsFeatureLayer: (layer: LayerInfo) => void;
    editFeatureAvailable?: boolean;
};

export function LayerStylingContainer (props:Props) {
    return <LayerStyling key={props.layerInfo?.layerId || "TT"} {...props} />
}

export function LayerStyling (props:Props) {
    const {hasAccessToFeature} = useContext(ApplicationStateContext)
    const [displayColorPicker, setDisplayColorPicker] = useState<{property:"color"|"lineColor"|"textColor"|"", color?:string}>({property:"", color:""});
    const [dataname,setDataname] = useState(props.layerInfo?.datasetname ?? "")
    const {dispatch: appMessageDispatch} = useContext(AppMessagesContext)

    function getStyling<K extends keyof LayerInfoStyling>(key: K):LayerInfoStyling[K] {
        return props.layerInfo.styling[key]
    }

    /**
     * Function to easily change styling of layerinfo
     * @param key string of key in layerinfo.styling
     * @param value value to set to key, if object only changes defined keys. 
     * @param override true to override entire obj instead of only defined keys in obj.
     */
    function setStyling<K extends keyof LayerInfoStyling>(key: K, value:LayerInfoStyling[K] | {[valKey in keyof LayerInfoStyling[K]]: LayerInfoStyling[K][valKey]}, override?:boolean) {
        if (Array.isArray(value)) {
            props.layerInfo.styling[key] = value as LayerInfoStyling[K]
        } else if (typeof value === "object") {
            const oldVal:LayerInfoStyling[K] = props.layerInfo.styling[key]
            if (typeof oldVal === "object" && !override) {
                const newVal:LayerInfoStyling[K] = {...oldVal, ...value}
                props.layerInfo.styling[key] = newVal
            } else {
                props.layerInfo.styling[key] = value as LayerInfoStyling[K]
            }
        } else {
            props.layerInfo.styling[key] = value
        }
    }

    function setOther<K extends keyof LayerInfo>(key: K, value:LayerInfo[K]) {
        props.layerInfo[key] = value
    }

    function getOther<K extends keyof LayerInfo>(key: K):LayerInfo[K] {
        return props.layerInfo[key]
    }

    function restyle(layerInfo?: LayerInfo) {
        props.callbackOnLayerStyling("styling", layerInfo ?? props.layerInfo);
    }

    function getProperties() {
        return props.layerInfo.propertiesInGeoJson ?? []
    }

    function getFonts():string[]  {
        return [
        'Droid Sans Bold',
        'Droid Sans Regular',
        'Klavika Regular',
        'Klavika Light', 
        'Klavika Medium',
        'Noto Sans Regular',
        'Noto S',
        'Noto Sans Bold',
        'Noto Sans Italic',
        'OpenSans-ExtraBoldItalic',
        'OpenSans-Regular',
        'OpenSans-Bold',
        'OpenSans-Italic',
        'OpenSans-SemiBold',
        'OpenSans-BoldItalic',
        'OpenSans-Light',
        'OpenSans-SemiBoldItalic',
        'OpenSans-ExtraBold',
        'OpenSans-LightItalic'
        ]
    }
    
    useEffect(() => {
        if (props.layerInfo !== undefined) {
            setDataname(props.layerInfo.datasetname)
        }
    },[props.layerInfo])

    
    if (props.layerInfo === undefined) {
        return null
    }

    function handleCancel(e:any) { e.preventDefault?.(); props.callbackOnLayerStyling?.('cancel'); }

    function callbackToSaveLayerAsFeatureLayer() {
        props.callbackToSaveLayerAsFeatureLayer(props.layerInfo);
        handleCancel({})
    }



// ===================================== EVENTS ===================================


function nameOnBlur(evt, value:any) {
    evt.preventDefault();
    setOther("datasetname", value)
    restyle()
}

function onChangeUseClustering(evt:any) {
    let newVal:boolean = evt.target.checked;
    setStyling("useClustering", newVal)
    restyle()
}

function switchClusteringType (): void {
    switch (getStyling("clusteringType")) {
        case ClusteringType.Piechart:
            setStyling("clusteringType" ,ClusteringType.Normal);
            break;
        case ClusteringType.Normal:
            setStyling("clusteringType" ,ClusteringType.Piechart);
            break;
        default:
            setStyling("clusteringType" ,ClusteringType.Piechart);
            break;
    }
    restyle()
}

function colorByValueFieldOnChange(evt:any) {
    let col = evt;
    if (col==="") {
        setStyling("colorByProperty", undefined)
        setStyling("colorByValue", undefined)
    } else {
        let column = Number.parseInt(col, 10);
        // Set default color range to 0
        let colorRange = getStyling("colorByValue")?.useColorRange || 0
        let columnMappingNew:ColumnMapping = {...props.layerInfo.columnMapping, [MitDataType.Value]:column};
        let colorByValueInfo:ColorByValueStyling = {
            dataColumn:column, 
            useColorRange:colorRange, 
            divisionStylingType:DivisionStylingType.Standard,
        };
        // setStyling("colorByValue", colorByValueInfo)
        setStyling("colorByProperty", col)
        setStyling("colorByValue",{useColorRange:0}, true)
        setStyling("outsideColorRange", {show:true, color: props.layerInfo.styling?.outsideColorRange?.color || SettingsManager.getSystemSetting("mapColorForOtherDataValue",'cyan')})
        props.layerInfo.columnMapping = columnMappingNew
    }
    restyle()
}

function heightByValueFieldOnChange(evt:any) {
    let col = evt;
    if (col==="") {
        setStyling("heightByValue", undefined)
        setStyling("heightByProperty", undefined)
    } else {
        let column = Number.parseInt(col, 10);
        // Set default height range to 0
        let columnMappingNew:ColumnMapping = {...props.layerInfo.columnMapping, [MitDataType.ValueHeight]:column};
        let heightByValueInfo:HeightByValueStyling = {
            dataColumn:column
        };
        setStyling("heightByValue", heightByValueInfo)
        setStyling("heightByProperty", col)
        setStyling("textByProperty", undefined)
        props.layerInfo.columnMapping = columnMappingNew
    }
    restyle()
}

function textByValueFieldOnChange(evt:any) {
    let col = evt;
    if (col==="" || (Array.isArray(col) && col.length === 0)) {
        setStyling("textByValue", undefined)
        setStyling("textByProperty", undefined)
    } else {
        let column = Number.parseInt(col, 10);
        // Set default text range to 0
        let columnMappingNew:ColumnMapping = {...props.layerInfo.columnMapping, [MitDataType.ValueText]:column};
        let textByValueInfo:TextByValueStyling = {
            dataColumn:column
        };
        setStyling("textByValue", textByValueInfo)
        setStyling("textByProperty", col)
        props.layerInfo.columnMapping = columnMappingNew
    }
    restyle()
}

function textFontFieldOnChange(evt:any) {
    let font = evt;
    if (font==="") {
        setStyling("textFont", undefined)
    } else {
        setStyling("textFont", font)
        props.layerInfo.styling.textFont;
    }
    restyle()
}


function allowHideIndividualDivisionsChange(evt:any) {
    let newVal:boolean = evt.target.checked;
    let colorByValueInfo:ColorByValueStyling = {
        ...props.layerInfo.styling.colorByValue!, 
        allowHideIndividualDivisions:newVal
    };
    // if set to false then mark all divisions visible again by removing the 'hide' flag.
    if (!newVal) {
        colorByValueInfo.divisions && colorByValueInfo.divisions.list.forEach((div) => {
            delete div.hide;
        });
    }
    setStyling("colorByValue", colorByValueInfo)
    restyle()
}

function freezeDivisionsChange(evt:any) {
    setStyling("colorByValue", {freezeDivisions:(evt.target.checked)})
    restyle()
}

function showElementCountsChange(evt:any) {
    setStyling("showElementCounts", evt.target.checked);
    restyle()
}

function markerByValueFieldOnChange(evt:any) {
    let col = evt;
    if (col==="") {
        setStyling("markerType",MarkerType.Pin)
        setStyling("markerByValue",undefined)
        setStyling("iconByProperty", undefined)
    } else {
        // let column = Number.parseInt(col,10);
        // let key:string = MitDataType.IconUrl;
        // Set default color range to 0
        // let columnMappingNew:ColumnMapping = {...getOther("columnMapping"), [key]:column};
        // setStyling("markerByValue", {dataColumn:column})
        setStyling("markerType", MarkerType.Icon)
        // setOther("columnMapping", columnMappingNew)
        setStyling("iconByProperty", col)
    }
    restyle()
}

function sizeByValueOnChange(evt:any) {
    let col = evt;
    if (col==="") {
        setStyling("sizeByValue", undefined)
        setStyling("sizeByProperty", undefined)
    } else {
        let column = Number.parseInt(col, 10);
        // let key:string = MitDataType.Value2;
        // Set default size range to 0
        let sizeRange = getStyling("sizeByValue")?.useSizeRange || 0;
        // let columnMappingNew:ColumnMapping = {...getOther("columnMapping"), [key]:column};
        setStyling("sizeByValue", {dataColumn:column, useSizeRange:sizeRange })
        // setOther("columnMapping", columnMappingNew)
        setStyling("sizeByProperty", col)
    }
    restyle()
}

function onClickRestoreDefault(e:any) {
    setStyling("colorByValue", {useColorRange:0, 
        divisionStylingType:DivisionStylingType.Standard}, true)
    restyle()
}

function onClickColorPalette(val:any) {
    if (val==="") {
        // Clear field setting
        setStyling("colorByValue",{useColorRange:0})
    } else {
        setStyling("colorByValue",{useColorRange:val}, true)
    }
    restyle()
}

function RenderAColorRange(parentIndex:number, colorRange:ColorRange) {
    let x= colorRange.colors.map((color, idx)  => { 
        return (
            <Fragment key={color+idx}>
            <GlassColorBox selected={false} color={color} />
            </Fragment>
        );
    });
    return x;
  }

function RenderColorRanges() {
    let result;
    if (props.layerInfo.styling.colorByValue?.divisionStylingType === DivisionStylingType.Custom) {
        // Custom styling
        let idx=1;
        console.assert(props.layerInfo.styling.colorByValue!.divisions, "Expected divisions");
        let cr:ColorRange = DivisionCalculator.extractColorRangeFromDivision(props.layerInfo.styling.colorByValue!.divisions!);
        result = (
        <div key={"x"+idx} >
        <div 
            
            id="GlassColorRange-custom"
            className="GlassColorRange" 
            data-toggle="tooltip" 
            title={Localization.getText("Custom Color Range")} 
        >
        {RenderAColorRange(idx, cr)}
        </div>   
        <span>{"  "}</span>
        <Button 
            id="mit-button-restore-standard-range"
            size="sm"
            variant="default"
            onClick={(e) => onClickRestoreDefault(e)}
        >
            {Localization.getText("Restore default")}
        </Button>                
        </div>
        );
    } else {
    result = props.colorRanges && props.colorRanges.map((obj, idx) => {
        let selected:boolean|undefined = props.layerInfo.styling && 
        props.layerInfo.styling.colorByValue &&
        props.layerInfo.styling.colorByValue.useColorRange === idx;

        let styleClass = selected ? " selected":"";
        return (
            <div 
                key={"x"+idx} 
                className={"GlassColorRange" + styleClass} 
                onClick={() => onClickColorPalette(idx)}
                data-toggle="tooltip" 
                title={obj.name} 
            >
            {RenderAColorRange(idx, obj)}                
            </div>
        );
    });
    }
    return (
        <div className="GlassColorPalette" >
        {result}
        </div>
    )
  }

  /**
   * 
   */
 




function onClickSizeRange(idx:number) {
    _sizeRangeOnChange(idx);
}


function _sizeRangeOnChange(val:any) {
    let newLayerInfo;
    if (val==="") {
        // Clear field setting
        setStyling("sizeByValue",{useSizeRange:undefined})
    } else {
        setStyling("sizeByValue",{useSizeRange:val})
    }
    restyle()
}

function RenderSizeRanges() {
    let y = props.sizeRanges && props.sizeRanges.map((obj, idx) => {
        let selected:boolean|undefined = getStyling("sizeByValue")?.useSizeRange === idx

        let styleClass = selected ? " Flat-selected":"";
        return (
            <div 
                key={"x"+idx} 
                className={"mit-size-palette" + styleClass}
                onClick={() => onClickSizeRange(idx)}
                data-toggle="tooltip" 
                title={obj.name} 

            >
            {RenderASizeRange(idx, obj)}
            </div>
        );
    });
    return y;
  }

  function RenderASizeRange(parentIndex:number, sizeRange:SizeRange) {
    let x= sizeRange.sizes.map((size, idx)  => { 
        size = size/2;
        let bgColor = "grey";
        return (
            <div 
                key={"x"+parentIndex+"y"+idx} 
                className="int-bot glass-color-box" 
                style={{background:bgColor, borderRadius:"50%",width:size, height:size}}
            />
        );
    });
    return x;
  }



    
    const RColorByValueSelect = <TypeAheadSingle id='RColorByValueSelect' value={getStyling("colorByProperty")} onChange={colorByValueFieldOnChange} options={getProperties()} />
    const RHeightByValueSelect = <TypeAheadSingle id='RHeightByValueSelect' value={getStyling("heightByProperty")} onChange={heightByValueFieldOnChange} options={getProperties()} />
    const RTextFontSelect = <TypeAheadSingle id='RTextFontSelect' value={getStyling("textFont")} onChange={textFontFieldOnChange} options={getFonts()} />
    
    let RenderColorPalette = (
        RenderColorRanges()
    );
    
    // const RTextByValueSelect = <TypeAheadSingle id='RTextByValueSelect' value={getStyling("textByProperty")} onChange={textByValueFieldOnChange} options={getProperties()} optionsAdd={[{label:Localization.getText("Dataset name"), value:"datasetname"}]} />
    const RTextByValueSelect = (
        <Typeahead
        id='RTextByValueSelect'
        multiple={true}
        options={[...(props.layerInfo.propertiesInGeoJson?.map(val => ({label:val, value:val})) || []), {label:Localization.getText("Dataset name"), value:"datasetname"}] }
        labelKey={"label"}
        clearButton={false}
        minLength={0}
        placeholder={Localization.getText("please select") + " " + "noget"}
        newSelectionPrefix={Localization.getText("Add new tag") + " "}
        emptyLabel={"No matches found"}
        onChange={(selectedOptions:any[]) => {
          let propertiesToDisplay = selectedOptions.map((opt) => {return opt.value});
          textByValueFieldOnChange(propertiesToDisplay)
        }}
        selected={([getStyling("textByProperty") || []]).flat(1).map((elm) => ({label:(elm == "datasetname") ? Localization.getText("Dataset name") : elm , value:elm}))}
        />
    )
    let RenderSelectPopupInfoGeoJson = (
        <Typeahead
      multiple={true}
      id="PopupInfo"
      options={[...(props.layerInfo.propertiesInGeoJson?.map(val => ({label:val, value:val})) || []), {label:Localization.getText("Dataset name"), value:"datasetname"}] }
      labelKey={"label"}
      clearButton={false}
      minLength={0}
      placeholder={Localization.getText("please select") + " " + "noget"}
      newSelectionPrefix={Localization.getText("Add new tag") + " "}
      emptyLabel={"No matches found"}
      onChange={(selectedOptions:any[]) => {
        let propertiesToDisplay = selectedOptions.map((opt) => {return {name:opt.value};});
        setOther("propertiesToDisplay",propertiesToDisplay)
        restyle()
        }
    }
      selected={props.layerInfo.propertiesToDisplay?.map((elm) => ({label:(elm.name == "datasetname") ? Localization.getText("Dataset name") : elm.name , value:elm.name}))}
      />
      );
    

    let anyColumnsWithHTTPType = Boolean(props.layerInfo.analysisResult && props.layerInfo.analysisResult.columns.find((col) => {return col.mitDataType === MitDataType.String_HTTP;}));
    let currentMarkerValue = getStyling("markerByValue")?.dataColumn ?? "";
    // let markerByValueSelect = ComponentUtils.getSelectWithOptionsV2(
    //     columnSelectionListNumbers, "markerByValue", 
    //     (evt) => markerByValueFieldOnChange(evt),Localization.getText("marker by value"),
    //     String(currentMarkerValue),
    //     () => false);

    // let RenderMarkerByValue = anyColumnsWithHTTPType && !(getStyling("colorByValue") || getStyling("sizeByValue")) ? (
    //     markerByValueSelect
    // ):null;

    let markerByValueSelect =
        <Typeahead
          multiple={true}
          id='MarkerByValue'
          options={props.layerInfo.propertiesInGeoJson?.map(val => ({label:val, value:val})) || []}
          labelKey={"label"}
          clearButton={false}
          minLength={0}
          placeholder={Localization.getText("please select") + " " + "noget"}
          newSelectionPrefix={Localization.getText("Add new tag") + " "}
          emptyLabel={"No matches found"}
          onChange={(selectedOptions:any[]) => {
            markerByValueFieldOnChange(selectedOptions[selectedOptions.length-1]?.value || "");
        }
    }
          selected={[getStyling("iconByProperty")].filter(a => a)?.map((elm) => ({label:elm, value:elm})) || []}
          />

    // let sizeByValueSelect = ComponentUtils.getSelectWithOptionsV2(
    //     columnSelectionListNumbers, "sizeByValue", 
    //     (evt) => sizeByValueOnChange(evt),Localization.getText("Size by value"),
    //     getStyling("sizeByValue")?.dataColumn?.toString() || "",
    //     () => false);

        let sizeByValueSelect =
        <Typeahead
          multiple={true}
          id='SizeByValue'
          options={props.layerInfo.propertiesInGeoJson?.map(val => ({label:val, value:val})) || []}
          labelKey={"label"}
          clearButton={false}
          minLength={0}
          placeholder={Localization.getText("please select") + " " + "noget"}
          newSelectionPrefix={Localization.getText("Add new tag") + " "}
          emptyLabel={"No matches found"}
          onChange={(selectedOptions:any[]) => {
            sizeByValueOnChange(selectedOptions[selectedOptions.length-1]?.value || "");
        }
    }
          selected={[getStyling("sizeByProperty")].filter(a => a)?.map((elm) => ({label:elm, value:elm})) || []}
          />
            


    let RenderSizeRangeChooser =  (
        <div className='mit-size-palette-wrapper'>{RenderSizeRanges()}</div>
    )

    let RenderHideAreasWithNoData:JSX.Element|null= (
    
            <GlassCheckbox
                disabled={false}
                checked={props.layerInfo!.styling!.areaNoData?.show}
                onChange={(evt) =>  {setStyling("areaNoData", {show:!evt.target.checked, color:
                    SettingsManager.getSystemSetting(
                        "areaMapColorForAreasWithNoData",
                        "black"
                    )
                }); restyle()}}
                />
    );

    let clusteringOfMarkers = (
            <GlassCheckbox
                checked={props.layerInfo!.styling!.useClustering ? props.layerInfo!.styling!.useClustering : false}
                onChange={(e) => onChangeUseClustering(e)} 
                />
    );

    let clusteringAsDivide = (
            <GlassCheckbox
                checked={props.layerInfo.styling.clusteringType === ClusteringType.Piechart}
                onChange={() => switchClusteringType()} 
            />
    )

    let RenderAllowHideIndividualDivisions:JSX.Element|null=
            <GlassCheckbox
                checked={props.layerInfo!.styling.colorByValue?.allowHideIndividualDivisions || false}
                onClick={(e) => allowHideIndividualDivisionsChange(e)}
                />

    let RenderFreezeDivisions:JSX.Element|null=  (
                <GlassCheckbox
                    checked={props.layerInfo!.styling!.colorByValue?.freezeDivisions || false}
                    onChange={(e) => freezeDivisionsChange(e)}
                    />
    )

    let RenderShowElementCounts:JSX.Element|null = (
            <GlassCheckbox
                checked={props.layerInfo!.styling!.showElementCounts || false}
                onChange={(e) => showElementCountsChange(e)}
                />
    );

    let RenderSingleColorChooser:JSX.Element|null=  (
            <GlassColorPalette defaultColor={props.colors[0]} cogClick={() => setDisplayColorPicker(getStyling("color")?.startsWith("rgb") || getOther("type") !== LayerType.GeoJSON_Polygon ? {property:"color"} : {property:"color", color:chroma(getStyling("color") || "red").css().replace(",1)",`,${SettingsManager.getSystemSetting("geoJSONFillOpacity", 0.1)})`)})}
            colorSet={props.colors} getter={() => getStyling("color") || "blue"} onChange={(color:string) => {setStyling("color",color); restyle()}} />
    )

    let RenderConvertLayerVariability = (
            <div className="col-sm-8 mit-form-control flex-mid" onClick={() => callbackToSaveLayerAsFeatureLayer()}>
                <span className="mit-layerVariability-conversion" title={Localization.getText("Convert layer to permanent layer")}>
                    {Localization.getText("Convert layer to permanent layer")}
                </span>
                <BsFillCloudArrowUpFill
                    style={{ marginLeft:"auto", cursor:"pointer" }}
                    onClick={() => callbackToSaveLayerAsFeatureLayer()}
                    title={Localization.getText("Convert layer to permanent layer")}
                />
            </div>
    );

    const RenderTextColorPalette = (
        <GlassColorPalette cogClick={() => {setDisplayColorPicker({property: "textColor"})}} colorSet={(getStyling("textColor") && [getStyling("textColor") as string, ...props.colors]) || props.colors} defaultColor={getStyling("textColor") || props.colors[0]} getter={() => getStyling("textColor") || getStyling("textColor") || "black"}  onChange={(e) => {setStyling("textColor", e == getStyling("textColor") ? undefined : e); restyle()}} />
    )

    // Systemsettings

    const allowHideDivisions = SettingsManager.getSystemSetting("allowHideIndividualDivisions",false);
    const allowFrezeDivisions = SettingsManager.getSystemSetting("allowFreezeDivisions",false)
    // ############ Logic

    const length = props.layerInfo.geoJson?.features.length || 0

    // Booleans

    const hasProperties = props.layerInfo.propertiesInGeoJson?.length && true || false
    const notEmpty = length > 0
    const isIcon = getStyling("markerType") == MarkerType.Icon
    const isPolygon = getOther("type") == LayerType.GeoJSON_Polygon
    const always = true
    const newer = false
    const isPoint = getOther("type") == LayerType.GeoJSON_Point
    const NotPoint = getOther("type") !== LayerType.GeoJSON_Point
    const allowManualClustering = isPoint && notEmpty && length < SettingsManager.getSystemSetting("noOfRowsToMandateClustering", 10000);
    const heightByValueActive = Boolean(getStyling("heightByProperty")) && ((isPoint && !!(getStyling("colorByValue") || getStyling("sizeByValue"))) || !isPoint)

    // ############ Styling Table

    const stylingOptions:stylingOption[] = [
        {label:"Dataset name", show: always, render: <FormControl size="sm" type="text"  value={dataname} placeholder={Localization.getText("Enter name of dataset")} onChange={(e) => {setDataname(e.target.value)}} onBlur={(e) => nameOnBlur(e, dataname)}/> },
        {label:"Popup Info", show: hasProperties ,render: RenderSelectPopupInfoGeoJson},
        
        {label:"Fixed color", show: !Boolean(getStyling("colorByValue")) && !isIcon, render: RenderSingleColorChooser},
        {label:'Opacity', show: !Boolean(getStyling("colorByValue")), render:<Slider value={getStyling("opacity") ?? 1} min={0} max={1} step={0.05} onChange={(e) => {setStyling("opacity", e); restyle()}} />},
        
        
        {label:"Icon", show: isPoint && !(getStyling("colorByProperty") || getStyling("sizeByProperty")), render: markerByValueSelect},
        {label:"Icon size", show: isIcon, render: <Slider value={getStyling("iconSize") ?? 1} min={0.05} max={2} step={0.05} onChange={((e) => {setStyling("iconSize", e); restyle()})} />},

        {label:"Color by value", show: notEmpty && !isIcon, render: RColorByValueSelect},
        {label:"Color Palette", show: Boolean(getStyling("colorByValue")), render: RenderColorPalette},
        {label:'Opacity', show: Boolean(getStyling("colorByValue")), render:<Slider value={getStyling("opacity") ?? 1} min={0} max={1} step={0.05} onChange={(e) => {setStyling("opacity", e); restyle()}} />},

        {label:"Size by value", show: isPoint && !isIcon, render: sizeByValueSelect},
        {label:"Size Ranges", show: isPoint && !!getStyling("sizeByValue"), render: RenderSizeRangeChooser},

        {label:"Height by value", show: !(getStyling("useClustering")) && notEmpty && !isIcon && ((isPoint && !!(getStyling("colorByValue") || getStyling("sizeByValue"))) || !isPoint), render: RHeightByValueSelect},
        {label:"Height multiplier", show: heightByValueActive, render: <Slider value={getStyling("heightMultiplier") ?? 1} min={0.1} max={100} step={0.1} onChange={((e) => {setStyling("heightMultiplier", e); restyle()})} />},

        {label:"Text by value", show: !(heightByValueActive), render: RTextByValueSelect},
        {label:"Text color", show: !(heightByValueActive) && Boolean(getStyling("textByProperty")), render: RenderTextColorPalette},
        {label:"Text font", show: !(heightByValueActive) && Boolean(getStyling("textByProperty")), hasAccess:Feature.StylingSelectTextFont, render: RTextFontSelect},

        {label:"AllowHideValueGroups", show: allowHideDivisions && getStyling("colorByValue"), render: RenderAllowHideIndividualDivisions},
        {label:"FreezeDivisions", show: allowFrezeDivisions && getStyling("colorByValue"), render: RenderFreezeDivisions},
        
        {label:"ShowElementCounts", show: true, render: RenderShowElementCounts},

        // Polygons
        
        
        // Clustering
        {label:"Clustering of markers", show: !getStyling("heightByProperty") && isPoint && allowManualClustering, render: clusteringOfMarkers},
        {label: "Divide color by value", show: !!(getStyling("useClustering") && getStyling("colorByProperty")),hasAccess:Feature.PiechartClustering, render: clusteringAsDivide},
        

        {label:"Area Border Color", show: isPolygon && !heightByValueActive, render: <GlassColorPalette cogClick={() => {setDisplayColorPicker({property: "lineColor", color: chroma(getStyling("lineColor") || getStyling("color") || "red").css().replace(/[\d\.]+\)$/g, `${getStyling("lineOpacity")})`) })}} colorSet={(getStyling("color") && [getStyling("color") as string, ...props.colors]) || props.colors} defaultColor={getStyling("color") || props.colors[0]} getter={() => getStyling("lineColor") || getStyling("color") || "blue"}  onChange={(e) => {setStyling("lineColor", e == getStyling("color") ? undefined : e); restyle()}} />},

        // Lines & Polygons
        {label:'Line Opacity', show: NotPoint && !heightByValueActive, render:<Slider value={getStyling("lineOpacity") ?? 1} min={0} max={1} step={0.05} onChange={(e) => {setStyling("lineOpacity", e); restyle()}} />},
        {label:"Border Width", show: NotPoint && !heightByValueActive,   render:<Slider value={getStyling("lineWidth") ?? 4} min={0} max={10} onChange={((e) => {setStyling("lineWidth", e); restyle()})} />},
        {label:"Dash Ratio", show: NotPoint && !heightByValueActive,     render:<Slider value={getStyling("lineDashRatio") ?? 1} min={0} max={1} step={0.1} onChange={((e) => {setStyling("lineDashRatio", e); restyle()})} />},
        {label:"Dash Multitude", show: NotPoint && !heightByValueActive, render:<Slider value={getStyling("lineDashMultitude") ?? 5} min={1} max={10} onChange={((e) => {setStyling("lineDashMultitude", e); restyle()})} />},

        {label:"Hide areas with no data", show: isPolygon, render: RenderHideAreasWithNoData},
        

        // {label:"Permanent layer", show: always, hasAccess: Feature.EditCustomFeatureLayers ,render: RenderConvertLayerVariability},
        
        
        // {show: isPolygon, label:'Fill Opacity', render: <Slider min="0" max="1" step="0.05" value={getStyling("areaFillOpacity") ?? 0.1} onChange={(e) => {setStyling("areaFillOpacity", e); restyle()}} />},
    ]

    return (
        ReactDOM.createPortal(
            <>
            <AdvancedDragModal
                PosDefault={{width:"400px", top:"100px", left:"20px"}}
                title={Localization.getText("Styling")}
                onClose={handleCancel}
                variant="NSDark"
            >
                <ADModalBody>
                    <GlassInputGroup >
                        <StyleUsingOptionsList stylingOptions={stylingOptions} />
                    </GlassInputGroup>
                </ADModalBody>
                <ADModalFooter>
                <GlassButton
                    onClick={(e) => handleCancel(e)}
                    >
                    {Localization.getText("Close")}
                </GlassButton>
                </ADModalFooter>
            </AdvancedDragModal>
            </>
        ,document.getElementById("Mit-MapOverlay") || document.body )
      ); 
}

type RowSimpleProps = {
    label: string,
    children: any,
    className?: string
}
function RowSimple({label, children, className}: RowSimpleProps) {
    return (
        <>
        <label style={{textAlign:"right"}} >{label}</label>
        <div className={className}>{children}</div>
        </>
    )
}

type SliderProps = {
    value: number,
    min: string | number,
    max: string | number,
    step?: string | number,
    onChange: (val) => void,
}

type stylingOption = {
    show: boolean,
    hasAccess?: FeatureType,
    label
    render: React.ReactElement | null,
}

type PropsStylingUsingOptionsList = {
    stylingOptions: stylingOption[] 
}
function StyleUsingOptionsList(props: PropsStylingUsingOptionsList) {
    const { hasAccessToFeature } = useContext(ApplicationStateContext)

    return <>
        {props.stylingOptions.map((a) => {
            if (!a.show) {
                return null
            }
            if (a.hasAccess ) {
                return hasAccessToFeature(a.hasAccess) && a.render ? <GlassTextInputTransformer key={a.label} label={Localization.getText(a.label)} >{a.render}</GlassTextInputTransformer> : null 
            }
            return a.render ? <GlassTextInputTransformer key={a.label} label={Localization.getText(a.label)} >{a.render}</GlassTextInputTransformer> : null
        })}
    </>
}

type TypeAheadSingle = {
    value: string | undefined,
    id: string,
    onChange: (val:(string | "")) => void,
    options: string[]
    optionsAdd?: {label:string, value:string}[]
}
function TypeAheadSingle(props:TypeAheadSingle) {
    return (
    <Typeahead
      multiple={true}
      id={props.id}
      options={[...(props.options?.map(val => ({label:val, value:val})) || []),...(props.optionsAdd || [])]}
      labelKey={"label"}
      placeholder={Localization.getText("please select") + " " + "noget"}
      emptyLabel={"No matches found"}
      onChange={(selectedOptions:any[]) => {props.onChange(selectedOptions[selectedOptions.length-1]?.value || "")}}
      selected={
        props.optionsAdd?.find((a) => a.value == props.value)?.label ? [props.optionsAdd?.find((a) => a.value == props.value)?.label || ""] :  [props.value].filter(a => a)?.map((elm) => ({label:elm, value:elm})) || []

      }
      />
    )
}