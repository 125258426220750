import React, { useContext, useMemo } from "react";
import { AdvancedDragModal, ADModalBodyNoScroll } from "src/componentsUtils/AdvancedDragModal";
import { MapitStateContext } from "src/states/MapitState";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, WindowId, openWindow, ManagedWindow } from "src/WindowManager/WindowState";
import { bnboActiveFilter, constructMaskObj, getAllDeleForLodsejer, getAllSubFeaturesLodsejer, getSFEAsFeatureList, nonOverlappingGeo, nonOverlappingGeoAdv, sortFeatureBytype } from "./BNBOAreaEditorExtra";
import { BNBOContext, setLodsejerDele, setLodsejerPoints, setLodsejerNote, TurfFeature, BNBOBeskyttelsesOmråde } from "./BNBOState";
import { featureCollection, feature } from "@turf/helpers";
import intersect from "@turf/intersect";
import { union } from "@turf/union";
import { BNBOAreaEditorV3 } from "./BNBOAreaEditorV3";
import { area, difference, truncate } from "@turf/turf";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { Feature } from "src/states/ApplicationStateFeatures";
import { ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";

export function BNBOAAreaEditorDialog(props: {
   showWindow:number,
   managedWindow: ManagedWindow,
   lodsejerId: string
 }) {
  const {state: bnboState, dispatch: bnboDispatch} = useContext(BNBOContext);
  const {state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);
  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const {dispatch: windowDispatch} = useWindowContext();
 
  const featureList = useMemo(() => {
     return getAllDeleForLodsejer(bnboState, props.lodsejerId)
   },[props.lodsejerId])

   const SfeGeom = useMemo(() => {
      return getSFEAsFeatureList(bnboState, props.lodsejerId)
    }, [props.lodsejerId]);

   const maskDele = useMemo(() => {
    let length = featureList.length
    return getAllSubFeaturesLodsejer(bnboState, props.lodsejerId, length)
    }, [props.lodsejerId])

    const bnboGeom = useMemo(() => {
      return Object.values(bnboState.bnboer[props.lodsejerId]).filter(bnboActiveFilter).map((a) => {
        return feature(a.geometry, {
          id: a.id,
          dguNr: a.dguNr,
          statusCode: a.statusCode,
          statusTekst: a.statusTekst,
          anlaegsNavn: a.anlaegsNavn,
          kommuneNavn: a.kommuneNavn,
        })
      }) || []
    },[props.lodsejerId])

   
   if (!props.showWindow) {
     return <></>
   }
      
   function callBackOnPoint(p, lodsejerId) {
    bnboDispatch(setLodsejerPoints(lodsejerId, p))
    }
 
    function callBackOnArea(e, id) {
      let maskObj = constructMaskObj(maskDele)
      let x = nonOverlappingGeoAdv(e, true, [...SfeGeom], maskObj);
      e.forEach(element => {
        let calcFeature = x.find((a) => a.id == element.id) || undefined;
        element.properties.arealNetto = calcFeature?.properties?.arealNetto ?? 0;
      });
      bnboDispatch(setLodsejerDele(
        props.lodsejerId,
        e.filter((a) => !a.properties.nonEditble).map((a) => {
          let res = {
            id: a.properties.id,
            type: a.properties.type,
            arealBrutto: a.properties.arealBrutto,
            farve: a.properties.farve,
            arealNetto: a.properties.arealNetto,
            note: a.properties.note,
            mask: a.properties.mask,
            isManualTakst: a.properties.isManualTakst,
            takst: a.properties.takst,
            geometry: a.geometry,
            isSpecialArea: a.properties.isSpecialArea,
            specialPattern: a.properties.specialPattern,
            specialNote: a.properties.specialNote,
            faktor: a.properties.faktor
          }
          return res
        })
      ))
      // windowDispatch(closeWindow(WindowId.BNBOAreaEditor))  
    }

   return (
     <AdvancedDragModal
       key={props.managedWindow.id}
       topUpdate={props.showWindow}
       PosDefault={{left:"99%", top:"50px", width:"400px", height:"750px", transform:"translateX(-99%)"}}
       subHeaderContent={
        <ViewButtonText
          onClick={()=>{
          windowDispatch(openWindow(WindowId.BNBOAreaViewer, {lodsejerId: props.lodsejerId}))
          windowDispatch(closeWindow(WindowId.BNBOAreaEditor))
          }}
        >
          Overblik
        </ViewButtonText>
       }
       title={"Erstatningseditor" + (!hasAccessToFeature(Feature.BNBOProjectWrite) ? " (Læs kun)" : "")}
       onClose={(e) => {windowDispatch(closeWindow(props.managedWindow.id));
         windowDispatch(openWindow(WindowId.BNBOSingleElement, {lodsejerId: props.lodsejerId}))}
       }
     >
       <ADModalBodyNoScroll gap={"6px"} templateRows={"1px 1fr"}>
       <BNBOAreaEditorV3 
        lodsejerId={props.lodsejerId}
        delGeom={featureList}
        clipGeom={maskDele}
        SFEGeom={SfeGeom}
        bnboGeom={bnboGeom}
        onCommit={(e) => {
          callBackOnArea(e, props.lodsejerId)
        }}
       />
       </ADModalBodyNoScroll>
     </AdvancedDragModal>
   )
 }


