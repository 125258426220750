import { SimpleTranslationTable } from "@viamap/viamap2-common"
import { CustomLayerSpecs, FeatureLayer, customLayerDefault } from "./WmsLayerFunc"
import { featureCollection, point } from "@turf/helpers"
import polylabel from "polylabel"
import { circle, centroid, bboxClip } from "@turf/turf"
import { PropertyInfoInterface } from "./PropertyInfoInterface"
import chroma from "chroma-js"

type updatedProperties = {
   type: "paint"|"layout",
   prop: string,
   value: any,
}

type Variants = {
   title: string,
   layers: {
      'type':string,
      'paint': object,
      'layout': object,
   }[],
}

type WFSGeojsonLayerSpec = {
   licenseFeature:string,
   label: string,
   type: "geojsonWFS",
   group: string,
   src: string,
   layers: {
      'type':string,
      'paint': object,
      'layout': object,
   }[],
   minZoom?: number,
   attrib?: string,
   sourceID: string,
   dataTransform: any[]
   legend: boolean|string,
   propertiesToDisplay?: string[],
   translationTable?: SimpleTranslationTable,
   variantSelectors?: any,
   variantValues?: any,
   mitValues?: any,
   keyPrefix?: string,
}


function EmptyString<T extends object,E extends keyof T & (string | number)>(key: E, object:T): object is (T & Record<E, string>) {
   return (key in object && typeof object[key] === "string" && object[key] !== "")
}

function isObject(T: unknown): T is Partial<WFSGeojsonLayerSpec> {
   return (typeof T === "object")
}

export function isGeojsonWFS(layer: unknown): layer is WFSGeojsonLayerSpec {
   if (!isObject(layer)) {
      return false
   }
   if (!EmptyString("label", layer)) {
      return false
   }
   if (!(EmptyString("type", layer) && layer.type === "geojsonWFS")) {
      return false
   }
   if (!EmptyString("group", layer)) {
      return false
   }
   return true 
}

//TODO: Change such BadHack isn't necessary
function BadHack(layer: CustomLayerSpecs) {
   let newLayer = window.structuredClone(layer)
   Object.keys(newLayer).map((a) => {
      newLayer[a] ||="a" 
   })
   return newLayer
}

function constructDefaultMitValues(layer: WFSGeojsonLayerSpec):{[key:string]:any} {
   if (layer.mitValues) {
      return layer.mitValues
   }
   let defaults = {}
   if ("mitValuesControls" in layer && typeof layer.mitValuesControls === "object" ) { 
      Object.keys(layer.mitValuesControls as any).forEach((a) => {
         if (a in (layer.mitValuesControls as any)) {
            defaults[a] = (layer.mitValuesControls as any)[a]
         }
      })
   }
   if ("variantSelectors" in layer && typeof layer.variantSelectors === "object" ) {
      Object.keys(layer.variantSelectors as any).forEach((a) => {
         if (a in (layer.variantSelectors as any)) {
            defaults[a] = (layer.variantSelectors as any)[a]?.default
         }
      })
   }
   return defaults
}

export class GeojsonWFS extends FeatureLayer {
   vectorHandle: WFSGeojsonLayerSpec;
   mitValues: {[key:string]:any};
   static dataCacheByBFEnr: {[sfeNr:string]:any} = {};
   static colorCacheByOwner: {[owner:string]:any} = {};
   static colorScale?:any[] = undefined
   static nextColorIndex = 0;

   constructor(layer: GeojsonWFS | WFSGeojsonLayerSpec) {
      if (isGeojsonWFS(layer)) {
         super({...BadHack(customLayerDefault),...layer})
         this.vectorHandle = layer
         this.mitValues = constructDefaultMitValues(this.vectorHandle)
         return this
      }
      super({...BadHack(customLayerDefault),...layer.vectorHandle})
      this.vectorHandle = layer.vectorHandle
      this.mitValues = constructDefaultMitValues(this.vectorHandle)
      return this
   }
   
   public copy(prefix?): GeojsonWFS {
      return new GeojsonWFS(window.structuredClone({...this.vectorHandle, keyPrefix: prefix}))
   }
   
   public getType(): string {
      return "geojsonWFS";
   }
   
   public getWithKey() {
      return {[this.vectorHandle.label] : this}
   }
   
   public get minZoom(): number {
      return this.vectorHandle.minZoom || 13 
   }
   
   public setStyling(value: any): void {
      Object.keys(value).forEach((a) => {
         this.mitValues[a] = value[a]
      })
      this.vectorHandle.mitValues = this.mitValues
   }
   
   // public getUpdatedProperties(value): updatedProperties[] {
   
   // }
   
   public getTileURL(): string {
      return ""
   }
   
   public getLayerID() : string[]
   public getLayerID(idx: number)
   public getLayerID(idx?: number) {
      if (idx !== undefined) {
         return `${this.getSourceID()}-L${idx}-${this.vectorHandle.layers[idx].type}`
      }
      return this.vectorHandle.layers?.map((a, idx) => {
         return this.getLayerID(idx)
      })
   }
   public getSourceID() {
      if (this.vectorHandle.keyPrefix)
         return `\\${this.vectorHandle.keyPrefix}\\` + this.vectorHandle.sourceID
      return this.vectorHandle.sourceID
   }
   public getDataSrc(bounds?) {
      let bbox_old = (bounds.toArray() as number[][]).flat().join();
      let bbox_new = (bounds.toArray() as number[][]).flatMap((a) => [a[1],a[0]]).join();

      return bounds && this.vectorHandle.src.replace("{BBOX_4326}", bbox_new).replace("{BBOX_4326_old}", bbox_old) || this.vectorHandle.src
   }
   public getDataTransformer() {
      let transformations = this.vectorHandle.dataTransform;
      async function RecursiveDataTransformer(data, options, dataTransform: any[]) {
         if (dataTransform?.[0] == "polylabel") {
            return featureCollection((await RecursiveDataTransformer(data, options, dataTransform[1])).features.map((feature) => {
               let coords = polylabel(feature.geometry.coordinates, dataTransform?.[2] || 0.1)
               return point(coords, {...feature.properties, polylabelDist: coords.distance })
            }))
         }
         if (dataTransform?.[0] == "addZoom") {
            return featureCollection((await RecursiveDataTransformer(data, options, dataTransform[1])).features.map((feature) => {
               return {...feature, properties: {...feature.properties, zoom: options.zoom}}
            }))
         }
         if (dataTransform?.[0] == "data") {
            return data
         }
         if (dataTransform?.[0] == "nothing") {
            return data //For backwards compatibility
         }
         if (dataTransform?.[0] == "combine") {
            return featureCollection([...(await RecursiveDataTransformer(data, options, dataTransform[1])).features,...(await RecursiveDataTransformer(data, options, dataTransform[2])).features])  
         }
         if (dataTransform?.[0] == "bboxClip") {
            return featureCollection((await RecursiveDataTransformer(data, options, dataTransform[1])).features.map((feature) => {
               return bboxClip(feature, minMaxBBOX(options.bounds))
            }).filter((a) => a.geometry.coordinates.length > 0));
         }
         if (dataTransform?.[0] == "circle")
            return featureCollection((await RecursiveDataTransformer(data, options, dataTransform[1])).features.map((feature) => {return circle(feature, dataTransform[2])}));
         if (dataTransform?.[0] == "removeIfPropertiesAreEqual")
            return featureCollection((await RecursiveDataTransformer(data, options, dataTransform[1])).features.filter((feature) => feature.properties[dataTransform[2]] != feature.properties[dataTransform[3]]));
         if (dataTransform?.[0] == "addOwnerName") {
            function formatEjerInformation(item: any): string {
             if (item.sfe_registreretArealSamlet > 0 && item.sfe_registreretArealSamlet === item.sfe_vejarealSamlet) {
                 return ""; // Vej
             }
             return [...new Set((item.ejf_ejere_liste || []).map((a) => {
                 return a.navn
             }).filter((a) => a !== "ukendt ejertype").filter((a, idx) => (idx < 2)).map((a, idx) => (idx===0) ? a : "mfl."))].join(",  ") ;
            }
            function formatEjerKey(item: any): string {
               return [...new Set((item.ejf_ejere_liste || []).map((a) => {
                   return a.navn
               }))].join(",  ") ;
              }
            function shuffle (array: string[]) { 
              for (let i = array.length - 1; i > 0; i--) { 
                const j = Math.floor(Math.random() * (i + 1)); 
                [array[i], array[j]] = [array[j], array[i]]; 
              } 
              return array; 
            }; 
            function getNextColor() {
               if (!GeojsonWFS.colorScale) {
                  // GeojsonWFS.colorScale = chroma.scale(["#FFEDA0", "blue","#800026"]).mode('lab').colors(15);
                  GeojsonWFS.colorScale = shuffle(chroma.scale('Spectral').colors(20));
               }
               if (GeojsonWFS.nextColorIndex >= GeojsonWFS.colorScale.length) {
                  GeojsonWFS.nextColorIndex = 0;
               }
               return GeojsonWFS.colorScale[GeojsonWFS.nextColorIndex++]
            }  
            return featureCollection(await Promise.all(data.features.map(async (matr) => { 
               let sfeNr = matr.properties["samletFastEjendomLokalId"];
               let centroidVal = centroid(matr as any);
               if (!GeojsonWFS.dataCacheByBFEnr[sfeNr]) {
                  let ejere = await PropertyInfoInterface.getEjerskab(""+sfeNr);
                  let colorKey = formatEjerKey({ejf_ejere_liste: ejere});
                  let color;
                  if (GeojsonWFS.colorCacheByOwner[colorKey]) {
                     color = GeojsonWFS.colorCacheByOwner[colorKey];
                  } else {
                     color = getNextColor();
                     GeojsonWFS.colorCacheByOwner[colorKey] = color;
                  }
                  let cad = {
                     ...matr.properties,
                     jords_matrikelnr: matr.properties["matrikelnr"],
                     jords_ejerlavskode: matr.properties["ejerlavskode"],
                     ejf_ejere_liste : ejere,
                     sfe_nr: sfeNr,
                     koord_lng: centroidVal.geometry.coordinates[0],
                     koord_lat: centroidVal.geometry.coordinates[1],
                     color: color
                 }
                  cad["ejf_ejere"] = formatEjerInformation(cad);
                 GeojsonWFS.dataCacheByBFEnr[sfeNr] = cad;
               }
               if (GeojsonWFS.dataCacheByBFEnr[sfeNr]) {
                  return {...matr, properties: 
                     {
                        ...GeojsonWFS.dataCacheByBFEnr[sfeNr],
                        ...matr.properties,
                        koord_lng: centroidVal.geometry.coordinates[0],
                        koord_lat: centroidVal.geometry.coordinates[1]
                     }
                  }
               }
               return matr
            }
            )));
         }

         return data
      }
      return (data, options) => RecursiveDataTransformer(data, options, transformations);
   }

   public getSource() {
      return {
         type: "geojson",
         data: {
            'type': 'FeatureCollection',
            'features': []
         }
      }
   }
   
   public getLegendURL() {
      if (typeof this.vectorHandle.legend == "string") {
         return this.vectorHandle.legend
      }
      return ""
   }
   
   public getLayers() {
      return this.vectorHandle.layers.map((a, idx) => {
         return {
            "minzoom": this.vectorHandle.minZoom ?? 9, //TODO: Make system setting for vector layer default zoom
            ...replaceMitTagsValues(this.mitValues,this.vectorHandle.layers[idx]),
            id: this.getLayerID(idx),
            source: this.getSourceID(),
         }
      })
   }
}

function replaceMitTagsValues(values: {[key:string]:any}, object: unknown) {
   if (typeof object == "string") {
      if (object.includes("@")) {
         const key = object.split("@")[1]
         return specialKeys(key, values[key]) || values[key][0]
      }
   }
   if (Array.isArray(object)) {
      return object.map((a) => {
         return replaceMitTagsValues(values, a)
      })
   }
   if (typeof object == "object" && object) {
      const next = {}
      Object.keys(object).forEach((a) => {
         next[a] = replaceMitTagsValues(values, object[a])
      })
      return next
   }
   return object
}

function specialKeys(key: string, value: any[]) {
   switch(key) {
      case "fieldsToShow": {
         return ["format", ...value.flatMap((a) => [["concat", ["get", a], ["case", ["has", a], "\n", ""]], { "font-scale": 1.0 }])]
      }
      default: {
         return false;
      }
   }
}


function oldBBOX(bounds) {
   return (bounds.toArray() as number[][]).flat() as [number,number,number,number];
}
function minMaxBBOX(bounds:any) {
   return [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()] as [number,number,number,number];
}

function newBBOX(bounds) {
   return (bounds.toArray() as number[][]).flatMap((a) => [a[1],a[0]]) as [number,number,number,number];
}
