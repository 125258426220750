import { LicenseType } from "@viamap/viamap2-common";
import { Feature, FeatureType } from "./ApplicationStateFeatures";

export enum AppMode { Normal="Normal", ReadOnly="ReadOnly", Embedded="Embedded" }
export enum DeviceScreenSize { Normal="Normal", Phone="Phone", Tablet="Tablet" }

export type FeatureAllowDenyListMap = { 
   [key:string]: { 
       allow:FeatureType[];
       deny:FeatureType[];
   }
};


// Features for Regionerner 
export const Regionerne = [
    Feature.SpatialExportGeoJSONInverse,
    Feature.SpatialExportGeoJsonPreview,
    Feature.MeasurementToolSpatial
 ]

export const BasicFeatures = [
   Feature.BasicMap, 
   Feature.ExplorerDiscover,
   Feature.CreateMarker,
   Feature.GeoCoding, 
   Feature.ImportEnableGrid,
   Feature.MapStyleBlank,
   Feature.GettingStarted,
   Feature.ShowReleases,
   Feature.MapStyleAerial,
   Feature.MapStyleViamap,
   Feature.SaveMap, 
   Feature.SearchBar,
   Feature.ShowLayerControls, 
   Feature.ShowMapScale,
   Feature.ShowMapSettings,
   Feature.ShowMenu, 
   Feature.PiechartClustering,
   Feature.MapStyleDark,
   Feature.MapStyleLight,
   Feature.MapStyleContrast,
   Feature.DeviceLocation,
   Feature.MeasurementTool,
   Feature.DuplicateLayer,
]
if (process.env.NODE_ENV === "development") {
    BasicFeatures.push(...[
        Feature.Debugging,
        Feature.SwapLicense,
        Feature.EditCustomFeatureLayers,
        Feature.SaveMapCloud,
        Feature.SaveMapCustomerCloud,
        // Feature.SaveMapAdminCloud,
        Feature.EarlyPreview,
        Feature.EditDataLayer,
        Feature.TravelTimeCalculateSeparately,
        Feature.DuplicateLayerFiltered,
        // Feature.SettingsCustomer,
        Feature.Datarapport,
        Feature.Websocket, Feature.WebsocketGlobalChat,
        ...BasicFeatures
    ]);
}

export const PremiumFeatures = [
   Feature.POI,
   Feature.PoiSaveAsNewLayer,
   Feature.SpatialExport,
   Feature.SpatialExportGeoJSON,
   Feature.IntersectingPolygons,

   Feature.SaveLink,
   Feature.SaveLinkReuseMapLink,
   Feature.SaveLinkSetRestrictions,
   Feature.SaveLinkSelectFeatureLayers,

   Feature.ImportPhotos,

   Feature.TravelTime,
   Feature.TravelTimeRushHourMode,
   Feature.TravelTimeDownloadPolygons,
   Feature.TravelTimeMultiple,
   Feature.ObliquePhoto,
   Feature.PropertyInfo,
   Feature.BoligmarkedsStatistik,
   Feature.OpenPropetyInfoDocument,
   Feature.PrintPropertyInfo,
   Feature.FavoriteBackgroundLayers,

   ...Regionerne
];

//Estate Basic & Estate PRO
export const EstateBackgroundLayers = [
   Feature.BackgroundLayerAdmGrænser,
   Feature.BackgroundLayerAdmGrænserStednavne,
   Feature.BackgroundLayerCadaster,
   Feature.BackgroundLayerPlanData,
   Feature.BackgroundLayerPlanDataFingerPlan,
   Feature.BackgroundLayerPlanDataForsyning,
   Feature.BackgroundLayerByggeBeskyt,
   Feature.BackgroundLayerVejeTrafikVolumen,
   Feature.BackgroundLayerForurening,
   Feature.BackgroundLayerFloodingFromSea,
   Feature.BackgroundLayerOwnerInformation,
   Feature.BackgroundLayerPlanDataTrafikAnlæg,

   Feature.BackgroundLayerStøj,
]

export const VisualizeBackgroundLayers = [
   Feature.BackgroundLayerAdmGrænser,
   Feature.BackgroundLayerAdmGrænserStednavne
]

export const LookupBackgroundLayers = [
    Feature.BackgroundLayerAdmGrænser,
    Feature.BackgroundLayerAdmGrænserStednavne,
    Feature.BackgroundLayerCadaster,
    Feature.BackgroundLayerPlanData,
    Feature.BackgroundLayerPlanDataFingerPlan,
    Feature.BackgroundLayerPlanDataForsyning,
    Feature.BackgroundLayerByggeBeskyt,
    Feature.BackgroundLayerBeskyttetNatur,
    Feature.BackgroundLayerVejeTrafikVolumen,
    Feature.BackgroundLayerForurening,
    Feature.BackgroundLayerOwnerInformation,
    Feature.BackgroundLayerBiodiversitet,
    Feature.BackgroundLayerVandløbOversvoemmelse,
]

export const SurveyorBackgroundLayers = [
   Feature.MapStyleAerialNoMask,

   // Uddeleger til lag
   Feature.BackgroundLayerHistoriskeKort,
   Feature.BackgroundLayerHistoriskeOrtofoto,
   Feature.BackgroundLayerOrtofotoSommer,
   Feature.BackgroundLayerAdmGrænser,
   Feature.BackgroundLayerAdmGrænserExtended,
   Feature.BackgroundLayerAdmGrænserStednavne,
   Feature.BackgroundLayerCadaster,
   Feature.BackgroundLayerCadasterForeløbigSimple,
   Feature.BackgroundLayerAnvendelse,
   Feature.BackgroundLayerPlanData,
   Feature.BackgroundLayerPlanDataForsyning,
   Feature.BackgroundLayerPlanDataKonsekvens,
   Feature.BackgroundLayerPlanDataFingerPlan,
   Feature.BackgroundLayerFredningerKulturArv,
   Feature.BackgroundLayerByggeBeskyt,
   Feature.BackgroundLayerBeskyttetNatur,
   Feature.BackgroundLayerVeje,
   Feature.BackgroundLayerVejeTrafikVolumen,
   Feature.BackgroundLayerForurening,
   Feature.BackgroundLayerMarkblokke,
   Feature.BackgroundLayerMarkblokkeHist,
   Feature.BackgroundLayerGeoDanmark,
   Feature.BackgroundLayerDHM,
   Feature.BackgroundLayerDDM,
   Feature.BackgroundLayerFikspunkt,

   Feature.BackgroundLayerFloodingFromSea,
   Feature.BackgroundLayerOwnerInformation,
   Feature.BackgroundLayerRainFall,
   Feature.BackgroundLayerJordbrug,
   Feature.BackgroundLayerJordbrugVand,

   Feature.BackgroundLayerStøj,
   Feature.BackgroundLayerStøjNat,

   Feature.BackgroundLayerBiodiversitet,
   Feature.BackgroundLayerVandløbOversvoemmelse,

   Feature.BackgroundLayerPlanDataTrafikAnlæg,
   Feature.BackgroundLayerJordbrugDrikkevand,
   Feature.BackgroundLayerGrundvand,
]

export const PropertyFeatures = [
   Feature.BackgroundLayerCadaster,
   Feature.BackgroundLayerZoning,
   Feature.BackgroundLayerLocalPlans,
   Feature.BackgroundLayerCadasterPoints,
   Feature.BackgroundLayerPrivatVej,
   Feature.BackgroundLayerStrandBeskyttelsesLinjer,
   Feature.BackgroundLayerForeloebigeSkel,
   Feature.BackgroundLayerFredsskov,
   Feature.BackgroundLayerJordforurening_NuanceringV2,
   Feature.BackgroundLayerJordforureningV1,
   Feature.PropertyInfo,
   Feature.EjerlavMatrikelSearch,
   Feature.VisSamletFastEjendom,
   Feature.VisVurderingsejendom,
   Feature.BoligmarkedsStatistik,
   Feature.DownloadAktFraTinglysning,
   Feature.PlandataAflyst,
   Feature.PlandataForslag,
];


export const ExplorerBasic = [
//    Feature.ThemeNewSec,
   Feature.Explorer,
   Feature.BasicMap,
   Feature.ExplorerDiscover,
   Feature.ExplorerProjects,
   Feature.ExplorerExplore,
   Feature.ExplorerOwnership,
//    Feature.AddToProjectUnassignedList, Disabled due to new project structure
   Feature.PropertyInfoFavorite,
   Feature.ExplorerToExcel,
   Feature.ExplorerSaveAsNewLayer,
   Feature.ExplorerShowAsList,
   Feature.ExplorerSearchByMapLayer,
];

export const ExplorerPro = [
   Feature.ExplorerMarkedsData,
   Feature.ViewCustomFeatureLayers,
   Feature.BackgroundLayerRainFall,
   Feature.BackgroundLayerBiodiversitet,
   Feature.BackgroundLayerVandløbOversvoemmelse,
   Feature.BackgroundLayerStøjNat,
   Feature.BackgroundLayerBeskyttetNatur,
   Feature.BackgroundLayerJordbrug,
];

export const ExplorerDemo = [
   Feature.ExplorerDashboard,
];

/**
* Customer Administrator Features
*/

export const ProAdminFeatures = [
   Feature.CustomUserSettingAdmin
];

export const PremiumAdminFeatures = [
   Feature.EditCustomFeatureLayers
];

export const PropertyAdminFeatures = [];

export const MinimalFeaturesRole = "MINIMAL_FEATURES_FOR_ANY_USER";

/**
* User Role Based Access Parameters
*/
export const UserRole2Features:FeatureAllowDenyListMap = {
   /* --------------------------------------------------------------- */
   [MinimalFeaturesRole]: {
       allow: [],
       deny:  []
   },

   /**
    * All licensed packages
    */

   "Visualize": {
        allow: [
            ...BasicFeatures, 
            ...VisualizeBackgroundLayers,
            Feature.CreateMarkerNavigation,
        ],
        deny:  []
    },
    "Lookup": {
        allow: [
            ...BasicFeatures,
            ...PremiumFeatures,
            ...LookupBackgroundLayers,
            Feature.CreateMarkerNavigation,
        ],
        deny:  []
    },
    "Surveyor": {
        allow: [
            ...BasicFeatures,
            ...PremiumFeatures,
            ...PropertyFeatures,
            ...SurveyorBackgroundLayers,
            
            Feature.SearchFallBack,
            Feature.MapPlot,
            Feature.ExplorerExplore,
            Feature.ExplorerOwnership,
            Feature.AllowConsolidateDuplicates,
            Feature.PropertyInfoFavorite,
            Feature.ExplorerToExcel,
            Feature.ExplorerSaveAsNewLayer,
            Feature.ExplorerShowAsList,
            Feature.ExplorerSearchByMapLayer,
            Feature.LinkToMao,
            Feature.CreateMarkerNavigation,
            Feature.EditDataLayer,
            Feature.ServitutTilExcel,
            Feature.SaveLinkSelectSearchOptions,
            Feature.EditMapDescription,
        ],
        deny:  []
    },
    "Explorer": {
        allow: [
            ...BasicFeatures,
            ...PremiumFeatures,
            ...PropertyFeatures,
            ...ExplorerBasic,
            ...EstateBackgroundLayers,
            Feature.ThemeNewSec
        ],
        deny:  []
    },
    "ExplorerPro": {
        allow: [
            ...BasicFeatures,
            ...PremiumFeatures,
            ...PropertyFeatures,
            ...ExplorerBasic,
            ...ExplorerPro,
            ...EstateBackgroundLayers,
            Feature.ExplorerMarkedsData,
            Feature.ViewCustomFeatureLayers,
            Feature.BackgroundLayerRainFall,
            Feature.ThemeNewSec
        ],
        deny:  []
    },

   /* --------------------------- OLD PACKAGES ------------------------ */
   "Basic": {
       allow: BasicFeatures,
       deny:  []
   },
   "Pro": {
       allow: [
           ...BasicFeatures,
       ],
       deny:  []
   },
   "Premium": {
       allow: [
           ...BasicFeatures,
           ...PremiumFeatures,
       ],
       deny:  []
   },
   "Property": {
       allow: [
           ...BasicFeatures,
           ...PremiumFeatures,
           ...PropertyFeatures,
       ],
       deny:  []
   },
   "ProAdmin": {
       allow: [
           ...ProAdminFeatures,
       ],
       deny:  []
   },
   "PremiumAdmin": {
       allow: [
           ...ProAdminFeatures,
           ...PremiumAdminFeatures,
       ],
       deny:  []
   },
   "PropertyAdmin": {
       allow: [
           ...ProAdminFeatures,
           ...PremiumAdminFeatures,
           ...PropertyAdminFeatures,
       ],
       deny:  []
   },

   /* Applies to all costumers before Feb2020 when the basic rights were reduced. 
       All customer who signed up before Feb2020 should (also) have this access right */
   "BasicPre2020": {
       allow: [
           Feature.BasicMap, Feature.GeoCoding, Feature.SaveMap, 
           Feature.ShowLayerControls, Feature.ShowMenu, Feature.ShowMapScale,
           Feature.ShowMolbakLogoOnGeodataReport, Feature.SearchBar,
           Feature.GettingStarted, Feature.BackgroundLayerCadaster, 
           Feature.MapStyleViamap, 
           Feature.MapStyleAerial,
           Feature.MapStyleContrast,
           Feature.MapStyleDark,
           Feature.MapStyleLight,
           Feature.CreateMarker
       ],
       deny:  []
   },
   "SelfSignedupTrial": {
       allow: [],
       deny:  []
   },
   /* --------------------------------------------------------------- */
   "Administrator": {
       allow: [Feature.UserAdmin],
       deny:  []
   },
   "EarlyPreview": {
        allow: [
            Feature.EarlyPreview,
            Feature.KortUdsnitEjendom,
            Feature.StylingSelectTextFont,
            Feature.PoiSaveAsNewLayer,
            Feature.MapPlotSuper,
            Feature.ExportFloodingProperties,
            Feature.DuplicateLayer,
        ],
        deny:  []
    },
    "Debugging": {
        allow: [
        Feature.Debugging,
        Feature.EarlyPreview,
        Feature.MapPlotSuper,
        Feature.KortUdsnitEjendom
        ],
       deny:  []
    },
   "Cadaster": {
       allow: [Feature.BackgroundLayerCadaster],    
       deny:  []
   },
   "DataPakkeBBR": {
       allow: [Feature.BackgroundLayerBBRData],      
       deny:  []
   },
   "DataPakkeEjerforhold": {
       allow: [Feature.BackgroundLayerEjerData],      
       deny:  []
   },
   "DataPakkeZoning": {
       allow: [Feature.BackgroundLayerZoning],      
       deny:  []
   },
   "DataPakkeTrafikTal": {
       allow: [Feature.BackgroundLayerVejeTrafikVolumen],      
       deny:  []
   },
   "DataPakkeDAI": {
       allow: [Feature.BackgroundLayerDAI],      
       deny:  []
   },
   "DataPakkeLokalplaner": {
       allow: [Feature.BackgroundLayerLocalPlans],      
       deny:  []
   },
   "DataPakkePolitidistrikter": {
       allow: [Feature.BackgroundLayerPoliceDistricts],
       deny: []
   },
   "DataPakkeJordforureningV1": {
       allow: [Feature.BackgroundLayerJordforureningV1],
       deny: []
   },
   "DataPakkeJordforurening_NuanceringV2": {
       allow: [Feature.BackgroundLayerJordforurening_NuanceringV2],
       deny: []
   },
   "DataPakkeJordforurening": {
       allow: [Feature.BackgroundLayerJordforureningV1, Feature.BackgroundLayerJordforurening_NuanceringV2],
       deny: []
   },
   "DataPakkePrivatVej": {
       allow: [Feature.BackgroundLayerPrivatVej],
       deny: []
   },
   "DataPakkeStrandBeskyttelsesLinjer": {
       allow: [Feature.BackgroundLayerStrandBeskyttelsesLinjer],
       deny: []
   },
   "DataPakkeFredsskov": {
       allow: [Feature.BackgroundLayerFredsskov],
       deny: []
   },
   "DataPakkeForeloebigeSkel": {
       allow: [Feature.BackgroundLayerForeloebigeSkel],
       deny: []
   },
   "DataPakkeSommerhuse": {
       allow: [Feature.BackgroundLayerAnvendelse],
       deny: []
   },
   "POI": {
       allow: [Feature.POI],      
       deny:  []
   },
   "POIEVCharger": {
       allow: [Feature.POI, Feature.POIEVCharger],
       deny: []
   },
   "GeodataReport": {
       allow: [Feature.GeodataReport],
       deny:  []
   },
   "GeodataReportGermany": {
       allow: [Feature.GeodataReportGermany],
       deny:  []
   },
   "DeviceLocation": {
       allow: [Feature.DeviceLocation],
       deny:  []
   },
   "SaveLink": {
       allow:  [Feature.SaveLink],
       deny:  []
   },
   "TravelTime": {
       allow:  [Feature.TravelTime, Feature.TravelTimeRushHourMode],
       deny:  []
   },
   "TravelTimeMultiple": {
       allow:  [Feature.TravelTimeMultiple],
       deny:  []
   },
   "TravelTimeFromPoint": {
    allow:  [Feature.TravelTimeFromPoint],
    deny:  []
    },
   "TravelTimeDownloadPolygons": {
       allow:  [Feature.TravelTimeDownloadPolygons],
       deny:  []
   },
   "TravelTimeCombinedBikeAndTransit": {
    allow:  [Feature.TravelTimeCombinedBikeAndTransit],
    deny:  []
    },
    "TravelTimeCalculateSeparately": {
        allow:  [Feature.TravelTimeCalculateSeparately],
        deny:  []
        },
    "TravelTimeBufferSetting": {
        allow: [Feature.TravelTimeMultipleBufferSetting],
        deny: []
    },
   "SpatialExport": {
       allow:  [Feature.SpatialExport, Feature.SpatialExportGeoJSON],
       deny:  []
   },
   "ObliquePhoto": {
       allow:  [Feature.ObliquePhoto],
       deny:  []
   },
   "DataPakkeDayNightPopulation": {
       allow:  [Feature.BackgroundLayerDayNightPopulation],
       deny:  []
   },
   "MapStyleViamap": {
       allow: [
           Feature.MapStyleViamap,
       ],
       deny: []
   },
   "MapStyleAerial": {
       allow: [
           Feature.MapStyleAerial,
       ],
       deny: []
   },
   "MapStyleContrast": {
       allow: [
           Feature.MapStyleContrast,
       ],
       deny: []
   },
   "MapStyleDark": {
       allow: [
           Feature.MapStyleDark,
       ],
       deny: []
   },
   "MapStyleLight": {
       allow: [
           Feature.MapStyleLight,
       ],
       deny: []
   },
   "StreetView": {
       allow: [
           Feature.StreetView
       ],
       deny: []
   },
   "CustomFeatureLayers": {
       allow: [
           Feature.ViewCustomFeatureLayers
       ],
       deny: []
   },
   "CustomFeatureLayersAdmin": {
       allow: [
           Feature.ViewCustomFeatureLayers,
           Feature.EditCustomFeatureLayers,
       ],
       deny: []
   },
   "LifaOIS": {
       allow: [
           Feature.LifaOIS
       ],
       deny: []
   },
   "Kortviser": {
       allow: [
           Feature.Kortviser
       ],
       deny: []
   },
   "PropertyInfo": {
       allow: [
           Feature.PropertyInfo,
       ],
       deny: []
   },
   "EjerlavMatrikelSearch": {
       allow: [
           Feature.EjerlavMatrikelSearch,
       ],
       deny: []
   },
   "MeasurementTool": {
       allow: [
           Feature.MeasurementTool
       ],
       deny: []
   },
   "VisSamletFastEjendom": {
       allow: [
           Feature.VisSamletFastEjendom
       ],
       deny: []
   },
   "VisVurderingsejendom": {
       allow: [
           Feature.VisVurderingsejendom
       ],
       deny: []
   },
   "PiechartClustering": {
       allow: [
           Feature.PiechartClustering,
       ],
       deny: []
   },
   "CustomUserSettingAdmin": {
       allow: [
           Feature.CustomUserSettingAdmin,
       ],
       deny: []
   },
   "DataPakkeAnvendelse2021": {
       allow: [
           Feature.BackgroundLayerAnvendelse2021,
       ],
       deny: []
   },
   "ImportPhotos": {
       allow: [
           Feature.ImportPhotos,
       ],
       deny: [],
   },
   "ImportEnableSydslesvigGeom": {
        allow: [Feature.ImportEnableSydslesvigGeom],
        deny: [], 
   },
   "ImportEnableGrid": {
        allow: [Feature.ImportEnableGrid],
        deny: [], 
   },
   "ImportEnableRomaniaGeom": {
        allow: [Feature.ImportEnableRomaniaGeom],
        deny: [], 
   },
   "SaveLinkReuseMapLink": {
       allow: [
           Feature.SaveLinkReuseMapLink,
       ],
       deny: [],
   },
   "SaveLinkSetRestrictions": {
    allow: [
        Feature.SaveLinkSetRestrictions,
    ],
    deny: [],
    },
    "SuperPlot" : {
        allow: [
            Feature.MapPlot,
            Feature.MapPlotSuper
        ],
        deny: [],
    },
    "Matrikeloversigt": {
        allow: [
            Feature.Datarapport
        ],
        deny: [],
    },
    "EditDataLayer": {
        allow: [
            Feature.EditDataLayer
        ],
        deny: [],
    },
    "SaveLinkSelectFeatureLayers": {
        allow: [
            Feature.SaveLinkSelectFeatureLayers,
        ],
        deny: [],
    },
    "SaveLinkSelectSearchOptions": {
        allow: [
            Feature.SaveLinkSelectSearchOptions,
        ],
        deny: [],
    },
   "SwapLicense": {
    allow: [Feature.SwapLicense, 
        Feature.DownloadAktFraTinglysning],      
    deny:  []
    },
    "Molbak": {
        allow: [
            Feature.HexagonBackgroundLayerMoelbak,
            Feature.BackgroundGeusVandboringer,
            Feature.LinkHistoriskeKort
        ],
        deny: []
    },
   "Demo": {
       allow: [
            Feature.GeodataReport,
            Feature.SwapLicense,
            // Feature.ShowDemoQuickButtons,
            Feature.ExplorerImportExport,
            Feature.PrintPropertyInfo,
            Feature.PoiSaveAsNewLayer,
            Feature.StreetView,
            Feature.MapPlotSuper,
            Feature.LinkHistoriskeKort,
        ],
       deny:  []
   },
   "BNBO": {
        allow: [Feature.BNBOTool, Feature.BackgroundGeusVandboringer],
        deny: []
   },
   "BNBReadAcess": {
        allow: [Feature.BNBOTool, Feature.BNBOProjectRead, Feature.BackgroundGeusVandboringer],
        deny: []
    },
   "BNBOWriteAcess": {
        allow: [Feature.BNBOTool, Feature.BNBOToolWriteAccess , Feature.BackgroundGeusVandboringer],
        deny: []
   },
   "BNBOAdmin": {
        allow: [Feature.BNBOTool, Feature.BNBOToolWriteAccess, Feature.BNBOReplaceData, Feature.BNBOProjectRead, Feature.BNBOProjectWrite, Feature.BNBOToolAdmin, Feature.BackgroundGeusVandboringer],
        deny: []
   },
   "BNBOGlobalAdmin": {
    allow: [Feature.BNBOTool, Feature.BNBOToolWriteAccess, Feature.BNBOReplaceData, Feature.BNBOProjectRead, Feature.BNBOProjectWrite, Feature.BNBOToolAdmin, Feature.BNBOToolGlobalAdmin, Feature.BackgroundGeusVandboringer],
    deny: []
   },
   "BNBOArrondationCalc": {
    allow: [Feature.BNBOToolArrondationCalc],
    deny: []
   },
   "ExplorerMapLayer": {
    allow: [Feature.ExplorerSearchByMapLayer],
    deny: []
   },
   "Websocket": {
         allow: [Feature.Websocket],
         deny: []
    },
   "GlobalChat": {
         allow: [Feature.Websocket, Feature.WebsocketGlobalChat],
         deny: []
    },
    "AllowConsolidateDuplicates": {
        allow: [Feature.AllowConsolidateDuplicates],
        deny: []
   },   
};

/**
* Appication Mode Based Access Parameters
*/
export const FeaturesAccessibleByMode:FeatureAllowDenyListMap = {
   [AppMode.Embedded]: {
       allow: [],
       deny: [
           Feature.DeviceLocation, 
           Feature.GeodataReport, 
           Feature.POI, 
           Feature.SaveLink, 
           Feature.BackgroundLayerBBRData, 
           Feature.BackgroundLayerCadaster, 
           Feature.BackgroundLayerEjerData,
           Feature.BackgroundLayerZoning,
           Feature.ShowLayerControls, 
           Feature.SaveMap, 
           Feature.ShowMenu,
           Feature.ShowMapSettings,
           Feature.ShowMapScale,
           Feature.TravelTime,
           Feature.TravelTimeMultiple,
           Feature.GettingStarted,
           Feature.ShowReleases,
           Feature.CreateMarker,
           Feature.MeasurementTool,
       ]
   },
   [AppMode.ReadOnly]: {
       allow: [],
       deny: [
           Feature.DeviceLocation,
           Feature.SearchBar,
           Feature.BasicMap,
           Feature.SaveMap, 
           Feature.SaveLink,
           Feature.GettingStarted,
           Feature.ShowReleases
       ]
   },
   [AppMode.Normal]: {
       allow: [],
       deny: []
   }
};


/**
* Application modes based on screen size
*/
export const FeaturesAccessibleByScreenSize:FeatureAllowDenyListMap = {
    [DeviceScreenSize.Phone]: {
        allow: [
            Feature.DeviceScreenSizePhone
        ],
        deny: [
            Feature.DeviceScreenSizeTablet,
            Feature.DeviceScreenSizeNormal,
            Feature.SaveLink,
            Feature.GeodataReport, 
            Feature.DrawCircle,
            Feature.SpatialExport, 
            Feature.SpatialExportGeoJSON, 
            Feature.SpatialExportGeoJSONInverse, 
            Feature.SpatialExportGeoJsonPreview, 
            Feature.Explorer,
            Feature.ExplorerOwnership,
            Feature.ExplorerExplore,
            Feature.ExplorerProjects,
            Feature.StreetView,
            Feature.MeasurementTool,
            Feature.MapPlot,
        ]
    },
    [DeviceScreenSize.Tablet]: {
        allow: [
            Feature.DeviceScreenSizeTablet,
        ],
        deny: [
            Feature.DeviceScreenSizePhone,
            Feature.DeviceScreenSizeNormal,

            Feature.GeodataReport, 
            Feature.DrawCircle,
            Feature.SpatialExport, 
            Feature.SpatialExportGeoJSON, 
            Feature.SpatialExportGeoJSONInverse, 
            Feature.SpatialExportGeoJsonPreview, 
            Feature.Explorer,
            Feature.ExplorerOwnership,
            Feature.ExplorerExplore,
            Feature.ExplorerProjects,
            Feature.StreetView,
            Feature.MeasurementTool,
            Feature.MapPlot,
        ]
    },
    [DeviceScreenSize.Normal]: {
        allow: [
            Feature.DeviceScreenSizeNormal,
        ],
        deny: [
            Feature.DeviceScreenSizeTablet,
            Feature.DeviceScreenSizePhone,
        ]
    }
 };

 /**
* Application modes based on screen size
*/
export const FeaturesAccessibleByLicenseType:FeatureAllowDenyListMap = {
    [LicenseType.Trial]: {
        allow: [
        ],
        deny: [
            Feature.ExportFloodingProperties,
            Feature.ExplorerToExcel,
            Feature.ExplorerImportExport,
            Feature.ExplorerSaveAsNewLayer,
            Feature.ExplorerShowAsList,
            Feature.SpatialExport,
            Feature.SpatialExportGeoJSON,
            Feature.SpatialExportGeoJSONInverse,
            Feature.SpatialExportGeoJsonPreview,
            Feature.Debugging,
            Feature.EarlyPreview,
            Feature.GeodataReport,
            Feature.MapPlot,
            Feature.PowerUser,
            Feature.PrintPropertyInfo,
            Feature.PrintPersonInfo,
            Feature.MapPlot,
            Feature.MapPlotSuper
        ]
    },
    [LicenseType.Subscription]: {
        allow: [
        ],
        deny: [
        ]
    },
    [LicenseType.OneOff]: {
        allow: [
        ],
        deny: [
        ]
    },
 };
