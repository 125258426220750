import React, { useContext, useEffect, useMemo } from "react";
import { AdvancedDragModal, ADModalBody, ADModalInfo } from "src/componentsUtils/AdvancedDragModal";
import { constructMaskObj, getAllFeaturesForLodsejer, nonOverlappingGeo, nonOverlappingGeoAdv, bnboActiveFilter } from "./BNBOAreaEditorExtra";
import { BNBOAreaViewer, ViewerModus } from "./BNBOAreaViewer";
import { BNBOContext } from "./BNBOState";
import * as Turf from "@turf/turf"; // Add this line to import Turf library
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, minimizeWindow, openWindow, WindowId } from "src/WindowManager/WindowState";
import { feature, featureCollection, intersect } from "@turf/turf";
import { ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";
import { RightPaneContext, RightPaneModus } from "src/states/RightPaneState";
import { message } from "src/components/Message";

export function BNBOAreaViewerDialog(props: {
   showWindow:number,
   lodsejerId: string,
   defaultModus?: ViewerModus
 }) {
   const {state: bnboState, dispatch: bnboDispatch} = useContext(BNBOContext);
   const {state: rightPaneState} = useContext(RightPaneContext);
   const [lodejerToShow, setLodejerToShow] = React.useState<string>(props.lodsejerId);
   const { dispatch: windowDispatch } = useWindowContext();
   useEffect(() => {
    if (props.lodsejerId) {
      setLodejerToShow(props.lodsejerId);
    }
   }, [props.lodsejerId])

   const featureList = useMemo(() => {
    
    let ejendomsDele = structuredClone(
      bnboState.dele?.[props.lodsejerId] || []
    )
    let ejendomsFeatures = ejendomsDele.map((a, idx) => {
      return {
        type: "Feature",
        id: idx,
        geometry: a.geometry,
        properties: {
          id: idx,
          farve: a.farve,
          note: a.note,
          mask: (a as any).mask || "" ,
          areal: (a as any).areal,
          type: a.type,
          isSpecialArea: a.isSpecialArea || false,
          specialPattern: a.specialPattern,
          specialNote: a.specialNote,
          faktor: a.faktor
        }
      }
    })

    return ejendomsFeatures
  },[props.lodsejerId])


  const SfeGeom = useMemo(() => {
    if (bnboState.lodsejer[props.lodsejerId]?.geometry.features.length > 1)
    return [Turf.union(Turf.featureCollection(bnboState.lodsejer[props.lodsejerId]?.geometry.features))]
    return bnboState.lodsejer[props.lodsejerId]?.geometry.features
    }, [props.lodsejerId]);

   const maskDele = useMemo(() => {
    let length = featureList.length
    let matLength = bnboState.lodsejer[props.lodsejerId]?.geometry.features.length;
    return [...bnboState.lodsejer[props.lodsejerId]?.geometry.features.map((a, idx) => {
      return feature(a.geometry, {...a.properties, type:"Jordstykke", id: idx + length, maskKey: a.properties.Matrikelnummer+"-"+a.properties.Ejerlavskode}, {id: idx + length})
    }) || [],      
      ...(Object.values(bnboState.marker[props.lodsejerId]||{}).map((a, idx) => {
        return {...intersect(featureCollection([...SfeGeom, feature(a.geometry)]), {properties: {
            afgrøde: a.afgrøde,
            arealBrutto: a.arealBrutto,
            arealNetto: a.arealNetto,
            type:"Mark",
            id: idx + length + matLength,
            lodsejerId: a.lodsejerId,
            markBlok: a.markBlok,
            markNr: a.markNr,
            maskKey: `${a.markBlok}-${a.markNr}`,
            påvirketAfBnboIder: a.påvirketAfBnboIder,
        }}), id: idx + length + matLength}}))]
    }, [props.lodsejerId])

 

    const clippedFeatures = useMemo(() => {
      let maskObj = constructMaskObj(maskDele);
      return nonOverlappingGeoAdv(featureList, true, [...SfeGeom], maskObj);
    },[props.lodsejerId, featureList, maskDele, SfeGeom])
 
   const bnboGeom = useMemo(() => Object.values(bnboState.bnboer[lodejerToShow] || {}).filter(bnboActiveFilter).map((a) => 
    ({type:"Feature", 
      geometry: a.geometry, 
      properties:{Dgunr:a.dguNr
      }})), [lodejerToShow]);
   const markGeom = useMemo(() => [...(Object.values(bnboState.marker[lodejerToShow] || {}).flatMap((a) => Turf.feature(a.geometry))) as any], [lodejerToShow]);
   const lodsGeom = useMemo(() => bnboState.lodsejer[lodejerToShow].geometry.features, [lodejerToShow])
   
   if (props.showWindow === 0) {
      return null;
    } 
 
   return (
     <AdvancedDragModal 
       title={"BNBO Overblik"}
       topUpdate={props.showWindow}
       PosDefault={{left:"99%", top:"50px", width:"350px", transform:"translateX(-99%)"}}
       onClose={(e) => windowDispatch(closeWindow(WindowId.BNBOAreaViewer))}
       subHeaderContent={
        <ViewButtonText
        onClick={()=>{
         if (rightPaneState.current == RightPaneModus.Map_Measure) {
            message.info("Kan ikke åbne Erstatningseditor mens tegnemodus er åben")
         } else {
            windowDispatch(openWindow(WindowId.BNBOAreaEditor, {lodsejerId: props.lodsejerId}))
            windowDispatch(closeWindow(WindowId.BNBOAreaViewer))
         }
        }}>Editor</ViewButtonText>
       }
     >
      <ADModalInfo >
         Vælg modus for visning af BNBO-arealer
      </ADModalInfo>
       <ADModalBody >
         <BNBOAreaViewer
           lodsejerId={lodejerToShow}
           features={clippedFeatures}
           bnboGeom={bnboGeom}
           markGeom={markGeom}
           lodsGeom={lodsGeom}
           defaultModus={props.defaultModus}
         />
       </ADModalBody>
     </AdvancedDragModal>
 
   )
 }
 

