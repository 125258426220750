import { useContext, useEffect, useReducer, useState } from 'react';

import { Filters } from './../components/CommonInputs';
import { Results } from './Results';
import { ActiveFilters } from './ActiveFilters';
import { ExploreSearchActions, ExploreSearchContext, ExploreSearchReducer, GenericTransactionManager, initialExploreSearchState, transactionalExploreSearchReducer } from '../states/ExploreSearchState';
import { usePanWindow } from 'src/common/components/CustomHooks';
import { RightPaneContext } from 'src/states/RightPaneState';
import { ProtectedFeature } from 'src/components/ProtectedFeature';
import { actionSetInfoMessage, AppMessagesContext, Localization } from '@viamap/viamap2-common';
import { AddressInterface } from 'src/managers/AddressInterface';
import { ProjectContext, handleToBeAdded } from 'src/states/ProjectState';
import { Feature } from 'src/states/ApplicationStateFeatures';
import { ViewButtonText } from 'src/propertyInfoTemplates/PropertyComponents';
import { PropertyInfoView } from 'src/propertyInfoTemplates/PropertyInfoView';
import { ADModalBodyNoScroll, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassCard } from 'src/components/MitGlassComponents';
import { useWindowContext } from 'src/WindowManager/useWindowContext';
import { closeWindow, WindowId } from 'src/WindowManager/WindowState';

type Coords = [number,number]

export const SearchWindow = (props:{show:boolean, callbackOnClose: ()=>void}) => {
  const [state, dispatch] = useReducer(ExploreSearchReducer, initialExploreSearchState());
  const {dispatch:rightPaneDispatch} = useContext(RightPaneContext)
  const [viewInfoBox, setViewInfoBox] = useState(0)
  const [coords, setViewCoords] = useState<[number,number]>([0,0])
  const [latLng, setLatLng] = useState<{lat:number,lng:number}>({lat:0,lng:0})
  const {dispatch: WindowDispatch} = useWindowContext();

  const {state:projState, dispatch:projDispatch} = useContext(ProjectContext)
  const {dispatch:appMsgDispatch} = useContext(AppMessagesContext)

  const [Eventer, ref, clickEventer] = usePanWindow(40)

  // const { state, dispatch } = useContext(ExploreSearchContext);

  function bfeHandler(a,b) {
    setViewInfoBox(a);
    setViewCoords(b);
  }

  useEffect(() => {
    //Refreshes PropertyInfo
    setLatLng({lat:coords[1],lng:coords[0]})
  },[coords])

  if (!!!props.show) {
    return <></>
  }

  return (
    <ExploreSearchContext.Provider value={{ state: state, dispatch: GenericTransactionManager.dispatchMiddleware<ExploreSearchActions>(dispatch, transactionalExploreSearchReducer) }}>
    <AdvancedDragModal 
      PosDefault={{left:"200px", top:"50%", transform:"translateY(-50%)", height:"800px",width:"1000px"}}
      PosDefaultNS={{width:"calc( 100% - 30px )", height: "calc(100% - 50px)", top: "50px", left:"15px"}}
      saveKey="Explore"
      title={"Explore"}
      allowUserMiniMize
      allowFullscreen
      onClose={(e) => WindowDispatch(closeWindow(WindowId.Explore))}
      subHeaderContent={viewInfoBox ? 
        <ProtectedFeature feature={Feature.AddToProjectUnassignedList} contentsIfNoAccess={<></>} >
          <ViewButtonText onClick={async (e) => {
            if (projState.isEmpty) {
              appMsgDispatch(actionSetInfoMessage(Localization.getText("Please visit the Project tabs, an action is needed!")))
                return
            }
            if (latLng?.lat && latLng?.lng) {
                
                let resp = await AddressInterface.reverseGeocodeFull(latLng.lat,latLng.lng);
                let addrData = await resp.json();
                let jordstykke = await (await fetch(addrData.jordstykke.href)).json()

                let fullAddress = addrData.adressebetegnelse;
                let tmp = fullAddress.split(',');
                let streetAndNumber = tmp[0] || fullAddress;
                let zipAndCity = addrData.postnummer.nr+" "+addrData.postnummer.navn;
                
                projDispatch(handleToBeAdded([{bfeNr:jordstykke.bfenummer,name:streetAndNumber + ", " + zipAndCity ,latLng:[latLng.lat,latLng.lng]}]))
                return
                }
            }} 
            >
            {Localization.getText("Add to Project")}
          </ViewButtonText>
        </ProtectedFeature>
        : null }
      >
      <ADModalBodyNoScroll
        templateRows={
          !viewInfoBox ?
          "minmax(300px, 20%) 1fr" :
          "50% 50%"
        }
      >
        
        {!viewInfoBox ? <>
        <Filters />
        </> :null}
        <div className='glassColumn'>
        <ActiveFilters />
        <Results selectBFECallback={(a,b) => bfeHandler(a,b)} callBackOnClose={() => setViewInfoBox(0)} showClose={Boolean(viewInfoBox)}  />
        </div>
        {viewInfoBox ? <>
          <div className='glassColumn'>
            <GlassCard 
              style={{height:"100%"}}
              title={"Ejendom"}
              >
          <PropertyInfoView
            key={latLng.lat.toString()}
            foldetUd={true}
            cols={1}
            labelLatLng={{latlng: latLng}}
            callBackOnLoaded={() => {}}
            allowExtendedInformation={true}
          />
          </GlassCard>
          </div>
        </> :null}
      </ADModalBodyNoScroll>
      </AdvancedDragModal>
  </ExploreSearchContext.Provider>
  );
}



